import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import GTabs from "../gComponents/gTabs";
import NotificationsSetting from "./notificationSettings";
import GText from "../gComponents/gText";

export default function NotificationTypes() {
  let { type } = useParams();
  let { userType } = useParams();
  let { notifyType } = useParams();
  const [notificationTypeValue, setNotificationTypeValue] = useState(
    type === "task" ? notifyType || "delivery" : ""
  );

  const notificationRoutes =
    type === "order"
      ? [
        {
          title: "customer",
          link: "/settings/order-notifications/customer",
          state: "setting",
        },
        // {
        //   title: "driver",
        //   link: "/settings/order-notifications/driver",
        //   state: "setting",
        // },
        {
          title: "admin",
          link: "/settings/order-notifications/admin",
          state: "setting",
        },
      ]
      : [
        {
          title: "customer",
          link:
            "/settings/task-notifications/customer/" + notificationTypeValue,
          state: "setting",
        },
        // {
        //   title: "driver",
        //   link:
        //     "/settings/task-notifications/driver/" + notificationTypeValue,
        //   state: "setting",
        // },
        {
          title: "admin",
          link: "/settings/task-notifications/admin/" + notificationTypeValue,
          state: "setting",
        },
      ];

  function handleChange(nTypeVal) {
    setNotificationTypeValue(nTypeVal ? nTypeVal : "delivery");
  }

  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={type + " Notifications"}
        style={{ marginBottom: "15px", textTransform: "capitalize" }}
      />

      <GTabs routes={notificationRoutes} active={userType}></GTabs>
      <div className="listPaper">
        <div>
          <Switch>
            <Route path="/settings/:type-notifications/:userType">
              <NotificationsSetting
                onChange={handleChange}
                userType={userType}
                nType={notifyType || ""}
              />
            </Route>

            <Route path="/settings/:type-notifications/:type/:userType/:notifyType">
              <NotificationsSetting
                onChange={handleChange}
                type={type}
                userType={userType}
                nType={notifyType || notificationTypeValue}
              />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}
