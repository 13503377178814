import React, { useState } from "react";
import { Add, Close } from "@material-ui/icons";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import GButton from "../gComponents/gButton";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";

export default function CreatePricingRule(props) {
  const [openSnackbar] = useSnackbar();
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const initialValues = {
    title: !props.isEmpty ? props.ruleData.title : "",
    options: !props.isEmpty
      ? props.ruleData?.options || [{ upto: "", cost_asap: "", cost_flex: "" }]
      : [{ upto: "", cost_asap: "", cost_flex: "" }],
  };

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    console.log(values);

    var hitUrl = !props.isEmpty
      ? "task/rules/" + props.ruleData.id
      : "task/rules";
    var hitMethod = !props.isEmpty ? "PUT" : "POST";

    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setPrvisible(false);
          props.onSubmit();
        }
        openSnackbar(response.data.message);
        setIsBtnDisabled(false);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),

    options: Yup.array()
      .of(
        Yup.object({
          upto: Yup.string().required("Required"), // these constraints take precedence
          cost_asap: Yup.string().required("Required"), // these constraints take precedence
          cost_flex: Yup.string().required("Required"),
        })
      )
      .required("required") // these constraints are shown if and only if inner constraints are satisfied
      .min(1, "Minimum of 1 option"),
  });

  if (props.prvisible === false) {
    return null;
  }

  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? "Edit" : "Add"} Pricing Rule
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label="Rule name"
                name="title"
              />
              <h4 style={{ margin: "15px 15px 5px" }}>Fare Calculations</h4>
              <div className="form-control">
                <p
                  style={{
                    color: "#ccc",
                    fontSize: "14px",
                    fontStyle: "italic",
                  }}
                >
                  (If the distance is more than the last slot, then the price of
                  last slot will be applied.)
                </p>
                <FieldArray name="options">
                  {(fieldArrayProps) => {
                    console.log(fieldArrayProps);
                    const { push, remove, form } = fieldArrayProps;
                    const { values } = form;
                    const { options } = values;
                    return (
                      <div>
                        <div
                          style={{
                            display: "inline-flex",
                            width: "100%",
                          }}
                          className="arrayFields"
                        >
                          <label
                            style={{
                              width: options.length > 1 ? "31%" : "33.3%",
                            }}
                          >
                            Distance (Miles)
                          </label>
                          <label
                            style={{
                              width: options.length > 1 ? "31%" : "33.3%",
                            }}
                          >
                            ASAP Cost
                          </label>
                          <label
                            style={{
                              width: options.length > 1 ? "31%" : "33.3%",
                            }}
                          >
                            Flex Cost
                          </label>
                        </div>
                        {options.map((o, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                display: "inline-flex",
                                width: "100%",
                                gap: "10px",
                              }}
                              className="arrayFields"
                            >
                              <FormikControl
                                control="input"
                                type="text"
                                name={`options[${index}].upto`}
                                divstyle={{ padding: "0" }}
                                onChange={(event) => {
                                  const re = /^[0-9.\b]+$/;
                                  if (
                                    event.target.value &&
                                    !re.test(event.target.value)
                                  ) {
                                    return;
                                  } else {
                                    setFieldValue(
                                      `options[${index}].upto`,
                                      event.target.value
                                    );
                                  }
                                }}
                                style={{
                                  display: "inline-block",
                                  width: "95%",
                                  margin: "5px 0",
                                  verticalAlign: "top",
                                }}
                              />
                              <FormikControl
                                control="input"
                                type="text"
                                name={`options[${index}].cost_asap`}
                                divstyle={{ padding: "0" }}
                                style={{
                                  display: "inline-block",
                                  width: "95%",
                                  margin: "5px 0",
                                  verticalAlign: "top",
                                }}
                                onChange={(event) => {
                                  const re = /^[0-9.\b]+$/;
                                  if (
                                    event.target.value &&
                                    !re.test(event.target.value)
                                  ) {
                                    return;
                                  } else {
                                    setFieldValue(
                                      `options[${index}].cost_asap`,
                                      event.target.value
                                    );
                                  }
                                }}
                              />
                              <FormikControl
                                control="input"
                                type="text"
                                name={`options[${index}].cost_flex`}
                                divstyle={{ padding: "0" }}
                                style={{
                                  display: "inline-block",
                                  width: "95%",
                                  margin: "5px 0",
                                  verticalAlign: "top",
                                }}
                                onChange={(event) => {
                                  const re = /^[0-9.\b]+$/;
                                  if (
                                    event.target.value &&
                                    !re.test(event.target.value)
                                  ) {
                                    return;
                                  } else {
                                    setFieldValue(
                                      `options[${index}].cost_flex`,
                                      event.target.value
                                    );
                                  }
                                }}
                              />
                              {options.length > 1 && (
                                <a
                                  style={{
                                    textDecoration: "none",
                                    padding: "7px",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    if (options.length > 1) {
                                      remove(index);
                                    }
                                  }}
                                >
                                  <Close />
                                </a>
                              )}
                            </div>
                          );
                        })}
                        <a
                          type="button"
                          href
                          //role="button"
                          variant="linkable"
                          onClick={(e) => {
                            push({
                              upto: "",
                              cost_asap: "",
                              cost_flex: "",
                            });
                          }}
                          style={{
                            display: "inline-flex",
                            marginTop: "10px",
                            cursor: "pointer",
                          }}
                        >
                          <Add /> Add Row
                        </a>
                      </div>
                    );
                  }}
                </FieldArray>
              </div>

              <GButton
                type="submit"
                disabled={isBtnDisabled}
                variant="condensed"
                children={props.isEmpty ? "Create" : "Update"}
                style={{ margin: "15px 15px 0", width: "calc(100% - 30px)" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
