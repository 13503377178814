import React, { useState, useEffect } from "react";
// import { Button} from '@material-ui/core';
import styled from "styled-components";
import useSettings from "../useSettings";

const RuleTypeDiv = styled.div`
  h5 {
    margin: 0px;
    font-weight: 400;
    color: #777;
    font-size: 0.9rem;
    padding: 5px 0;
  }
  p {
    margin: 0px;
    font-weight: 400;
    display: inline-block;
    width: 50%;
    font-size: 0.95rem;
    color: #818183;
  }
`;

export const GPricingRuleCard = ({ ruleData }) => {
  const settings = useSettings(false);

  return (
    <RuleTypeDiv>
      <div>
        <h5>Distance Slots Based</h5>
        {ruleData?.options &&
          ruleData?.options.map((slots, i) => {
            return (
              <p key={i}>
                {slots.distance} {settings.distance_unit}: {slots.asap_cost} -{" "}
                {slots.flex_cost}
              </p>
            );
          })}
      </div>
    </RuleTypeDiv>
  );
};

export default GPricingRuleCard;
