import React from "react";
import GText from "./gText";
import QuoteImg from "../assets/images/quote.png";

const GRouteAddress = (props) => {
  return (
    <>
      {props.task.map((t, i) => {
        return (
          <div style={{ padding: "5px 0" }} key={i}>
            {props.status === true && (
              <GText
                semi
                text={t.status_display?.title}
                style={{
                  color: "#" + t.status_display?.color1,
                  marginbottom: "3px",
                }}
              />
            )}
            <GText
              g6
              dark
              text={
                t.task_time
                  ? (t.type == "pickup" ? "Pick-Up" : t.type) +
                    " - " +
                    t.task_time
                  : t.type == "pickup"
                  ? "Pick-Up"
                  : t.type
              }
              style={{ textTransform: "capitalize", fontStyle: "italic" }}
            />
            <GText
              med
              text={t.rec_name + " - " + t.rec_phone}
              style={{
                textTransform: "capitalize",
                fontStyle: "italic",
                margin: "1px 0",
                fontSize: "14px",
              }}
            />
            <GText
              semi
              text={t.address}
              style={{ color: "#333", fontSize: "14px" }}
            />
            {props.status === true && t.notes ? (
              <div style={{ display: "flex", marginTop: "5px" }}>
                <img
                  src={QuoteImg}
                  alt="quotes"
                  width={"16px"}
                  height={"16px"}
                  style={{ marginRight: "10px" }}
                />

                <GText
                  text={t.notes}
                  style={{
                    fontStyle: "italic",
                    fontSize: "14px",
                    color: "#333",
                  }}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        );
      })}
    </>
  );
};

export default GRouteAddress;
