import React from "react";
import styled from "styled-components";
import { ImgUrl, addDefaultSrc } from "../services";
import { Table, TableBody, TableRowData } from "../styledComponents/tableStyle";
import { Delete, Add, Remove } from "@material-ui/icons";
const Small = styled.small`
  font-size: 0.8rem;
  font-weight: 400;
  display: inline-block;
  padding-right: 5px;
  /* display: block; */
`;

const Tr = styled.tr`
  &:not(:last-child) td {
    border-bottom: 1px solid #f2f2f2;
  }
`;

function GItemTable(props) {
  return (
    <Table className="table">
      <TableBody>
        {props.items.map((i) => {
          return (
            <Tr key={i.id}>
              <td style={{ width: "80px", padding: "5px 0" }}>
                <img
                  alt=""
                  src={ImgUrl("item") + "/" + i.thumb_photo}
                  onError={(e) => addDefaultSrc(e, "item")}
                  className="thumbnail"
                  style={{ width: "50px", height: "50px", objectFit: "cover" }}
                />
              </td>
              {/* <td style={{ width: "30px" }}>{i.quantity} &nbsp;X</td> */}
              <TableRowData>
                {i.title}
                {/* <i>({i.category.title})</i> */}
                <br />
                {(i.variants_available === true || i.variants) &&
                  i.variants.map((v, $index) => {
                    return (
                      <Small key={v.id}>
                        {v.title}
                        {v.value ? (
                          <span style={{ color: "#777" }}> : {v.value}</span>
                        ) : (
                          ""
                        )}
                        {i.variants.length > $index + 1 ? <span>, </span> : ""}
                      </Small>
                    );
                  })}
              </TableRowData>
              <TableRowData style={{ minWidth: "80px" }}>
                {props.currencySymbol}
                {i.price_display ? i.price_display : i.price} * {i.quantity}
              </TableRowData>

              {props.editable === true && (
                <TableRowData style={{ textAlign: "right" }}>
                  <div
                    style={{
                      border: "1px solid #f23938",
                      display: "inline-flex",
                      color: "#f23938",
                      padding: "5px",
                    }}
                  >
                    <Remove
                      style={{ fontSize: "18px", cursor: "pointer" }}
                      onClick={() => props.onDecQuantity(i)}
                    />
                    <span style={{ padding: "0 8px" }}>{i.quantity}</span>
                    <Add
                      style={{ fontSize: "18px", cursor: "pointer" }}
                      onClick={() => props.onIncQuantity(i)}
                    />
                  </div>
                </TableRowData>
              )}
              <TableRowData style={{ textAlign: "right" }}>
                {props.currencySymbol}
                {(parseFloat(i.price) * i.quantity).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                })}
              </TableRowData>
              {props.editable === true && (
                <TableRowData style={{ textAlign: "right" }}>
                  <Delete
                    style={{
                      fontSize: "18px",
                      cursor: "pointer",
                      color: "#f23938",
                    }}
                    onClick={() => props.onRemove(i)}
                  />
                </TableRowData>
              )}
            </Tr>
          );
        })}
      </TableBody>
    </Table>
  );
}

export default GItemTable;
