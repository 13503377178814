import React, { useState } from "react";
import { ApiService, ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import GButton from "../gComponents/gButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import { useLocation } from "react-router-dom";
import useSettings from "../useSettings";
import GImageEditor from "../gComponents/gImageEditor";
import ColorPicker from "../gComponents/gColorPicker";
import GText from "../gComponents/gText";

export default function CreateCustomer(props) {
  const [openSnackbar] = useSnackbar();
  const settings = useSettings(true);
  const location = useLocation();
  const [isDisabled, setIsDisabled] = useState(false);

  const initialValues = {
    first_name: props.data ? props.data.first_name : "",
    last_name: props.data ? props.data.last_name : "",
    email: props.data ? props.data.email : "",
    phone: props.data ? props.data.phone : "",
    password: "",
    account_type: props.data ? props.data?.account_type : "",
    business_name: props.data ? props.data?.business_name : "",
    business_logo: props.data ? props.data?.business_logo : "",
    color1: props.data ? props.data?.color1 : "#ffffff",
    color2: props.data ? props.data?.color2 : "#ffffff",
    bulk_active: props.data ? props.data.bulk_active : 0,
    rest_active: props.data ? props.data?.rest_active : 0
  };

  const onSubmit = (values) => {
    if (props.data) {
      if (!values.password) {
        delete values.password;
      }
    }
    setIsDisabled(true);
    console.log(values);

    var hitMethod = props.data ? "PUT" : "POST";
    var hitUrl = props.data ? "customers/" + props.data.id : "customers";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          if (props.onSubmit) {
            props.onSubmit();
          }
          if (!props.data) {
            if (location.pathname === "/customers") {
              window.location.reload(false);
            } else {
              window.location = "/customers";
            }
          }
          props.setCcvisible(false);
        }
        setIsDisabled(false);
        openSnackbar(response.data.message);
      }
    );
  };

  const validationSchema = Yup.object({
    first_name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid Email Address").required("Required"),
    phone: Yup.string()
      .matches(
        /^([+ ]*)([\d{1,2}]*)(\d{5}([- ]*)\d{5})$/g,
        "Invalid phone number"
      )
      .min(9, "Min length is 10")
      .max(14, "Too Long!")
      .required("Required"),
    password: props.data
      ? ""
      : Yup.string()
        .required("Required")
        .min(6, "Password is too short - should be 6 chars minimum"),
  });

  if (props.ccvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? "Edit" : "Create"} Customer
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
        >
          {({ values, isValid, setFieldValue }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label="First Name"
                name="first_name"
                divstyle={{
                  display: "inline-block",
                  width: "50%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              // onChange={(event) => {
              //   const re = /[^A-Za-z]/gi;
              //   if (event.target.value && !re.test(event.target.value)) {
              //     return;
              //   } else {
              //     setFieldValue("first_name", event.target.value);
              //   }
              // }}
              />
              <FormikControl
                control="input"
                type="text"
                label="Last Name"
                name="last_name"
                divstyle={{
                  display: "inline-block",
                  width: "50%",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />
              <FormikControl
                control="input"
                type="email"
                label="Email ID"
                name="email"
              />

              <FormikControl
                control="input"
                type="text"
                label="Password"
                name="password"
              />
              <FormikControl
                control="numberInput"
                type="text"
                label="Phone"
                name="phone"
                onChange={(event) => {
                  const re = /^[0-9-+\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("phone", event.target.value);
                  }
                }}
              />

              {settings?.business_accounts == 1 && (
                <>
                  <FormikControl
                    control="select"
                    as="select"
                    label="Account Type"
                    name="account_type"
                    options={[
                      { title: "Personal", value: "personal" },
                      { title: "Business", value: "business" },
                    ]}
                    key_title="title"
                    key_value="value"
                  />
                  {values.account_type == "business" && (
                    <>
                      <FormikControl
                        control="input"
                        type="text"
                        label="Business Name"
                        name="business_name"
                      />

                      {props.data?.id && (
                        <>
                          <GText
                            text={"Business Branding Info"}
                            style={{
                              margin: "15px 15px 5px",
                              letterSpacing: "0.4px",
                              fontSize: "14px",
                              width: "auto",
                            }}
                          />
                          <GImageEditor
                            onSubmit={(newValue, id, thumb_val) => {
                              values.business_logo = newValue;
                              setFieldValue("business_logo", newValue);
                            }}
                            id="business_logo"
                            image={
                              values.business_logo
                                ? ImgUrl("logo") + "/" + values.business_logo
                                : ""
                            }
                            type="logo"
                            style={{ height: "140px", paddingTop: "60px" }}
                            divstyle={{
                              width: "calc(100% - 10px)",
                              display: "block",
                              padding: "5px 10px",
                            }}
                            imgStyle={{ objectFit: "contain" }}
                          />
                          <FormikControl
                            control="input"
                            type="text"
                            name="business_logo"
                            style={{ display: "none" }}
                          />

                          <div
                            style={{
                              margin: "0 15px 20px",
                              position: "relative",
                            }}
                          >
                            <label
                              style={{ margin: "5px 0", display: "block" }}
                            >
                              Color1
                            </label>
                            <ColorPicker
                              id={"Color1"}
                              defaultcolor={values.color1}
                              onChange={(newVal, fieldId) => {
                                setFieldValue("color1", newVal);
                              }}
                            />
                          </div>

                          <div
                            style={{
                              margin: "0 15px 20px",
                              position: "relative",
                            }}
                          >
                            <label
                              style={{ margin: "5px 0", display: "block" }}
                            >
                              Color2
                            </label>
                            <ColorPicker
                              id={"Color2"}
                              defaultcolor={values.color2}
                              onChange={(newVal, fieldId) => {
                                setFieldValue("color2", newVal);
                              }}
                            />
                          </div>

                          <FormikControl
                            control="toggle"
                            label="Bulk Allowed"
                            isToggled={values.bulk_active == "1" ? 1 : 0}
                            name="bulk_active"
                            onToggle={() => setFieldValue("bulk_active", values.bulk_active == 1 ? 0 : 1)}
                            style={{
                              display: "inline-flex",
                              width: "50%",
                              boxSizing: "border-box",
                              verticleAlign: "top",
                              marginBottom: 25,
                            }}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              <FormikControl
                control="toggle"
                label="API Allowed"
                isToggled={values.rest_active == "1" ? 1 : 0}
                name="rest_active"
                onToggle={() => setFieldValue("rest_active", values.rest_active == 1 ? 0 : 1)}

                style={{
                  display: "inline-flex",
                  width: "50%",
                  boxSizing: "border-box",
                  verticleAlign: "top",
                  marginBottom: 25,
                }}
              />
              <br />
              <GButton
                variant="condensed"
                disabled={isDisabled}
                style={{
                  margin: "0 15px",
                  width: "calc(100% - 30px)",
                }}
                children={props.data ? "Update" : "Create"}
                type="submit"
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
