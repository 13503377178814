import React, { useEffect, useState } from "react";
import { makeStyles, Paper, Backdrop } from "@material-ui/core";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import styled from "styled-components";
import GButton from "../gComponents/gButton";

const Div = styled.div`
  margin: 20px 15px;

  p {
    font-size: 17px;
    font-weight: 500;
    margin: 0;
    padding: 10px 0;

    span {
      color: #777;
    }
  }

  h4 {
    font-size: 17px;
    margin: 20px 0 15px;
  }
  .radio {
    padding: 7px 5px;
    input {
      margin: 0 10px;
      vertical-align: middle;
    }
  }
`;
const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function ReciveOrderPayment(props) {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const [selectedGateway, setSelectedGateway] = useState("cash");

  const onRecievePayment = () => {
    if (!selectedGateway) {
      openSnackbar("Choose payment method");
      return;
    }
    var values = {
      amount: props.pendingAmount,
      gateway: selectedGateway,
    };
    console.log(values);
    //return;
    ApiService({
      method: "POST",
      route: "order/payment/" + props.linkedId,
      body: values,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        props.setTransModalVisible(false);
        props.onSubmit();
      }
      openSnackbar(response.data.message);
      return;
    });
  };

  const handleClose = (e) => {
    if (e.target.id !== (2739).toString()) {
      return;
    }
    props.setTransModalVisible(false);
  };

  if (props.transModalVisible === false) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2739"
        className={classes.backdrop}
        open={props.transModalVisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper
          className={`modalRight
              ${props.transModalVisible === true ? "fadeM" : ""}`}
          style={{ padding: "20px", maxWidth: "500px" }}
        >
          <h3>Receive Pending Payment</h3>

          <Div>
            <p style={{ color: "#0381FC" }}>#{props.linkedId}</p>
            <p>
              <i>
                Pending Amount - <span>{props.amount}</span>
              </i>
            </p>
            <h4>Choose Payment Method</h4>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value="cash"
                  checked={selectedGateway === "cash"}
                  onChange={() => setSelectedGateway("cash")}
                />
                Cash On Delivery
              </label>
            </div>
            <br />
            <GButton
              variant="condensed"
              children="Receive"
              onClick={() => {
                onRecievePayment();
              }}
              style={{
                margin: "10px 5px",
              }}
            ></GButton>{" "}
          </Div>
        </Paper>
        {/* </Popover> */}
      </Backdrop>
    </div>
  );
}
