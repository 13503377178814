import React, { useState, useEffect } from "react";
import { makeStyles, Paper, TableContainer } from "@material-ui/core";
import { ApiService } from "../services";
import { Redeem, Edit, Delete } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import { Link, useHistory } from "react-router-dom";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GButton from "../gComponents/gButton";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GPagination from "../gComponents/gPagination";
import GPaginationSelect from "../gComponents/gPaginationSelectbox";
import GSwitch from "../gComponents/gSwitch";
import CouponForm from "../forms/couponForm";
import GSelectbox from "../gComponents/gSelectbox";
import QueryWith from "../Query";
import { useSnackbar } from "react-simple-snackbar";
import Modal from "react-modal";
// import { browserHistory } from "react-router";

const columns = [
  { id: "title", label: "Title", minWidth: 130 },
  { id: "discount", label: "Discount", minWidth: 50 },
  { id: "coupon_code", label: "Coupon Code", minWidth: 120 },
  { id: "time_schedule", label: "Time Schedule", minWidth: 150 },
  { id: "status", label: "Status", minWidth: 50 },
  { id: "actions", label: "Actions", minWidth: 60, align: "center" },
];
const statusFilters = [
  {
    title: "Active",
    value: "1",
  },
  {
    title: "Inactive",
    value: "0",
  },
];

const useStyles = makeStyles({
  root: {
    padding: "18px",
    width: "100%",
    boxSizing: "border-box",
    background: "#fff",
    // background: '#222529',
    border: "1px solid #e8e8e8",
    boxShadow: "none",
    borderRadius: "0px",
  },
});

export default function Coupons() {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const [couponData, setCouponData] = useState([]);
  const [couponPaginationData, setCouponPaginationData] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCouponData, setSelectedCouponData] = useState({
    index: "",
    data: {},
  });
  const [selectedCouponId, setSelectedCouponId] = useState("");
  const [ccpnvisible, setCcpnvisible] = useState(false);
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    status: params.get("status") ? params.get("status") : "",
  });

  const getCoupons = () => {
    var params = QueryWith(history.location, filters);
    if (params.length > 0) {
      history.push({
        pathname: "/coupons",
        state: "coupon",
        search: params[0],
      });
    }
    // var hitUrl = "coupons?page=" + currentPage + "&include_expired=true";

    ApiService({ method: "GET", route: "coupons", body: filters })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setCouponData(response.data.data.data || []);
          setCouponPaginationData(response.data.data || {});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getCoupons();
  }, [history, filters]);

  const deleteCoupon = () => {
    ApiService({ method: "DELETE", route: "coupons/" + selectedCouponId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getCoupons();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const toggleValue = (newValue, valueId, i) => {
    ApiService({
      method: "PUT",
      route: "coupons/" + valueId,
      body: { status: newValue },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          if (filters.status) {
            couponData.splice(i, 1);
            setCouponData(couponData);
          } else {
            couponData[i].status = newValue;
            setCouponData([...couponData]);
          }
        } else {
          getCoupons();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ ...filters, page: NewPageNo });
  };

  return (
    <div className="mainInnerView">
      <h1>
        <Redeem /> Coupons
      </h1>

      <div>
        <GSelectbox
          placeholder="Select Status"
          defaultvalue={filters.status}
          data={statusFilters}
          id="item_status_filter"
          option_key="title"
          option_value="value"
          onChange={(newVal) => {
            setFilters({ ...filters, status: newVal, page: 1 });
          }}
          style={{
            border: "1px solid #e2e2e2",
            height: "40px",
            marginLeft: "10px",
            minWidth: "150px",
          }}
        />
      </div>
      {couponPaginationData ? (
        <div style={{ textAlign: "right" }}>
          <GPagination
            style={{ display: "inline-block" }}
            count={couponPaginationData.total}
            from={couponPaginationData.from || 0}
            to={couponPaginationData.to || 0}
            onChangeNextPage={() =>
              handleChangePage(couponPaginationData.next_page_url)
            }
            onChangePrevPage={() =>
              handleChangePage(couponPaginationData.prev_page_url)
            }
            rowsPerPage={couponPaginationData.per_page}
            prevDisabled={couponPaginationData.prev_page_url ? false : true}
            nextDisabled={couponPaginationData.next_page_url ? false : true}
          ></GPagination>
          {couponPaginationData ? (
            <GPaginationSelect
              defaultvalue={filters.page}
              totalPage={couponPaginationData.last_page}
              onChangePage={(NewPageNo) =>
                setFilters({ ...filters, page: NewPageNo })
              }
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      <Paper className={classes.root}>
        {!couponPaginationData ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <TableContainer className={classes.container}>
            {couponData.length === 0 ? (
              <GEmpty></GEmpty>
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableHeader
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          textAlign: column.align,
                        }}
                      >
                        {column.label}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {couponData.map((c, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                        <TableRowData
                          style={{
                            BorderBottom: "1px solid #f4f5f9",
                          }}
                        >
                          {c.title}
                        </TableRowData>
                        <TableRowData> {c.discount}% </TableRowData>
                        <TableRowData>{c.code}</TableRowData>
                        <TableRowData>
                          Valid From :{" "}
                          {c.valid_from ? (
                            c.valid_from
                          ) : (
                            <span style={{ color: "#a2a2a2" }}>NA</span>
                          )}
                          <br />
                          Valid Upto :{" "}
                          {c.expiry ? (
                            c.expiry
                          ) : (
                            <span style={{ color: "#a2a2a2" }}>NA</span>
                          )}
                        </TableRowData>
                        <TableRowData>
                          <GSwitch
                            id={c.id}
                            uId={c.id}
                            currentValue={
                              c.status === "true" || c.status === "1" ? 1 : 0
                            }
                            onSwitchChange={(newVal, id) =>
                              toggleValue(newVal, id, index)
                            }
                          />
                        </TableRowData>

                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <GButton
                            variant="linkable"
                            onClick={(e) => {
                              setSelectedCouponData({ index: index, data: c });
                              setCcpnvisible(true);
                            }}
                          >
                            <Edit fontSize="small" />
                          </GButton>

                          <GButton
                            variant="linkable"
                            //  onClick={(e) =>
                            //       window.confirm(
                            //         "Are you sure you wish to delete this customer?"
                            //       ) && deleteCustomer(c.id)
                            // }

                            onClick={(e) => {
                              setSelectedCouponId(c.id);
                              setDialogOpen(true);
                            }}
                          >
                            <Delete fontSize="small" />
                          </GButton>
                        </TableRowData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </Paper>

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Coupon"
          text="Are you sure you want to delete this coupon?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteCoupon();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}

      <Modal
        isOpen={ccpnvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCcpnvisible(false)}
        style={{
          content: {
            width: "500px",
          },
        }}
        contentLabel="Coupon Form Modal"
      >
        <CouponForm
          ccpnvisible={ccpnvisible}
          setCcpnvisible={setCcpnvisible}
          data={selectedCouponData.data}
          onSubmit={(data) => {
            console.log(data);
            if (data) {
              couponData[selectedCouponData.index] = data;
            }
          }}
        />
      </Modal>
    </div>
  );
}
