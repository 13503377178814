import React, { useState, useEffect } from "react";
import { makeStyles, Paper, TableContainer, Avatar } from "@material-ui/core";
import { ApiService, ImgUrl } from "../services";
import { PeopleAlt } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import { Link, useHistory } from "react-router-dom";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GButton from "../gComponents/gButton";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GPagination from "../gComponents/gPagination";
import GPaginationSelect from "../gComponents/gPaginationSelectbox";
import { useSnackbar } from "react-simple-snackbar";
import DriverCreate from "../forms/driverForm";
import QueryWith from "../Query";
import { GSearch } from "../gComponents/gSearch";
import GSelectbox from "../gComponents/gSelectbox";
import useSettings from "../useSettings";
import Modal from "react-modal";

const columns = [
  { id: "first_name", label: "Name", minWidth: 130 },
  { id: "photo", label: "Photo", minWidth: 50, type: "image" },
  { id: "email", label: "Email", minWidth: 150, align: "center" },
  { id: "phone", label: "Mobile", minWidth: 120, align: "center" },
  // { id: "vehicle_type", label: "Vehicle Type", minWidth: 120, align: "center" },
  // {
  //   id: "wallet_points_value",
  //   label: "Balance",
  //   minWidth: 90,
  //   align: "center",
  //   // format: (value) => value.toLocaleString('en-US'),
  //   format: (value) => value.toFixed(2),
  // },
  {
    id: "created_at_formatted",
    label: "Created",
    minWidth: 150,
    align: "center",
  },
];

const statusFilters = [
  {
    title: "Active",
    value: "1",
  },
  {
    title: "Inactive",
    value: "0",
  },
];

const useStyles = makeStyles({
  root: {
    padding: "18px",
    width: "100%",
    boxSizing: "border-box",
    background: "#fff",
    // background: '#222529',
    border: "1px solid #e8e8e8",
    boxShadow: "none",
    borderRadius: "0px",
    marginTop: "10px",
  },
});

export default function Drivers() {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const [cdvisible, setCdvisible] = useState(false);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [driverData, setDriverData] = useState([]);
  const [driverPaginationData, setDriverPaginationData] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedDriverData, setSelectedDriverData] = useState({});
  const [selectedDriverId, setSelectedDriverId] = useState("");
  const settings = useSettings(true);
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  console.log(JSON.stringify(history.location.search));
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    search: params.get("search") ? params.get("search") : "",
    // vehicle_type_id: params.get("vehicle_type_id")
    //   ? params.get("vehicle_type_id")
    //   : "",
    // driver_status: params.get("driver_status")
    //   ? params.get("driver_status")
    //   : "",
  });

  const getDrivers = () => {
    var params = QueryWith(history.location, filters);
    console.log(params);
    if (params.length > 0) {
      history.push({
        pathname: "/drivers",
        state: "driver",
        search: params[0],
      });
    }
    ApiService({ method: "GET", route: "drivers", body: filters })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          setDriverData(response.data.data.data);
          setDriverPaginationData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getDrivers();
  }, [filters, history]);

  useEffect(() => {
    ApiService({ method: "GET", route: "vehicle/types" })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          setVehicleTypes(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ ...filters, page: NewPageNo });
  };

  return (
    <div className="mainInnerView">
      <h1>
        <PeopleAlt /> Drivers
      </h1>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          marginBottom: "15px",
          gap: "10px",
        }}
      >
        <GSearch
          placeholder="Search by name, email or phone.."
          value={filters.search}
          handleChange={(val) =>
            setFilters({ ...filters, search: val, page: 1 })
          }
        />
        {/* <GSelectbox
          placeholder="All"
          defaultvalue={filters.driver_status}
          data={statusFilters}
          id="driver_status_filter"
          option_key="title"
          option_value="value"
          onChange={(newVal) => {
            setFilters({ ...filters, driver_status: newVal, page: 1 });
          }}
          style={{
            border: "1px solid #e2e2e2",
            height: "40px",
            minWidth: "150px",
          }}
        />
        <GSelectbox
          placeholder="Select Vehicle Type"
          defaultvalue={filters.vehicle_type_id}
          data={vehicleTypes}
          id="driver_vehicle_type_filter"
          option_key="title"
          option_value="id"
          onChange={(newVal) => {
            setFilters({ ...filters, vehicle_type_id: newVal, page: 1 });
          }}
          style={{
            border: "1px solid #e2e2e2",
            height: "40px",
            minWidth: "150px",
          }}
        /> */}

        {driverData.length !== 0 ? (
          <React.Fragment>
            <GPagination
              style={{ marginLeft: "auto" }}
              count={driverPaginationData.total}
              from={driverPaginationData.from}
              to={driverPaginationData.to}
              onChangeNextPage={() =>
                handleChangePage(driverPaginationData.next_page_url)
              }
              onChangePrevPage={() =>
                handleChangePage(driverPaginationData.prev_page_url)
              }
              rowsPerPage={driverPaginationData.per_page}
              prevDisabled={driverPaginationData.prev_page_url ? false : true}
              nextDisabled={driverPaginationData.next_page_url ? false : true}
            ></GPagination>
            {driverPaginationData ? (
              <GPaginationSelect
                defaultvalue={filters.page}
                totalPage={driverPaginationData.last_page}
                onChangePage={(NewPageNo) =>
                  setFilters({ ...filters, page: NewPageNo })
                }
              />
            ) : (
              ""
            )}
          </React.Fragment>
        ) : (
          ""
        )}
      </div>
      <Paper className={classes.root}>
        {!driverPaginationData ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <TableContainer className={classes.container}>
            {driverData.length === 0 ? (
              <GEmpty></GEmpty>
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableHeader
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          textAlign: column.align,
                        }}
                      >
                        {column.label}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {driverData.map((c) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                        <TableRowData
                          style={{
                            BorderBottom: "1px solid #f4f5f9",
                          }}
                        >
                          {c.first_name} {c.last_name}
                        </TableRowData>
                        <TableRowData>
                          <Avatar
                            alt={c.first_name}
                            src={ImgUrl("user") + "/" + c.thumb_photo}
                          />
                        </TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {c.email || "-"}
                        </TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {c.phone || "-"}
                        </TableRowData>
                        {/* <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {c.vehicle_type_id
                            ? vehicleTypes
                                .filter((t) => t.id == c.vehicle_type_id)
                                .map((v) => {
                                  return v.title;
                                })
                            : "-"}
                        </TableRowData> */}
                        {/* <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {c.balance
                            ? settings.currency_symbol + "" + c.balance
                            : "-"}
                        </TableRowData> */}
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {c.created}
                        </TableRowData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </Paper>

      <Modal
        isOpen={cdvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCdvisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Driver Form Modal"
      >
        <DriverCreate
          cdvisible={cdvisible}
          setCdvisible={setCdvisible}
          data={selectedDriverData}
          onSubmit={getDrivers}
        />
      </Modal>
    </div>
  );
}
