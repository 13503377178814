import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useDetectOutsideClick } from "../useDetectOutsideClick";

const LI = styled.li`
  padding: 4px 6px;
  color: #333;
  font-size: 14px;
  display: flex;
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  line-height: 18px;
  &:hover {
    background: #f4f5f9;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const DIV = styled.div`
  background: transparent;
  border-radius: 4px;
  user-select: none;

  .selected {
    color: #0381fe!important;
  }

  .titleIcon {
    svg {
      margin: 0 2px;
      width: 14px !important;
      height: 14px !important;
    }
  }
`;

export const GSelectCheckbox = ({
  title,
  data,
  value,
  style,
  handleChange,
  countData,
  activeColor,
  option_key,
  option_value,
}) => {
  const wrapperMultiSelectRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [showFilterList, setShowFilterList] = useDetectOutsideClick(
    wrapperMultiSelectRef,
    false
  );

  useEffect(() => {
    console.log(value);
    setSelectedValue(value || "");
  }, [value]);

  return (
    <div
      ref={wrapperMultiSelectRef}
      style={Object.assign(
        {},
        {
          width: "212px",
          display: "inline-block",
          position: "relative",
        },
        style
      )}
    >
      <DIV
        style={{
          borderRadius: "4px",
          // height: "34px",
          fontSize: "0.97rem",
          color: "#555",
          boxSizing: "border-box",
          padding: "3px",
          fontWeight: "500",
          cursor: "pointer",
          background: selectedValue.length > 0 ? activeColor : "transparent",
        }}
        onClick={() => {
          setShowFilterList(!showFilterList);
        }}
        className={selectedValue.length > 0 ? "active" : ""}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid #e2e2e2",
          }}
        >
          {selectedValue ? (
            <p
              className="selected"
              style={{
                margin: "0",
                padding: "0 3px",
                textOverflow: "ellipsis",
                maxWidth: "160px",
                whiteSpace: "pre",
                overflow: "hidden",
                fontSize: "14px",
                fontWeight: "550",
                flex: "1",
                // display: "-webkit-box",
                // webkitLineClamp: "1",
                // webkitBoxOrient: "vertical",
              }}
            >
              {/* {selectedValue.length == data.length ? (
                <span style={{}}>All {title}</span>
              ) : ( */}
              {data
                .filter((d) => d[option_value] == selectedValue)
                .map((v, i) => {
                  return (
                    <span key={i} style={{}}>
                      {v[option_key]}
                    </span>
                  );
                })}
            </p>
          ) : (
            <span
              style={{
                color: "#999",
                padding: "0 3px",
                display: "inline-block",
                fontSize: "14.5px",
                fontWeight: "450",
                fontStyle: "italic",
              }}
            >
              {title}
            </span>
          )}
        </div>
      </DIV>
      {showFilterList === true ? (
        <div
          style={{
            background: "#fff",
            position: "absolute",
            zIndex: "9",
            boxSizing: "border-box",
            width: "100%",
            minWidth: "190px",
            padding: "3px",
            borderRadius: "4px",
            marginTop: "4px",
            border: "1px solid #ddd",
            // top: "-145px",
          }}
        >
          <ul
            style={{
              listStyleType: "none",
              padding: "2px 0",
              margin: "0",
              maxHeight: "150px",
              overflow: "auto",
            }}
          >
            {data.map((c, i) => {
              return (
                <LI
                  key={i}
                  onClick={() => {
                    setSelectedValue(c[option_value] || "");
                    handleChange(c[option_value] || "", c);
                    setShowFilterList(false);
                  }}
                >
                  {c[option_key]}{" "}
                </LI>
              );
            })}
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
