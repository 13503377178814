import React, { useState, useEffect } from "react";
import { Edit } from "@material-ui/icons";
import { ApiService } from "../services";
import GInfo from "../gComponents/gInfo";
import { Card, CardHead } from "../styledComponents/cardStyles";
import { GContent, GTableContent } from "../gComponents/gContent";
import { Switch, Route } from "react-router-dom";
import GTabs from "../gComponents/gTabs";
import Orders from "../orders/ordersList";
import Addresses from "../components/addressList";
import WalletPoints from "../components/walletPointsList";
import Documents from "../components/documentsList";
import { useSnackbar } from "react-simple-snackbar";
import CustomerCreate from "../forms/customerForm";
import useSettings from "../useSettings";
import GRouting from "../gComponents/gRouteBreadCrumbs";
import Modal from "react-modal";

const RoutLinks = [
  {
    title: "Customers",
    link: "/customers",
    state: "customer",
  },
];
export default function CustomerDetail(props) {
  const [customerId, setCustomerId] = useState("");
  const [customerData, setCustomerData] = useState([]);
  const [customerInsights, setCustomerInsights] = useState({});
  const [typeValue, setTypeValue] = useState("orders");
  const [ccvisible, setCcvisible] = useState(false);
  const settings = useSettings(true);
  const [openSnackbar] = useSnackbar();
  const faqRoutes = [
    {
      title: "orders",
      link: "/customer/" + customerId + "/orders",
      state: "customer",
    },
    {
      title: "address",
      link: "/customer/" + customerId + "/address",
      state: "customer",
    },
    {
      title: "delivAIry Points",
      link: "/customer/" + customerId + "/wallet",
      state: "customer",
    },
    {
      title: "documents",
      link: "/customer/" + customerId + "/documents",
      state: "customer",
    },
  ];

  const account_types = [
    { title: "Personal", value: "personal" },
    { title: "Business", value: "business" }
  ]

  const getCustomerDetail = () => {
    ApiService({
      method: "GET",
      route: "customers/" + props.match.params.customerId,
    })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          setCustomerData(response.data.data);
          setCustomerInsights(response.data.data.insights);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log(props.match.params.customerId);
    setCustomerId(props.match.params.customerId);

    getCustomerDetail();
  }, [props, customerId]);

  function handleChange(newValue) {
    setTypeValue(newValue);
  }
  function handleUpdateImage(imgValue, id, thumbValue) {
    console.log(imgValue);
    var data = { photo: imgValue, thumb_photo: thumbValue };
    ApiService({
      method: "PUT",
      route: "customers/" + customerId,
      body: data,
    }).then((response) => {
      console.log(response);
      if (response.data.status_code === 1) {
        setCustomerData(response.data.data);
      }
      openSnackbar(response.data.message);
    });
  }

  return (
    <div className="mainInnerView" style={{ padding: "20px 20px 0" }}>
      <GRouting links={RoutLinks} currentPage={customerData.title} />
      <div style={{ width: "50%", display: "inline-block" }}>
        <GInfo
          id={customerData.id}
          title={customerData.title}
          key={customerData.id}
          className="headerInfo"
          imgType="user"
          imgEdit={true}
          photo={customerData.photo}
          uploadImage={handleUpdateImage}
        />
      </div>

      <br />

      <div
        style={{
          width: "30%",
          display: "inline-block",
          padding: "10px",
          verticalAlign: "top",
          boxSizing: "border-box",
        }}
      >
        <Card style={{ minHeight: "200px" }}>
          <div>
            <CardHead>
              Contact Details <Edit onClick={() => setCcvisible(true)} />
            </CardHead>
            <GContent title="Email" value={customerData.email} />
            <GContent title="Phone" value={customerData.phone} />
            {settings?.business_accounts == 1 && (
              <>
                <GContent
                  title="Account Type"
                  value={account_types.find(a => a.value == customerData?.account_type)?.title}
                />
                {customerData?.account_type == "business" && (
                  <GContent
                    title="Business Name"
                    value={customerData?.business_name}
                  />
                )}
              </>
            )}
          </div>
        </Card>

        <Card style={{ minHeight: "200px" }}>
          <div>
            <CardHead>Smart Insights</CardHead>
            <GTableContent
              title="Last Order"
              value={
                customerInsights.last_ordered
                  ? customerInsights.last_ordered
                  : "-"
              }
              align="right"
            />
            <GTableContent
              title="Open Orders"
              value={customerInsights.open_orders}
              innerValue={
                settings.currency_symbol +
                "" +
                customerInsights.open_orders_amount
              }
              align="right"
            />
            <GTableContent
              title="Orders Completed"
              value={customerInsights.completed_orders}
              innerValue={
                settings.currency_symbol +
                "" +
                customerInsights.completed_order_amount
              }
              align="right"
            />
            <GTableContent
              title="Balance"
              value={customerData.wallet}
              innerValue={settings.currency_symbol + customerData.balance}
              align="right"
            />
          </div>
        </Card>
        <Modal
          isOpen={ccvisible}
          className="modal modalRight"
          overlayClassName="modal-overlay"
          onRequestClose={() => setCcvisible(false)}
          style={{
            content: {
              width: "420px",
            },
          }}
          contentLabel="Customer Form Modal"
        >
          <CustomerCreate
            ccvisible={ccvisible}
            setCcvisible={setCcvisible}
            data={customerData}
            onSubmit={getCustomerDetail}
          />
        </Modal>
      </div>

      <div
        style={{
          width: "calc(100% - 30%)",
          display: "inline-block",
          padding: "10px",
          verticalAlign: "top",
          boxSizing: "border-box",
        }}
      >
        <GTabs routes={faqRoutes} active={typeValue}></GTabs>

        <Switch>
          <Route exact path={"/customer/" + customerId + "/orders"}>
            <Orders
              onChange={handleChange}
              type="customer"
              value={customerId}
              title="customer_id"
            />
          </Route>
          <Route exact path={"/customer/" + customerId + "/address"}>
            <Addresses
              onChange={handleChange}
              type="customer"
              value={customerId}
              title="customer_id"
            />
          </Route>
          <Route exact path={"/customer/" + customerId + "/wallet"}>
            <WalletPoints
              onChange={handleChange}
              type="customer"
              value={customerId}
              title="customer_id"
            />
          </Route>
          <Route exact path={"/customer/" + customerId + "/documents"}>
            <Documents
              onChange={handleChange}
              type="customer"
              value={customerId}
              title="customer_id"
            />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
