import React, { useState } from "react";
import GView from "../gComponents/GView";
import GText from "../gComponents/gText";
import GInput from "../gComponents/gOrderFormInput";
import GSelectbox from "../gComponents/gSelectbox";
import styled from "styled-components";
import { ReactComponent as Check } from "../assets/images/tick.svg";
import { ReactComponent as UnCheck } from "../assets/images/untick.svg";
import { ImgUrl } from "../services";
import GImageEditor from "../gComponents/gFormImageEditor";
import GDocEditor from "../gComponents/gFormDocEditor";

const DIV = styled.div`
  padding: 0 10px 5px;
  & .inputDiv {
    display: flex;
    align-items: center;
    padding: 2px 0;
    margin: 10px 0;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
      &.checked {
        path {
          fill: #0381fe;
        }
      }
    }
    label {
      font-size: 14px;
      color: #333;
      cursor: pointer;
    }
  }
`;

const OrderForm = (props) => {
  const [fieldsData, setFieldsData] = useState(props.data || []);
  const initialValues = {
    fields: props.data || [],
  };

  return (
    <GView style={{ background: "#fff", padding: "0 10px" }}>
    {fieldsData.map((field, i) => {
      return (
        <GView
          key={i}
          style={{ borderBottom: "1px solid #e9e9e9", padding: "10px" }}
        >
          <GText
            semi
            text={field.required == 1 ? field.title + "*" : field.title}
            style={{
              // fontStyle: "italic",
              margin: "10px 8px 0",
              fontSize: "14.5px",
              color: "#333",
            }}
          />
          {field.type === "textfield" && (
            <GInput
              type="text"
              required={field.required == 1 ? true : false}
              name={field.id}
              id={field.id + "_" + i}
              placeholder="Type here"
              value={field.value}
              display="block"
              onChange={(e) => {
                field.value = e.target.value;
                fieldsData[i].value = e.target.value;
                setFieldsData([...fieldsData]);
                props.onChange(fieldsData);
              }}
              style={{
                background: "#fff",
                border: "0px solid #f2f2f2",
                color: "#333",
              }}
              // onBlur={(e) => {
              //   field.value = e;
              //   fieldsData[i].value = e;
              //   setFieldsData([...fieldsData]);
              // }}
            />
          )}
          {field.type === "phone" && (
            <GInput
              type="text"
              required={field.required == 1 ? true : false}
              name={field.id}
              id={field.id + "_" + i}
              value={fieldsData[i].value}
              display="block"
              placeholder="Type here"
              style={{
                background: "#fff",
                // border: "1px solid #f2f2f2",
                color: "#333",
              }}
              onKeyPress={(e) => {
                const re = /^[0-9+\b]+$/;
                if (e.target.value && !re.test(e.target.value)) {
                  return;
                } else {
                  fieldsData[i].value = e.target.value;
                  setFieldsData([...fieldsData]);
                  props.onChange(fieldsData);
                  return;
                }
              }}
              onChange={(e) => {
                const re = /^[0-9+\b]+$/;
                if (e.target.value && !re.test(e.target.value)) {
                  return;
                } else {
                  fieldsData[i].value = e.target.value;
                  setFieldsData([...fieldsData]);
                  props.onChange(fieldsData);
                  return;
                }
              }}
            />
          )}

          {field.type === "numeric" ? (
            <GInput
              type="text"
              required={field.required == 1 ? true : false}
              name={field.id}
              id={field.id + "_" + i}
              value={field.value}
              display="block"
              placeholder="Type here"
              style={{
                background: "#fff",
                // border: "1px solid #f2f2f2",
                color: "#333",
              }}
              onChange={(e) => {
                const re = /^[0-9. \b]+$/;
                if (e.target.value && !re.test(e.target.value)) {
                  return;
                } else {
                  fieldsData[i].value = e.target.value;
                  setFieldsData([...fieldsData]);
                  props.onChange(fieldsData);
                  return;
                }
              }}
            />
          ) : (
            ""
          )}

          {field.type === "email" && (
            <GInput
              type="text"
              required={field.required == 1 ? true : false}
              name={field.id}
              id={field.id + "_" + i}
              value={field.value}
              placeholder="Type here"
              display="block"
              style={{
                background: "#fff",
                // border: "1px solid #f2f2f2",
                color: "#333",
              }}
              onChange={(e) => {
                field.value = e.target.value;
                fieldsData[i].value = e.target.value;
                setFieldsData([...fieldsData]);
                props.onChange(fieldsData);
              }}
            />
          )}
          {field.type === "selectbox" && (
            <GSelectbox
              placeholder="Choose"
              defaultvalue={field.value?.title}
              data={field.data?.options}
              id="customer_status_filter"
              option_key="title"
              option_value="title"
              onChange={(newVal) => {
                let selectedVal = field.data?.options.filter(
                  (o) => o.title == newVal
                );
                console.log(selectedVal);
                field.value = selectedVal.length > 0 ? selectedVal[0] : {};
                fieldsData[i].value =
                  selectedVal.length > 0 ? selectedVal[0] : {};
                setFieldsData([...fieldsData]);
                props.onChange(fieldsData);
              }}
              style={{
                color: "#555",
                width: "calc(100% - 10px)",
                border: "1px solid #f2f2f2",
                margin: "5px",
              }}
            />
          )}

          {field.type === "priceoptions" && (
            <DIV>
              {field.data.options.map((o, ind) => {
                return (
                  <GView
                    className="inputDiv"
                    onClick={() => {
                      console.log(o);
                      field.value = o;
                      fieldsData[i].value = o;
                      setFieldsData([...fieldsData]);
                      props.onChange(fieldsData);
                    }}
                    key={ind}
                  >
                    <input
                      name={field.id}
                      type="radio"
                      id={"v_option_" + ind}
                      value={o}
                      defaultChecked={field.value == o}
                      style={{ display: "none" }}
                    />
                    {field.value === o ? (
                      <Check className="checked" />
                    ) : (
                      <UnCheck />
                    )}
                    &nbsp;&nbsp;
                    <label htmlFor={"v_option_" + ind} style={{}}>
                      {o.title}
                    </label>
                    <GText
                      g6
                      text={"+ " + props.settings?.currency_symbol + o.amount}
                      style={{ margin:0,marginLeft: "auto",width:"auto" }}
                    />
                  </GView>
                );
              })}
            </DIV>
          )}

          {field.type == "image" && (
            <GView>
              <GImageEditor
                onSubmit={(newValue, id, thumb_val) => {
                  field.value = newValue;
                  fieldsData[i].value = newValue;
                  setFieldsData([...fieldsData]);
                  props.onChange(fieldsData);
                }}
                id={`thumb_photo_${field.id}`}
                image={field.value ? ImgUrl("form") + "/" + field.value : ""}
                type="form"
                style={{ height: "155px", paddingTop: "60px" }}
                divstyle={{ margin: "0", display: "block" }}
              />
            </GView>
          )}

          {field.type == "document" && (
            <GView>
              <GDocEditor
                onSubmit={(newValue) => {
                  field.value = newValue
                    ? JSON.stringify(newValue)
                    : newValue;
                  fieldsData[i].value = newValue
                    ? JSON.stringify(newValue)
                    : newValue;
                  setFieldsData([...fieldsData]);
                  props.onChange(fieldsData);
                }}
                id={`doc_${field.id}`}
                file={field.value || ""}
                type="form"
              />
            </GView>
          )}
        </GView>
      );
    })}
  </GView>
  );
};

export default OrderForm;
