import React, { useState, useRef } from "react";
import { csvFileApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import { CustomerFilter } from "../components/customerFilterComponent";
import sampleFile from "../assets/files/sample-bulk-orders.csv";
import GText from "../gComponents/gText";

export default function OrderBulkUpload(props) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [openSnackbar] = useSnackbar();
  const fileInput = useRef(null);
  const [selectedFile, setSelectedFile] = useState({});

  const initialValues = {
    customer_id: "",
  };

  const onSubmit = (values) => {
    if (!selectedFile) {
      openSnackbar("File is required");
      return;
    }
    setIsDisabled(true);
    console.log(values);
    csvFileApiService("POST", "bulk/orders", {
      csv: selectedFile,
      customer_id: values.customer_id,
    }).then((response) => {
      console.log(response);
      if (response.status_code === 1) {
        props.setOUploadVisible(false);
        props.onSubmit();
      }
      setIsDisabled(false);
      openSnackbar(response.message);
      return;
    });
  };

  const validationSchema = Yup.object({
    customer_id: Yup.string().required("Required"),
  });

  if (props.oUploadVisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        Bulk Orders Upload
        <a
          href={sampleFile}
          download
          style={{
            textDecoration: "underline",
            color: "#f23938",
            fontSize: "14px",
            marginLeft: "auto",
          }}
        >
          Download Sample file
        </a>
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div
                style={{
                  padding: "4px 15px",
                  display: "inline-block",
                  verticalAlign: "top",
                  boxSizing: "border-box",
                  width: "100%",
                }}
              >
                <label>Customer</label>
                <CustomerFilter
                  value={values.customer_id}
                  style={{
                    marginBottom: "2px",
                    display: "block",
                    width: "100%",
                  }}
                  handleCustomerChange={(value) => {
                    console.log(value);
                    setFieldValue("customer_id", value ? value : "");
                  }}
                  title={""}
                  perPageVal="50"
                />
                <FormikControl
                  control="input"
                  type="text"
                  label=""
                  name="customer_id"
                  style={{ display: "none" }}
                  divstyle={{ padding: "0" }}
                />
              </div>
              <div
                style={{
                  padding: "4px 15px",
                  display: "inline-block",
                  verticalAlign: "top",
                  boxSizing: "border-box",
                  width: "100%",
                }}
              >
                <GText med text={"Choose File"} />
                <GButton
                  variant="outlined"
                  type="button"
                  onClick={() => document.getElementById("orderFile").click()}
                  style={{
                    padding: "5px 15px",
                    borderRadius: "0",
                  }}
                >
                  Choose a file
                </GButton>
                &nbsp;
                <GText
                  med
                  text={
                    fileInput?.current?.value
                      ? selectedFile?.name
                      : "No file chosen.."
                  }
                  style={{ display: "inline", width: "auto" }}
                />
                <input
                  type="file"
                  id="orderFile"
                  ref={fileInput}
                  onChange={(e) => {
                    console.log(e.target.files[0]);
                    setSelectedFile(e.target.files[0]);
                  }}
                  style={{ display: "none" }}
                />
              </div>
              <br />
              <br />
              <GButton
                children={"Upload"}
                variant="condensed"
                type="submit"
                disabled={isDisabled}
                style={{ width: "calc(100% - 30px", margin: "0 15px" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
