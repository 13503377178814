import React, { useState } from "react";
import styled from "styled-components";
import { CheckCircle } from "@material-ui/icons";
import { ApiService } from "../services";
import GConfirmDialog from "./gConfirmDialog";
import { useSnackbar } from "react-simple-snackbar";

const TaskStatusDiv = styled.div`
  position: relative;

  ul {
    list-style-type: none;
    padding: 0px;
    margin: 20px 0;

    li {
      display: inline-block;
      padding: 0 20px 0 0px;
      min-width: 140px;
      max-width: 24%;
      box-sizing: border-box;
      div {
        display: inline-block;
        vertical-align: middle;
        padding-left: 10px;
        box-sizing: border-box;
      }
    }
  }
  svg {
    margin-top: 5px;
  }
  & h4 {
    font-size: 14px;
    margin: 2px 0;
    font-weight: 400;
    color: #333;
  }
  & p {
    font-size: 14px;
    margin: 0;
    font-weight: 400;
    color: #777;
    line-height: 22px;
  }
`;

export default function GTaskStatusView(props) {
  const [selectedTaskId, setSelectedTaskId] = useState();
  const [selectedTaskStatus, setSelectedTaskStatus] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openSnackbar] = useSnackbar();

  function updateTaskStatus() {
    ApiService({
      method: "PUT",
      route: "order/tasks/" + selectedTaskId,
      body: {
        status: selectedTaskStatus,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.onStatusUpdate();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <React.Fragment>
      <TaskStatusDiv>
        <ul>
          <li
            style={{
              cursor:
                props.orderStatus === "Accepted" ||
                props.orderStatus === "Started"
                  ? "pointer"
                  : "auto",
            }}
            onClick={() => {
              if (
                props.orderStatus === "Accepted" ||
                props.orderStatus === "Started"
              ) {
                setSelectedTaskId(props.data.id);
                setSelectedTaskStatus("accepted");
                setDialogOpen(true);
              }
            }}
          >
            <CheckCircle
              style={{ color: props.data.accepted ? "#333" : "#ccc" }}
            />
            <div>
              <p>Accepted</p>
              <h4>{props.data.accepted || "-"}</h4>
            </div>
          </li>
          <li
            style={{
              cursor:
                props.orderStatus === "Accepted" ||
                props.orderStatus === "Started"
                  ? "pointer"
                  : "auto",
            }}
            onClick={() => {
              if (
                props.orderStatus === "Accepted" ||
                props.orderStatus === "Started"
              ) {
                setSelectedTaskId(props.data.id);
                setSelectedTaskStatus("started");
                setDialogOpen(true);
              }
            }}
          >
            <CheckCircle
              style={{ color: props.data.started ? "#333" : "#ccc" }}
            />
            <div>
              <p>Started</p>
              <h4>{props.data.started || "-"}</h4>
            </div>
          </li>
          <li
            style={{
              cursor:
                props.orderStatus === "Accepted" ||
                props.orderStatus === "Started"
                  ? "pointer"
                  : "auto",
            }}
            onClick={() => {
              if (
                props.orderStatus === "Accepted" ||
                props.orderStatus === "Started"
              ) {
                setSelectedTaskId(props.data.id);
                setSelectedTaskStatus("arrived");
                setDialogOpen(true);
              }
            }}
          >
            <CheckCircle
              style={{ color: props.data.arrived ? "#333" : "#ccc" }}
            />
            <div>
              <p>Arrived</p>
              <h4>{props.data.arrived || "-"}</h4>
            </div>
          </li>
          <li
            style={{
              cursor:
                props.orderStatus === "Accepted" ||
                props.orderStatus === "Started"
                  ? "pointer"
                  : "auto",
            }}
            onClick={() => {
              if (
                props.orderStatus === "Accepted" ||
                props.orderStatus === "Started"
              ) {
                setSelectedTaskId(props.data.id);
                setSelectedTaskStatus("completed");
                setDialogOpen(true);
              }
            }}
          >
            <CheckCircle
              style={{ color: props.data.completed ? "#333" : "#ccc" }}
            />
            <div>
              <p>Completed</p>
              <h4>{props.data.completed || "-"}</h4>
            </div>
          </li>
        </ul>
      </TaskStatusDiv>

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Update Task Status"
          text="Are you sure you want to update status?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            updateTaskStatus();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}
    </React.Fragment>
  );
}
