import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ApiService } from "../services";
import { TableContainer } from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import { Edit } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GSwitch from "../gComponents/gSwitch";
import GButton from "../gComponents/gButton";
import GSelectbox from "../gComponents/gSelectbox";
import useDataFctory from "../useDataFactory";
import NotificationFormPopup from "../forms/notificationsForm";
import useSettings from "../useSettings";
import Modal from "react-modal";

const columns = [
  { id: "event", label: "Event", minWidth: 150 },
  { id: "sms", label: "SMS", minWidth: 100 },
  { id: "email", label: "EMAIL", minWidth: 100 },
  { id: "push", label: "PUSH", minWidth: 100 },
  { id: "actions", label: "Actions", minWidth: 60, align: "center" },
];

const notifyTypesValues = [
  {
    label: "Pickup",
    value: "pickup",
  },
  {
    label: "Delivery",
    value: "delivery",
  },
];

export default function NotificationsSetting(props) {
  let history = useHistory();
  const [openSnackbar] = useSnackbar();
  const [notificationTypeValue, setNotificationTypeValue] = useState(
    props.type === "task" ? props.nType : ""
  );
  const [selectedNotification, setSelectedNotification] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [nfvisible, setNfvisible] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const settings = useSettings();
  const [initialLoading, setInitialLoading] = useState(true);

  const {
    loading,
    data: notificationsData,
    Placeholder,
    TableShimmer,
    refreshData,
  } = useDataFctory("notification-settings", false, {
    user_type: props.userType,
    notification_type: props.nType,
  });

  const [filters, setFilters] = useState({
    user_type: props.userType || "customer",
    notification_type: props.type === "task" ? props.nType : "",
  });

  useEffect(() => {
    props.onChange(notificationTypeValue);
  }, [notificationTypeValue]);

  useEffect(() => {
    if (initialLoading == false) {
      let data = {
        user_type: props.userType || "customer",
        notification_type: props.type === "task" ? props.nType : "",
      };
      setFilters(data);
      refreshData(data);
    }

    setInitialLoading(false);
  }, [props]);

  function getChangedValue(newValue) {
    setNotificationTypeValue(newValue ? newValue : "delivery");
    props.onChange(props.userType, newValue || "delivery");
    history.push({
      pathname:
        "/settings/task-notifications/" + props.userType + "/" + newValue
          ? newValue
          : "delivery",
      search: "",
    });
    //getNotifications();
  }

  const handleSwitchValue = (newValue, selectedId, medium, ind) => {
    ApiService({
      method: "PUT",
      route: "notification-settings",
      body: {
        medium: medium,
        id: selectedId,
        status: newValue,
      },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          notificationsData[ind][medium].status = newValue;
        } else {
          refreshData({
            user_type: props.userType || "customer",
            notification_type: props.type === "task" ? props.nType : "",
          });
        }

        openSnackbar(response.data.message);
        console.log(notificationsData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      {loading === true ? (
        <TableShimmer />
      ) : (
        <div>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {filters.user_type === "admin"
                    ? columns
                        .filter((c) => c.id !== "push" && c.id !== "sms")
                        .map((column) => (
                          <TableHeader
                            key={column.id}
                            style={{
                              minWidth: column.minWidth,
                              textAlign: column.align,
                            }}
                          >
                            {column.label}
                          </TableHeader>
                        ))
                    : columns.map((column) => (
                        <TableHeader
                          key={column.id}
                          style={{
                            minWidth: column.minWidth,
                            textAlign: column.align,
                          }}
                        >
                          {column.label}
                        </TableHeader>
                      ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {notificationsData.length > 0 &&
                  notificationsData.map((c, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={c.identifier}
                      >
                        <TableRowData
                          style={{
                            BorderBottom: "1px solid #f4f5f9",
                          }}
                        >
                          {c.title}
                        </TableRowData>
                        {filters.user_type !== "admin" ? (
                          <TableRowData>
                            {!c.sms || c.sms.status === 2 ? (
                              "-"
                            ) : (
                              <GSwitch
                                id={"sms" + c.identifier}
                                uId={c.sms.id}
                                currentValue={c.sms.status}
                                onSwitchChange={(newvalue, id) =>
                                  handleSwitchValue(newvalue, id, "sms", index)
                                }
                                // onChange={(e) => {
                                //   switchActionValue(t);
                                // }}
                              />
                            )}
                          </TableRowData>
                        ) : (
                          ""
                        )}
                        <TableRowData>
                          {!c.email || c.email.status === 2 ? (
                            "-"
                          ) : (
                            <GSwitch
                              id={"email" + c.identifier}
                              uId={c.email.id}
                              currentValue={c.email.status}
                              handleToggle={(e) => {
                                //switchActionValue(c, "email");
                              }}
                              onSwitchChange={(newvalue, id) =>
                                handleSwitchValue(newvalue, id, "email", index)
                              }
                            />
                          )}
                        </TableRowData>
                        {filters.user_type !== "admin" ? (
                          <TableRowData>
                            {!c.push || c.push.status === 2 ? (
                              "-"
                            ) : (
                              <GSwitch
                                id={"push" + c.identifier}
                                uId={c.push.id}
                                currentValue={c.push.status}
                                onSwitchChange={(newvalue, id) =>
                                  handleSwitchValue(newvalue, id, "push", index)
                                }
                                handleToggle={(e) => {
                                  // switchActionValue(c, "push");
                                }}
                              />
                            )}
                          </TableRowData>
                        ) : (
                          ""
                        )}
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <GButton
                            variant="linkable"
                            onClick={(e) => {
                              setSelectedNotification(c);
                              setNfvisible(true);
                            }}
                          >
                            <Edit fontSize="small" />
                          </GButton>
                        </TableRowData>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {notificationsData.length === 0 ? <GEmpty></GEmpty> : ""}
          </TableContainer>
        </div>
      )}

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Customer"
          text="Are you sure you want to delete this customer?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}

      <Modal
        isOpen={nfvisible}
        className="modal modalCentered"
        overlayClassName="modal-overlay"
        onRequestClose={() => setNfvisible(false)}
        style={{
          content: {
            width: "550px",
          },
        }}
        contentLabel="Notification Form Modal"
      >
        <NotificationFormPopup
          nfvisible={nfvisible}
          setNfvisible={setNfvisible}
          data={selectedNotification}
          userType={props.userType}
          onSubmit={() => {
            refreshData({
              user_type: props.userType || "customer",
              notification_type: props.type === "task" ? props.nType : "",
            });
          }}
          notificationType={notificationTypeValue}
        />
      </Modal>
    </div>
  );
}
