import axios from "axios";
require("dotenv").config();

let isRefreshing = false;
let failedQueue = [];
console.log("ccc");

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// ADD A REQUEST INTERCEPTOR
axios.interceptors.request.use(
  (config) => {
    console.log(config);
    config.baseURL = process.env.REACT_APP_API_URL;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//ADD A RESPONSE INTERCEPTOR
axios.interceptors.response.use(
  (response) => {
    console.log("failed 13");
    return response;
  },
  async (error) => {
    console.log(error);
    const originalRequest = error.config;

    if (
      error.response.status === 401 &&
      originalRequest.url === "oauth/token"
    ) {
      console.log("failed 11");
      localStorage.clear();
      window.location = "/";
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            console.log(token);
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      let refreshToken = localStorage.getItem("refreshToken");
      console.log(refreshToken);

      return new Promise(function (resolve, reject) {
        axios
          .post("oauth/token", {
            client_id: "2",
            client_secret: "f36F4ZZN84kWE9cwYbFj2Y6er5geY9OBXF3hEQO4",
            grant_type: "refresh_token",
            refresh_token: refreshToken,
            provider: "admins",
          })
          .then(async ({ data }) => {
            console.log(data);
            localStorage.setItem("zAccessToken", data.access_token);
            localStorage.setItem("refreshToken", data?.refresh_token);
            let now = (Date.now() / 1000) | 0;
            localStorage.setItem("zExpiresIn", now + data.expires_in);

            axios.defaults.headers.common["Authorization"] =
              "Bearer " + data?.access_token;
            originalRequest.headers["Authorization"] =
              "Bearer " + data?.access_token;
            processQueue(null, data?.access_token);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);
