import React, { useState, useEffect } from "react";
import { makeStyles, Paper, TableContainer } from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import { ApiService } from "../services";
import { Add, Edit, Delete } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GButton from "../gComponents/gButton";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GPagination from "../gComponents/gPagination";
import AddressForm from "../forms/addressForm";
import Modal from "react-modal";

const columns = [
  { id: "location_name", label: "Location Name", minWidth: 140 },
  { id: "address_line1", label: "Address Line1", minWidth: 150 },
  // { id: "Title", label: "Title", minWidth: 50 },
  // { id: "city", label: "City", minWidth: 50 },
  // { id: "state", label: "State", minWidth: 60 },
  // { id: "country", label: "Country", minWidth: 60 },
  { id: "zipcode", label: "Zipcode", minWidth: 60 },
  { id: "actions", label: "Actions", minWidth: 60, align: "center" },
];

const useStyles = makeStyles({
  root: {
    padding: "18px",
    width: "100%",
    boxSizing: "border-box",
    background: "#fff",
    // background: '#222529',
    border: "1px solid #e8e8e8",
    boxShadow: "none",
    borderRadius: "0px",
  },
  container: {
    maxHeight: "calc(100vh - 320px)",
    overflow: "overlay",
  },
});

export default function Addresses(props) {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();

  const [addressData, setAddressData] = useState([]);
  const [addressPaginationData, setAddressPaginationData] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState("");
  const [selectedAddressId, setSelectedAddressId] = useState("");
  const [selectedAddressData, setSelectedAddressData] = useState({});
  const [currentPage, setCurrentPage] = useState("");
  const [afvisible, setAfvisible] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  async function getAddress() {
    let obj = {};
    obj[props.title] = props.value;

    ApiService({ method: "GET", route: "addresses", body: obj })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          setAddressData(response.data.data.data || []);
          setAddressPaginationData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    console.log(props);
    if (props.type) {
      props.onChange("address");
    }
    setSelectedCustomerId(props.value);

    getAddress();
  }, [props, currentPage]);

  const deleteAddress = () => {
    var hitUrl = "addresses/" + selectedAddressId;
    ApiService({ method: "DELETE", route: hitUrl })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getAddress();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setCurrentPage(NewPageNo);
  };

  return (
    <div className={props.type ? "" : "mainInnerView"}>
      {props.type ? "" : <h1>Addresses</h1>}

      <GButton
        variant="linkable"
        children="Add"
        onClick={() => {
          setSelectedAddressData({});
          setIsEmpty(true);
          setAfvisible(true);
        }}
        style={{
          margin: "-40px 0px 10px",
          float: "right",
        }}
      >
        <Add fontSize="large" />
      </GButton>

      <Paper className={classes.root}>
        {addressData.length !== 0 ? (
          <GPagination
            count={addressPaginationData.total}
            from={addressPaginationData.from}
            to={addressPaginationData.to}
            onChangeNextPage={() =>
              handleChangePage(addressPaginationData.next_page_url)
            }
            onChangePrevPage={() =>
              handleChangePage(addressPaginationData.prev_page_url)
            }
            rowsPerPage={addressPaginationData.per_page}
            prevDisabled={addressPaginationData.prev_page_url ? false : true}
            nextDisabled={addressPaginationData.next_page_url ? false : true}
          ></GPagination>
        ) : (
          ""
        )}
        {!addressPaginationData ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <TableContainer className={classes.container}>
            {addressData.length === 0 ? (
              <GEmpty></GEmpty>
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableHeader
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          textAlign: column.align,
                        }}
                      >
                        {column.label}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {addressData.map((c) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                        <TableRowData>{c.location_name}</TableRowData>
                        <TableRowData>{c.line1}</TableRowData>
                        {/* <TableRowData style={{ textTransform: 'uppercase' }}>{c.title}</TableRowData> */}
                        {/* <TableRowData>{c.city}</TableRowData> */}
                        {/* <TableRowData>{c.state}</TableRowData> */}
                        {/* <TableRowData>{c.country}</TableRowData> */}
                        <TableRowData>{c.zipcode}</TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <GButton
                            variant="linkable"
                            onClick={(e) => {
                              setSelectedAddressData(c);
                              setIsEmpty(false);
                              setAfvisible(true);
                            }}
                          >
                            <Edit fontSize="small" />
                          </GButton>

                          <GButton
                            variant="linkable"
                            onClick={(e) => {
                              setSelectedAddressId(c.id);
                              setDialogOpen(true);
                            }}
                          >
                            <Delete fontSize="small" />
                          </GButton>
                        </TableRowData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </Paper>

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Address"
          text="Are you sure you want to delete this address?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteAddress();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}

      <Modal
        isOpen={afvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setAfvisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Address Form Modal"
      >
        <AddressForm
          afvisible={afvisible}
          setAfvisible={setAfvisible}
          data={selectedAddressData}
          onSubmit={getAddress}
          type={props.type}
          id={selectedCustomerId}
          isEmpty={isEmpty}
        />
      </Modal>
    </div>
  );
}
