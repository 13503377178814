import styled from "styled-components";

export const Card = styled.div`
  background: #fff;
  padding: 15px;
  margin-bottom: 20px;

  &:hover .MuiSvgIcon-root {
    display: inline-block;
  }
`;

export const CardHead = styled.h4`
  color: #000;
  font-size: 18px;
  margin: 0 0 15px 0;
  padding: 0;
  font-weight: 500;
  & .MuiSvgIcon-root {
    float: right;
    color: #555;
    font-size: 1.2rem;
    display: none;
    cursor: pointer;
  }
`;

export const CardBody = styled.p`
  color: #333;
  font-size: 15px;
  margin: 0px;
  line-height: 20px;
`;
