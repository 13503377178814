import React from "react";
import styled from "styled-components";
import { Skeleton } from "@material-ui/lab";

const CardShimmer = styled.div`
  div {
    display: inline-block;
    width: 32%;
    box-sizing: border-box;
    padding: 10px;
    flex: 1;
    flex-direction: row;
    .MuiSkeleton-root {
      width: 100%;
      background: #f6f7f8;

      &.MuiSkeleton-rect {
        margin-top: 0px;
        margin-bottom: 10px;
      }
      &.MuiSkeleton-text {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
  }
`;

function GCardShimmer() {
  return (
    <CardShimmer>
      <div>
        <Skeleton variant="text" height={90} animation="wave" />
        <Skeleton variant="rect" height={20} animation="wave" />
        <Skeleton variant="rect" height={20} animation="wave" />
      </div>
      <div>
        <Skeleton variant="text" height={90} animation="wave" />
        <Skeleton variant="rect" height={20} animation="wave" />
        <Skeleton variant="rect" height={20} animation="wave" />
      </div>
      <div>
        <Skeleton variant="text" height={90} animation="wave" />
        <Skeleton variant="rect" height={20} animation="wave" />
        <Skeleton variant="rect" height={20} animation="wave" />
      </div>
    </CardShimmer>
  );
}

export default GCardShimmer;
