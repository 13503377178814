import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useSnackbar } from "react-simple-snackbar";
import { docFileApiService, ImgUrl } from "../services";
import GButton from "./gButton";
import GText from "./gText";
import GView from "./GView";
import Loader from "../assets/images/loader.svg";
import { ReactComponent as FileIcon } from "../assets/images/file.svg";

const Div = styled.div`
  & svg.MuiSvgIcon-root {
    bottom: 0;
    right: -5px;
  }

  p {
    flex: 1;
  }
`;
const RDiv = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  // width: calc(100% - 100px);
  p {
    // width: 95%;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
  }
`;
function GDocEditor(props) {
  const [fileType] = useState(props.type);
  const fileInput = useRef(null);
  const [editableId] = useState(props.id); 
  const [openSnackbar] = useSnackbar();
  const [fileValue, setFileValue] = useState({});
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    let file = props.file ? JSON.parse(props.file) : props.file;
    setFileValue(file || {});
  }, [props]);

  const changeFileHandler = (e) => {
    setUploading(true);
    var files = fileInput.current.files[0];

    docFileApiService("POST", fileType, files)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          if (props.edit !== false) {
            setFileValue(response.data || {});
          }
          props.onSubmit(response.data, props.id);
          fileInput.current.value = "";
        } else {
          console.log(response.data);
          openSnackbar(response.message);
        }
        setUploading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <input
        id={props.id + "_imgInput"}
        type="file"
        ref={fileInput}
        onChange={changeFileHandler}
        style={{ display: "none" }}
        //onChange={(e) => setSelectedValue(e.target.files[0])}
      />

      <Div
        style={Object.assign(
          {},
          {
            padding: "10px 5px",
            position: "relative",
            boxSizing: "border-box",
            display: "flex",
            // alignItems: "center",
            verticalAlign: "top",
            flexDirection: "column",
          },
          props.divstyle
        )}
      >
        {uploading === true ? (
          <GButton
            variant="condensed"
            disabled={true}
            style={{
              borderRadius: "4px",
              padding: "0.5em 1.5em",
              fontWeight: "600",
            }}
          >
            Uploading.. &nbsp;
            <img
              src={Loader}
              alt="loading"
              style={{ verticalAlign: "middle", float: "right" }}
            />
          </GButton>
        ) : fileValue?.path ? (
          <>
            <RDiv>
              <FileIcon
                style={{ width: "30px", height: "30px", marginRight: "5px" }}
              />
              <GView>
                <GText semi text={fileValue?.title} />
                <GText dark text={`${fileValue?.size / 1000} KB`} />
              </GView>
            </RDiv>
            <GButton
              variant="linkable"
              onClick={() => {
                setFileValue({});
                props.onSubmit();
              }}
              style={{ textDecoration: "underline", marginTop: "5px" }}
            >
              Remove
            </GButton>
          </>
        ) : (
          // <GText med text={"No file chosen..."} />
          <GButton
            variant="outlined"
            onClick={() => {
              document.getElementById(props.id + "_imgInput").click();
            }}
            style={{
              borderRadius: "4px",
              padding: "0.5em 1.5em",
              fontWeight: "600",
            }}
          >
            Choose
          </GButton>
        )}
      </Div>
    </React.Fragment>
  );
}

export default GDocEditor;
