import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ApiService } from "../services";
import { TableContainer, Link } from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import { Edit, Delete, Add } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GButton from "../gComponents/gButton";
import CancelReasonCreate from "../forms/cancelReasonForm";
import Modal from "react-modal";

const columns = [
  { id: "title", label: "Title", minWidth: 200 },
  { id: "actions", label: "Actions", minWidth: 60, align: "center" },
];

export default function Child(props) {
  let { type } = useParams();
  var typeId = type === "customer" ? "1" : type === "manager" ? "2" : "3";

  const [openSnackbar] = useSnackbar();
  const [cancelReasonsData, setCancelReasonsData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState({});
  const [selectedReasonId, setSelectedReasonId] = useState("");
  const [crvisible, setCrvisible] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getOrderCancelReasons();
    props.onChange(type);
  }, [type]);

  const getOrderCancelReasons = () => {
    ApiService({ method: "GET", route: "cancel-reasons?user_type=" + typeId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setCancelReasonsData(response.data.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteReason = () => {
    ApiService({
      method: "DELETE",
      route: "cancel-reasons/" + selectedReasonId,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getOrderCancelReasons();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.

  return (
    <div>
      <GButton
        variant="linkable"
        children="Add"
        onClick={() => {
          setSelectedReason({});
          setIsEmpty(true);
          setCrvisible(true);
        }}
        style={{
          margin: "-60px -15px 0px",
          float: "right",
        }}
      >
        <Add fontSize="large" />
      </GButton>

      {isLoading === true ? (
        <GTableShimmer></GTableShimmer>
      ) : (
        <TableContainer>
          {cancelReasonsData.length === 0 ? (
            <GEmpty></GEmpty>
          ) : (
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableHeader
                      key={column.id}
                      style={{
                        minWidth: column.minWidth,
                        textAlign: column.align,
                      }}
                    >
                      {column.label}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {cancelReasonsData.map((c) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                      <TableRowData
                        style={{
                          BorderBottom: "1px solid #f4f5f9",
                        }}
                      >
                        {c.title}
                      </TableRowData>
                      <TableRowData
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <GButton
                          variant="linkable"
                          children="Add"
                          onClick={() => {
                            setSelectedReason(c);
                            setIsEmpty(false);
                            setCrvisible(true);
                          }}
                        >
                          <Edit fontSize="small" />
                        </GButton>

                        <GButton
                          variant="linkable"
                          //  onClick={(e) =>
                          //       window.confirm(
                          //         "Are you sure you wish to delete this customer?"
                          //       ) && deleteCustomer(c.id)
                          // }

                          onClick={(e) => {
                            setSelectedReasonId(c.id);
                            setDialogOpen(true);
                          }}
                        >
                          <Delete fontSize="small" />
                        </GButton>
                      </TableRowData>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      )}

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Cancel Reason"
          text="Are you sure you want to delete this cancel reason?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteReason();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}

      <Modal
        isOpen={crvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCrvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Cancel Reason Form Modal"
      >
        <CancelReasonCreate
          crvisible={crvisible}
          setCrvisible={setCrvisible}
          type={typeId}
          data={selectedReason}
          isEmpty={isEmpty}
          onSubmit={getOrderCancelReasons}
        />
      </Modal>
    </div>
  );
}
