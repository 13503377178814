import React, { useState, useEffect, useContext } from "react";
import { ImgUrl, ApiService } from "../services";
import GView from "../gComponents/GView";
import GText from "../gComponents/gText";
import styled from "styled-components";
import { GContent } from "../gComponents/gContent";
import moment from "moment-timezone";
import { GTableContent } from "../gComponents/gContent";
import useSettings from "../useSettings";
import { useSnackbar } from "react-simple-snackbar";

const StopsDiv = styled.div`
  border: 1px solid #e9e9e9;
  background: #fff;
  padding: 12px;
  margin-bottom: 12px;
  // cursor: pointer;

  .type {
    border: 1px solid #777;
    border-radius: 20px;
    text-align: center;
    width: 21px;
    height: 21x;margin:0;

    &.exist {
      background: #0381fe;
      border: 1px solid #0381fe;
      color: #fff!important;
    }
  }
`;

const OrderReview = (props) => { 
  const [openSnackbar] = useSnackbar();
  const settings = useSettings(true);
  const { categories, containers, fits, weights } = props.items;
  const [checkoutData, setCheckoutData] = useState({
    summary: {
      data: [],
    },
    wallet: {},
  });

  useEffect(() => {
    console.log(props.data);
    let COrderData = { ...props.data };
    ApiService({
      method: "POST",
      route: "order/checkout",
      body: COrderData,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setCheckoutData(response.data.data);
        } else {
          setCheckoutData(response.data.data);
          openSnackbar(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.data]);

  return (
    <GView style={{ padding: "5px 0" }}>
      <GText g4 semi text={"Order Review"} style={{ marginBottom: "20px" }} />
      {props.data.stops.map((s, i) => {
        return (
          <StopsDiv key={i}>
            {s.type === "pickup" ? (
              <GView
                style={{
                  display: "flex",
                  alignItems: "start",
                  cursor: !s.lat ? "pointer" : "",
                }}
              >
                <GText text={"P"} className={s.lat ? "type exist" : "type"} />
                &nbsp;&nbsp;
                {s.lat ? (
                  <GView style={{ width: "calc(100% - 50px)" }}>
                    <GText
                      semi
                      text={`${s.location_name} (${s.title})`}
                      style={{
                        textTransform: "uppercase",
                        paddingBottom: "3px",margin:0
                      }}
                    />
                    <GText text={s?.formatted_display || s.line1} style={{ color: "#333",margin:0 }} />
                    {props.settings?.schedule_enabled == "1" &&
                    s.complete_before ? (
                      <GText
                        med
                        g6
                        text={`Before - ${moment(s.complete_before).format(
                          "MMM DD, hh:mm a"
                        )}`}
                      />
                    ) : (
                      ""
                    )}
                    <hr
                      style={{
                        borderTop: "0",
                        borderBottom: "1px solid #e2e2e2",
                      }}
                    />
                    <GText
                      text={
                        s.rec_name +
                        " . " +
                        s.rec_phone +
                        (s.rec_email ? " . " + s.rec_email : "")
                      }
                      style={{margin:0}}
                    />
                  </GView>
                ) : (
                  <GText text={"Choose pickup address"} style={{margin:0}}/>
                )}
              </GView>
            ) : (
              <GView
                style={{
                  display: "flex",
                  alignItems: "start",
                  cursor: !s.lat ? "pointer" : "",
                }}
              >
                <GText text={"D"} className={s.lat ? "type exist" : "type"} />
                &nbsp;&nbsp;
                {s.lat ? (
                  <GView style={{ width: "calc(100% - 50px)" }}>
                    <GText
                      semi
                      text={`${s.location_name} (${s.title})`}
                      style={{
                        textTransform: "uppercase",
                        paddingBottom: "3px",margin:0
                      }}
                    />
                    <GText text={s?.formatted_display||s.line1} style={{ color: "#333",margin:0 }} />
                    {props.settings?.schedule_enabled == "1" &&
                    s.complete_before ? (
                      <GText
                        med
                        g6
                        text={`Before - ${moment(s.complete_before).format(
                          "MMM DD, hh:mm a"
                        )}`}
                      />
                    ) : (
                      ""
                    )}
                    <hr
                      style={{
                        borderTop: "0",
                        borderBottom: "1px solid #e2e2e2",
                      }}
                    />
                    <GText
                      text={
                        s.rec_name +
                        " . " +
                        s.rec_phone +
                        (s.rec_email ? " . " + s.rec_email : "")
                      }
                      style={{margin:0}}
                    />
                  </GView>
                ) : (
                  <GText text={"Choose delivery address"} style={{margin:0}}/>
                )}
              </GView>
            )}
          </StopsDiv>
        );
      })}

      <GView
        style={{
          background: "#fff",
          padding: "10px 15px",
          border: "1px solid #e2e2e2",
        }}
      >
        <GContent
          title="Vehicle Type"
          value={props.vehicleTypes
            .filter((v) => v.id == props.data.vehicle_type_id)
            .map((veh) => {
              return veh.title;
            })}
        />

        {props.data?.scheduled_from && (
          <GContent
            title="Pickup and Delivery Window"
            value={
              props.data?.scheduled_formatted?.date +
              ", " +
              props.data?.scheduled_formatted?.from_display +
              " - " +
              props.data?.scheduled_formatted?.to_display
            }
          />
        )}

        {props.data?.special_instructions && (
          <GContent
            title="Special Instructions"
            value={props.special_instructions
              .filter((s) => s.value == props.data?.special_instructions)
              .map((ins) => {
                return ins.title;
              })}
          />
        )}

        {props.data?.fields
          .filter((f) => f.value)
          .map((field, i) => {
            return (
              <>
                {field.type == "image" ? (
                  <div style={{ margin: "10px 0" }}>
                    <GText
                      semi
                      text={field.title}
                      style={{ margin: "5px 0" }}
                    />
                    {field.value ? (
                      <img
                        src={`${ImgUrl("form")}/${field.value}`}
                        style={{ maxWidth: "100%", height: "100px" }}
                      />
                    ) : (
                      "-"
                    )}
                  </div>
                ) : field.type == "document" ? (
                  <div style={{ margin: "10px 0" }}>
                    <GText
                      semi
                      text={field.title}
                      style={{ margin: "5px 0" }}
                    />
                    <a
                      target="_blank"
                      href={`${ImgUrl("form")}/${field.value?.path}`}
                      style={{ wordBreak: "break-word" }}
                    >
                      {ImgUrl("form")}/{field.value?.path}
                    </a>
                  </div>
                ) : field.type == "priceoptions" ? (
                  <div style={{ padding: "5px 0" }}>
                    <GText
                      semi
                      text={field.title}
                      style={{ margin: "2px 0" }}
                    />
                    <p
                      style={{ display: "flex", margin: "0", fontSize: "14px" }}
                    >
                      {field.value?.title}{" "}
                      <span style={{ marginLeft: "auto" }}>
                        {props.settings?.currency_symbol + field.value?.amount}
                      </span>
                    </p>
                  </div>
                ) : (
                  <GContent
                    key={i}
                    title={field.title}
                    value={field.value}
                    textTransform="capitalize"
                  />
                )}
              </>
            );
          })}
      </GView>
      {props.data?.items.length > 0 && (
        <GView
          style={{
            background: "#fff",
            padding: "10px 15px",
            border: "1px solid #e2e2e2",
            marginTop: "10px",
          }}
        >
          <GText semi text={"Items"} style={{ margin: "2px 0" }} />

          {props.data.items.map((item, i) => {
            return (
              <p
                key={i}
                style={{
                  fontSize: "14px",
                  lineHeight: "19px",
                  color: "#333",
                  margin: "0",
                  padding: "10px 0",
                  borderBottom:
                    i + 1 < props.data.items.length ? "1px solid #e2e2e2" : "0",
                }}
              >
                I am sending{" "}
                {categories
                  .filter((c) => c.id == item.category)
                  .map((cat) => {
                    return cat.title;
                  })}{" "}
                packed in {item.quantity}{" "}
                {containers
                  .filter((c) => c.id == item.packaging)
                  .map((con) => {
                    return con.title;
                  })}
                . The total order will{" "}
                {fits
                  .filter((c) => c.id == item.fit)
                  .map((fit) => {
                    return fit.title;
                  })}{" "}
                and weighs{" "}
                {weights
                  .filter((c) => c.id == item.weight)
                  .map((w) => {
                    return w.title;
                  })}{" "}
                pounds.
                <GView style={{ marginTop: "8px" }}>
                  <GText
                    semi
                    text={"Additional information"}
                    style={{ fontSize: "14px" ,margin:0}}
                  />
                  {item.notes}
                </GView>
              </p>
            );
          })}
        </GView>
      )}
      <GView
        style={{
          background: "#fff",
          padding: "10px 15px",
          border: "1px solid #e2e2e2",
          marginTop: "10px",
        }}
      >
        <GText
          semi
          text={"Payment Summary"}
          style={{
            margin: "2px 0",
          }}
        />
        <GView style={{ padding: "10px 0" }}>
          {checkoutData?.summary?.data.map((p) => {
            return (
              <GTableContent
                key={p.title}
                title={p.title}
                value={settings.currency_symbol + "" + p.value}
                align="right"
                lAlign="right"
                color="#333"
                padding="2px 5px"
              />
            );
          })}
          <hr
            style={{
              border: "0",
              borderBottom: "1px solid #e2e2e2",
              margin: "5px 0",
            }}
          />
          <GTableContent
            title="Grand Total"
            value={
              settings.currency_symbol + checkoutData?.summary?.total_display
            }
            align="right"
            lAlign="right"
            bold={true}
          />{" "}
        </GView>
      </GView>
    </GView>
  );
};

export default OrderReview;
