import React, { useState, useEffect } from "react";
import { makeStyles, Paper, TableContainer } from "@material-ui/core";
import { ApiService } from "../services";
import { CheckCircleOutline, Payment } from "@material-ui/icons";
import { useSnackbar } from "react-simple-snackbar";
import {
  Link,
  useHistory,
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
} from "react-router-dom";
import GTabs from "../gComponents/gTabs";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GButton from "../gComponents/gButton";
import GEmpty from "../gComponents/gEmpty";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GTableShimmer from "../gComponents/gTableShimmer";
import GPagination from "../gComponents/gPagination";
import useSettings from "../useSettings";
import { CustomerFilter } from "./customerFilterComponent";
import QueryWith from "../Query";

const columns = [
  // { id: "order_id", label: "Order Id", minWidth: 80 },
  { id: "method", label: "Method", minWidth: 100, align: "" },
  { id: "trans_id", label: "Trans Id", minWidth: 100 },
  { id: "customer_id", label: "Customer", minWidth: 120 },
  { id: "amount", label: "Amount", minWidth: 80 },
  { id: "status", label: "Status", minWidth: 50, align: "center" },
  {
    id: "added",
    label: "Added",
    minWidth: 60,
    align: "center",
    type: "resolved",
  },
  {
    id: "resolved",
    label: "Resolved",
    minWidth: 60,
    align: "center",
    type: "pending",
  },
];
const useStyles = makeStyles({
  root: {
    padding: "18px",
    width: "100%",
    boxSizing: "border-box",
    background: "#fff",
    // background: '#222529',
    border: "1px solid #e8e8e8",
    boxShadow: "none",
    borderRadius: "0px",
  },
  container: {
    maxHeight: "calc(100vh - 260px)",
    overflow: "auto",
    padding: "0 20px",
    boxSizing: "border-box",
  },
});

const docRoutes = [
  {
    title: "pending",
    link: "/failed-payments/pending",
  },
  {
    title: "resolved",
    link: "/failed-payments/resolved",
  },
];

export default function FailedTransactions() {
  const [typeValue, setTypeValue] = useState("pending");
  useEffect(() => {
    console.log("value" + typeValue);
  });

  function handleChange(newValue) {
    console.log(newValue);
    setTypeValue(newValue);
  }
  return (
    <div className="mainInnerView">
      <h1>
        <Payment /> Failed Payments
      </h1>

      <GTabs routes={docRoutes} active={typeValue}></GTabs>

      <Switch>
        <div className="listPaper" style={{ padding: "20px 0" }}>
          <Route path="/failed-payments/:type">
            <Child onChange={handleChange} />
          </Route>
        </div>
      </Switch>
    </div>
  );
}

function Child(props) {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  let { type } = useParams();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState("");
  const [transactionData, setTransactionData] = useState([]);
  const [transactionPaginationData, setTransactionPaginationData] =
    useState("");
  const settings = useSettings(false);
  const [isLoading, setIsLoading] = useState(true);
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    customer_id: params.get("customer_id") ? params.get("customer_id") : "",
  });

  function getTransactions() {
    var params = QueryWith(history.location, filters);
    if (params.length > 0) {
      history.push({
        pathname: "/failed-payments/" + type,
        state: "transaction",
        search: params[0],
      });
    }
    console.log(params[0]);

    // return;
    ApiService({
      method: "GET",
      route: "gateway/transactions",
      body: { ...filters, status: type },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setTransactionData(response.data.data.data || []);
          setTransactionPaginationData(response.data.data || {});
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    setIsLoading(true);
    props.onChange(type);
    getTransactions();
  }, [history, filters, type]);

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ ...filters, page: NewPageNo });
  };

  const resolvePayment = () => {
    var hitUrl = "gateway/transaction/resolve/" + selectedTransaction;
    ApiService({ method: "POST", route: hitUrl })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          getTransactions();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div
        style={{
          position: "absolute",
          right: "20px",
          top: "28px",
          // float: "right",
          // marginTop: "-115px",
        }}
      >
        <CustomerFilter
          value={filters.customer_id}
          style={{
            marginRight: "10px",
            marginBottom: "20px",
          }}
          handleCustomerChange={(value) => {
            setFilters({ ...filters, customer_id: value || "", page: "1" });
          }}
        />
        <br />

        {transactionData.length > 0 ? (
          <GPagination
            count={transactionPaginationData.total}
            from={transactionPaginationData.from}
            to={transactionPaginationData.to}
            onChangeNextPage={() =>
              handleChangePage(transactionPaginationData.next_page_url)
            }
            onChangePrevPage={() =>
              handleChangePage(transactionPaginationData.prev_page_url)
            }
            rowsPerPage={transactionPaginationData.per_page}
            prevDisabled={
              transactionPaginationData.prev_page_url ? false : true
            }
            nextDisabled={
              transactionPaginationData.next_page_url ? false : true
            }
          ></GPagination>
        ) : (
          ""
        )}
      </div>

      {/* <Paper className={classes.root}> */}

      <TableContainer className={classes.container}>
        {isLoading === true ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <React.Fragment>
            {transactionData.length === 0 ? (
              <GEmpty></GEmpty>
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns
                      .filter((column) => column.type !== type)
                      .map((column) => (
                        <TableHeader
                          key={column.id}
                          style={{
                            minWidth: column.minWidth,
                            textAlign: column.align,
                          }}
                        >
                          {column.label}
                        </TableHeader>
                      ))}
                    {type === "pending" ? (
                      <TableHeader
                        style={{
                          minWidth: "60px",
                          textAlign: "center",
                        }}
                      >
                        Actions
                      </TableHeader>
                    ) : (
                      ""
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactionData.map((c) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                        {/* <TableRowData>
                            <Link to={{pathname:"/order/" + c.order_id,state:'order'}}>  {c.order_id ? "#" + c.order_id : "-"}</Link>
                          </TableRowData> */}
                        <TableRowData
                          style={{
                            textTransform: "uppercase",
                            // textAlign: "center",
                          }}
                        >
                          {c.image ? (
                            <img
                              src={c.image}
                              alt=""
                              style={{
                                verticalAlign: "middle",
                                display: "inline-block",
                                width: "40px",
                                padding: "0 7px 0 0",
                              }}
                            />
                          ) : (
                            ""
                          )}
                          {c.gateway_identifier}
                        </TableRowData>
                        <TableRowData>
                          {c.transaction_id ? c.transaction_id : "-"}
                        </TableRowData>
                        <TableRowData>
                          {" "}
                          <Link
                            to={{
                              pathname:
                                "/customer/" + c?.customer?.id + "/orders",
                              state: "customer",
                            }}
                          >
                            {c?.customer?.title}
                          </Link>
                        </TableRowData>
                        <TableRowData>
                          {settings.currency_symbol}
                          {c.amount}
                        </TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>
                          <span
                            style={{
                              textTransform: "uppercase",
                              background: "#" + c.background,
                              color: "#" + c.color,
                              padding: "4px 12px",
                            }}
                          >
                            {c.status}
                          </span>
                        </TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {type === "pending" ? c.created : c.updated}
                        </TableRowData>
                        {type === "pending" ? (
                          <TableRowData
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <GButton
                              variant="linkable"
                              onClick={() => {
                                setSelectedTransaction(c.id);
                                setDialogOpen(true);
                              }}
                            >
                              <CheckCircleOutline />
                            </GButton>
                          </TableRowData>
                        ) : (
                          ""
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </React.Fragment>
        )}
      </TableContainer>

      {/* </Paper> */}
      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Resolve Payment"
          text="Are you sure you want to resolve this payment?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            resolvePayment();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}
    </div>
  );
}
