import React from "react";
import styled from "styled-components";

export const DIV = styled.div`
  display: inline-flex;
  width: 85%;
  padding: 5px;
  & h4 {
    font-size: 0.875rem;
    text-align: left;
    font-weight: 400;
    line-height: 1.43;
    padding: 10px 5px;
    margin: 0;
    // width: 65%;
    width: 320px;
  }
  & .inputStyle {
    display: inline-flex;
    align-items: center;
    width: calc(100% - 320px);
    max-width: 350px;
    margin-left: auto;
    span {
      text-align: center;
      background: #f2f2f2;
      border: 1px solid #f2f2f2;
      color: #555;
      padding: 0.5rem 2px;
      font-size: 14px;
      width: 60px;
    }
  }
`;

// function GWallet(props) {
//   return (
//     <DIV>
//       <h4>{props.title}</h4>
//       <div style={{ width: "50%" }}></div>
//     </DIV>
//   );
// }

// export default GWallet;
