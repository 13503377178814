import React, { useState } from "react";
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker,
// } from "react-google-maps";
import GMap from "../gComponents/gMaps";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";

const addressTitle = [
  {
    key: "RESIDENTIAL",
    value: "RESIDENTIAL",
  },
  {
    key: "BUSINESS",
    value: "BUSINESS",
  },
  // {
  //   key: "OTHER",
  //   value: "other",
  // },
];

function CreateAddress(props) {
  const [openSnackbar] = useSnackbar();
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [addressLocation, setAddressLocation] = useState(
    !props.isEmpty
      ? {
        lat: parseFloat(props.data.lat),
        lng: parseFloat(props.data.lng),
      }
      : { lat: 30.7046, lng: 76.7179 }
  );
  const initialValues = {
    type: "saved",
    customer_id: props.id,
    title: !props.isEmpty ? props.data.title : "RESIDENTIAL",
    location_name: !props.isEmpty ? props.data.location_name : "",
    line1: !props.isEmpty ? props.data.line1 : "",
    line2: !props.isEmpty ? props.data.line2 : "",
    rec_phone: !props.isEmpty ? props.data.rec_phone : "",
    rec_name: !props.isEmpty ? props.data.rec_name : "",
    rec_email: !props.isEmpty ? props.data.rec_email : "",
    pickup_notes: props.data ? props.data.pickup_notes : "",
    delivery_notes: props.data ? props.data.delivery_notes : "",
    lat: !props.isEmpty ? props.data.lat : "",
    lng: !props.isEmpty ? props.data.lng : "",
    city: !props.isEmpty ? props.data.city : "",
    state: !props.isEmpty ? props.data.state : "",
    country: !props.isEmpty ? props.data.country : "",
    zipcode: !props.isEmpty ? props.data.zipcode : "",
  };

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    console.log(values);
    // return;
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty ? "addresses/" + props.data.id : "addresses";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.onSubmit();
          props.setAfvisible(false);
        }
        setIsBtnDisabled(false);
        openSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    //title: Yup.string().required("Required"),
    line1: Yup.string().required("Required"),
    location_name: Yup.string().required("Required"),
    // city: Yup.string().required("Required"),
    // state: Yup.string().required("Required"),
    // country: Yup.string().required("Required"),
    // zipcode: Yup.string()
    //   .required("Required")
    //   .min(4, "Zipcode must be atleast 4 digits")
    //   .max(10, "Zipcode must be max 10 digits"),
    rec_phone: Yup.string().required("Required"),
    rec_name: Yup.string().required("Required"),
    // latitude: Yup.string().required("Required"),
  });

  if (props.afvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? "Edit" : "Create"} Address
      </div>
      <div className="modal-body">
        {/* <GoogleMap
            defaultZoom={10}
            defaultCenter={{ lat: 45.4211, lng: -75.6903 }}
          >
            <Marker position={{ lat: -34.397, lng: 150.644 }} />
            {/* We will render our data here *
          </GoogleMap> */}

        {/* <GMap center={{ lat: -24.9923319, lng: 135.2252427 }} /> */}

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <GMap
                centerLocation={addressLocation}
                search={true}
                height="30vh"
                type="createAddress"
                onChange={(address, loc) => {
                  values.lat = loc.lat();
                  values.lng = loc.lng();
                  values.line1 = address.address;
                  values.city = address.city;
                  values.state = address.state;
                  setFieldValue("line1", address.address);
                }}
                padding={true}
                moveMarker={true}
              />

              <FormikControl
                control="radio"
                name="title"
                options={addressTitle}
                key_title="key"
                selected={values.title}
                key_value="value"
                icons={true}
                style={{ display: "none" }}
                divstyle={{
                  display: "inline-block",
                  width: "50%",
                  border: "1px solid #d2d2d2",
                  textAlign: "center",
                  padding: "5px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
                labelStyle={{
                  width: "100%",
                  boxSizing: "border-box",
                  margin: "3px 0",
                }}
              />

              <FormikControl
                control="input"
                type="text"
                placeholder="House / Flat / Block No."
                name="line1"
                className="noBorder"
              />
              <FormikControl
                control="input"
                type="text"
                placeholder="Landmark / Extra details"
                name="line2"
                className="noBorder"
              />

              <h4 style={{ padding: "5px 15px 0" }}>Other Details</h4>
              <FormikControl
                control="input"
                type="text"
                label="Location Name"
                name="location_name"
                style={{ height: "40px" }}
              />
              <div style={{ display: "flex" }}>
                {/* <FormikControl
                    control="input"
                    type="text"
                    placeholder="City"
                    name="city"
                    maxLength={30}
                    style={{ height: "40px" }}
                    onChange={(event) => {
                      const re = /^[A-Za-z\s\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue("city", event.target.value);
                      }
                    }}
                  />
                  <FormikControl
                    control="input"
                    type="text"
                    placeholder="State"
                    name="state"
                    maxLength={15}
                    style={{ height: "40px" }}
                    onChange={(event) => {
                      const re = /^[A-Za-z\s\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue("state", event.target.value);
                      }
                    }}
                  />
                </div>
                <div style={{ display: "inline-flex" }}>
                  <FormikControl
                    control="input"
                    type="text"
                    placeholder="Country"
                    name="country"
                    style={{ height: "40px" }}
                    maxLength={51}
                    onChange={(event) => {
                      const re = /^[A-Za-z\s\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue("country", event.target.value);
                      }
                    }}
                  />  
                  <FormikControl
                    control="input"
                    type="text"
                    placeholder="Zipcode"
                    name="zipcode"
                    style={{ height: "40px" }}
                    maxLength={10}
                    onChange={(event) => {
                      const re = /^[0-9\b]+$/;
                      if (event.target.value && !re.test(event.target.value)) {
                        return;
                      } else {
                        setFieldValue("zipcode", event.target.value);
                      }
                    }}
                  /> */}

                <FormikControl
                  control="input"
                  type="text"
                  label="Recipient Phone"
                  name="rec_phone"
                  style={{ height: "40px" }}
                  divstyle={{
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                  maxLength={13}
                  onChange={(event) => {
                    const re = /^[0-9+\b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("rec_phone", event.target.value);
                    }
                  }}
                />
                <FormikControl
                  control="input"
                  type="text"
                  label="Recipient Name"
                  name="rec_name"
                  style={{ height: "40px" }}
                  divstyle={{
                    width: "50%",
                    boxSizing: "border-box",
                    verticalAlign: "top",
                  }}
                  onChange={(event) => {
                    const re = /^[a-zA-z \b]+$/;
                    if (event.target.value && !re.test(event.target.value)) {
                      return;
                    } else {
                      setFieldValue("rec_name", event.target.value);
                    }
                  }}
                />
              </div>
              <FormikControl
                control="input"
                type="text"
                label="Recipient Email"
                name="rec_email"
                style={{ height: "40px" }}
              />

              <FormikControl
                control="input"
                as="textarea"
                type="text"
                label="Pickup Notes"
                name="pickup_notes"
                rows={2}
                maxLength={140}
              />
              <FormikControl
                control="input"
                as="textarea"
                type="text"
                label="Delivery Notes"
                name="delivery_notes"
                rows={2}
                maxLength={140}
              />
              <br />
              <GButton
                disabled={isBtnDisabled}
                variant="condensed"
                children={!props.isEmpty ? "Update" : "Create"}
                type="submit"
                style={{ width: "calc(100% - 30px)", margin: "0 15px" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default CreateAddress;
