import React, { useState } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";

export default function ZipcodeForm(props) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [openSnackbar] = useSnackbar();
  const initialValues = {
    zipcode: !props.isEmpty ? props.data.zipcode : "",
    market_name: !props.isEmpty ? props.data.market_name : "",
    team_id: !props.isEmpty ? props.data.team_id : "",
    is_pickup: !props.isEmpty ? props.data.is_pickup : 0,
    is_dropoff: !props.isEmpty ? props.data.is_dropoff : 0,
  };

  const onSubmit = (values) => {
    setIsDisabled(true);
    console.log(values);
    // return;
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty ? "zipcodes/" + props.data.id : "zipcodes";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setZcvisible(false);
          props.onSubmit(response.data.data);
        }
        setIsDisabled(false);
        openSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    zipcode: Yup.string().required("Required"),
  });

  if (props.zcvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? "Edit" : "Create"} Zip Code
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <FormikControl
                control="input"
                type="text"
                label="Zipcode"
                name="zipcode"
              />

              <FormikControl
                control="input"
                type="text"
                label="Market Name"
                name="market_name"
              />

              <FormikControl
                control="input"
                type="text"
                label="Team ID"
                name="team_id"
              />

              <FormikControl
                control="toggle"
                label="Pickup"
                isToggled={values.is_pickup == "1" ? 1 : 0}
                name="is_pickup"
                onToggle={() => {
                  let unlimitedVal = values.is_pickup == "1" ? true : false;
                  unlimitedVal = !unlimitedVal;
                  unlimitedVal = unlimitedVal === true ? "1" : "0";
                  setFieldValue("is_pickup", unlimitedVal);
                }}
                style={{
                  display: "inline-flex",
                  width: "50%",
                  boxSizing: "border-box",
                  verticleAlign: "top",
                }}
              />

              <FormikControl
                control="toggle"
                label="Dropoff"
                isToggled={values.is_dropoff == "1" ? 1 : 0}
                name="is_dropoff"
                onToggle={() => {
                  let unlimitedVal = values.is_dropoff == "1" ? true : false;
                  unlimitedVal = !unlimitedVal;
                  unlimitedVal = unlimitedVal === true ? "1" : "0";
                  setFieldValue("is_dropoff", unlimitedVal);
                }}
                style={{
                  display: "inline-flex",
                  width: "50%",
                  boxSizing: "border-box",
                  verticleAlign: "top",
                }}
              />

              <br />
              <GButton
                type="submit"
                disabled={isDisabled}
                variant="condensed"
                children={!props.isEmpty ? "Update" : "Create"}
                style={{ width: "calc(100% - 30px)", margin: "15px 15px 0" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
