import React, { useEffect, useState } from "react";
import { makeStyles, Paper, Backdrop } from "@material-ui/core";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import useDataFctory from "../useDataFactory";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function CancelReasons(props) {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const { data: orderCancelReasons } = useDataFctory("cancel-reasons", false);

  useEffect(() => {
    orderCancelReasons.push({ title: "Other" });
  }, [orderCancelReasons]);

  const initialValues = {
    cancel_reason: "",
    other_reason: "",
  };

  const onSubmit = (values) => {
    console.log(values);
    let sendData = { ...values };
    if (sendData.cancel_reason !== "Other") {
      delete sendData.other_reason;
    } else {
      sendData.cancel_reason = sendData.other_reason;
    }

    ApiService({
      method: "PUT",
      route: "order/cancel/" + props.linkedId,
      body: sendData,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        props.setOcrvisible(false);
        props.onSubmit();
      }
      openSnackbar(response.data.message);
    });
  };

  const validationSchema = Yup.object({
    cancel_reason: Yup.string().required("Required"),
    other_reason: Yup.string().when("cancel_reason", {
      is: "Other",
      then: Yup.string().required("Required"),
    }),
  });

  const handleClose = (e) => {
    if (e.target.id !== (2739).toString()) {
      return;
    }
    props.setOcrvisible(false);
  };

  if (props.ocrvisible === false) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2739"
        className={classes.backdrop}
        open={props.ocrvisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper
          className={`modalRight
              ${props.ocrvisible === true ? "fadeM" : ""}`}
          style={{ padding: "20px", maxWidth: "475px" }}
        >
          <h3>Cancel Order</h3>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values }) => (
              <Form>
                <label style={{ margin: "15px 15px 5px" }}>
                  Please choose the cancellation reason below
                </label>
                {orderCancelReasons.length > 0 ? (
                  <FormikControl
                    control="radio"
                    name="cancel_reason"
                    options={orderCancelReasons}
                    key_title="title"
                    key_value="title"
                    divstyle={{ display: "block", padding: "5px 0" }}
                  />
                ) : (
                  ""
                )}
                {values.cancel_reason === "Other" ? (
                  <FormikControl
                    control="input"
                    as="textarea"
                    placeholder="Type reason here"
                    name="other_reason"
                    // style={{ display: "inline-block", width: "30%" }}
                  />
                ) : (
                  ""
                )}

                <br />

                <GButton
                  type="submit"
                  variant="condensed"
                  children="Cancel Order"
                  style={{ width: "calc(100% - 30px)", margin: "0 15px" }}
                />
              </Form>
            )}
          </Formik>
        </Paper>
        {/* </Popover> */}
      </Backdrop>
    </div>
  );
}
