import React, { useState, useEffect } from "react";
import { makeStyles, Paper, TableContainer, Avatar } from "@material-ui/core";
import { useSnackbar } from "react-simple-snackbar";
import { ApiService } from "../services";
import { RateReview, Delete } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GButton from "../gComponents/gButton";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GPagination from "../gComponents/gPagination";
import GPaginationSelect from "../gComponents/gPaginationSelectbox";
import GSwitch from "../gComponents/gSwitch";
import { Link, useHistory } from "react-router-dom";
import { DriverFilter } from "./driverFilterComponent";
import { CustomerFilter } from "./customerFilterComponent";
import QueryWith from "../Query";
import RatingStars from "../gComponents/gStarRating";
//import { browserHistory } from "react-router";

const columns = [
  { id: "orders_id", label: "Order", minWidth: 60 },
  // { id: "driver", label: "Driver", minWidth: 100 },
  { id: "customer", label: "Customer", minWidth: 120 },
  { id: "review", label: "Review", minWidth: 160 },
  { id: "rating", label: "Rating", minWidth: 100 },
  {
    id: "status",
    label: "Status",
    minWidth: 60,
  },
  {
    id: "created_at_formatted",
    label: "Created",
    minWidth: 120,
    align: "center",
  },
  { id: "actions", label: "Actions", minWidth: 60, align: "right" },
];

const useStyles = makeStyles({
  root: {
    padding: "18px",
    width: "100%",
    boxSizing: "border-box",
    background: "#fff",
    // background: '#222529',
    border: "1px solid #e8e8e8",
    boxShadow: "none",
    borderRadius: "0px",
    marginTop: "10px",
  },
});

export default function ReviewsComponent(props) {
  const classes = useStyles();
  const [openSnackbar] = useSnackbar();
  const [reviewsData, setReviewsData] = useState([]);
  const [reviewsPaginationData, setReviewsPaginationData] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedReviewId, setSelectedReviewId] = useState("");
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);

  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    // agent_id: params.get("agent_id") ? params.get("agent_id") : "",
    customer_id: params.get("customer_id") ? params.get("customer_id") : "",
    published: params.get("published") ? params.get("published") : "",
  });

  const getReviews = () => {
    var params = QueryWith(history.location, filters);

    if (params.length > 0) {
      history.push({
        pathname: "/reviews",
        search: params[0],
      });
    }
    ApiService({ method: "GET", route: "reviews", body: filters })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setReviewsData(response.data.data.data || []);
          setReviewsPaginationData(response.data.data || {});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getReviews();
  }, [filters, history]);

  const deleteReview = () => {
    ApiService({ method: "DELETE", route: "reviews/" + selectedReviewId })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getReviews();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ ...filters, page: NewPageNo });
  };
  const toggleValue = (newVal, id, ind) => {
    ApiService({
      method: "PUT",
      route: "reviews/" + id,
      body: {
        published: newVal,
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          reviewsData[ind] = response.data.data;
          setReviewsData(reviewsData);
        } else {
          getReviews();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="mainInnerView">
      <h1>
        <RateReview /> Reviews
      </h1>

      <div style={{ display: "inline-block", width: "60%" }}>
        {/* <DriverFilter
          value={filters.agent_id}
          style={{
            marginBottom: "10px",
          }}
          handleDriverChange={(value) => {
            setFilters({ ...filters, agent_id: value || "", page: 1 });
          }}
        /> */}

        <CustomerFilter
          value={filters.customer_id}
          style={{
            margin: "0 0 10px 10px",
          }}
          handleCustomerChange={(value) => {
            setFilters({ ...filters, customer_id: value || "", page: 1 });
          }}
        />
      </div>
      <div
        style={{ display: "inline-block", width: "40%", textAlign: "right" }}
      >
        {reviewsData.length !== 0 ? (
          <React.Fragment>
            <GPagination
              style={{ display: "inline-block" }}
              count={reviewsPaginationData.total}
              from={reviewsPaginationData.from}
              to={reviewsPaginationData.to}
              onChangeNextPage={() =>
                handleChangePage(reviewsPaginationData.next_page_url)
              }
              onChangePrevPage={() =>
                handleChangePage(reviewsPaginationData.prev_page_url)
              }
              rowsPerPage={reviewsPaginationData.per_page}
              prevDisabled={reviewsPaginationData.prev_page_url ? false : true}
              nextDisabled={reviewsPaginationData.next_page_url ? false : true}
            ></GPagination>
            {reviewsPaginationData ? (
              <GPaginationSelect
                defaultvalue={filters.page}
                totalPage={reviewsPaginationData.last_page}
                onChangePage={(NewPageNo) =>
                  setFilters({ ...filters, page: NewPageNo })
                }
              />
            ) : (
              ""
            )}
          </React.Fragment>
        ) : (
          ""
        )}
      </div>

      <Paper className={classes.root}>
        {!reviewsPaginationData ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <TableContainer className={classes.container}>
            {reviewsData.length === 0 ? (
              <GEmpty></GEmpty>
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableHeader
                        key={column.id}
                        style={{
                          minWidth: column.minWidth,
                          textAlign: column.align,
                        }}
                      >
                        {column.label}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reviewsData.map((c, i) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                        <TableRowData>
                          <Link
                            to={{
                              pathname: "/order/" + c.order_id + "/orders",
                              state: "order",
                            }}
                          >
                            #{c.order_id}
                          </Link>
                        </TableRowData>
                        {/* <TableRowData>
                          {c.agent?.id ? (
                            <Link
                              to={{
                                pathname: "/driver/" + c.agent?.id + "/orders",
                                state: "store",
                              }}
                            >
                              {c.agent?.title}
                            </Link>
                          ) : (
                            "-"
                          )}
                        </TableRowData> */}
                        <TableRowData>
                          {c.customer?.id ? (
                            <Link
                              to={{
                                pathname:
                                  "/customer/" + c.customer.id + "/orders",
                                state: "customer",
                              }}
                            >
                              {c.customer.title}
                            </Link>
                          ) : (
                            ""
                          )}
                        </TableRowData>
                        <TableRowData>{c.review || "-"}</TableRowData>
                        <TableRowData>
                          <RatingStars
                            totalStars={5}
                            selectedStars={c.rating}
                          />
                        </TableRowData>
                        <TableRowData>
                          <GSwitch
                            id={c.id}
                            uId={c.id}
                            currentValue={
                              c.published === "true" ||
                                c.published === "1" ||
                                c.published === 1
                                ? 1
                                : 0
                            }
                            onSwitchChange={(newVal, id) =>
                              toggleValue(newVal, id, i)
                            }
                          />
                        </TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {c.created}
                        </TableRowData>
                        <TableRowData
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <GButton
                            variant="linkable"
                            onClick={(e) => {
                              setSelectedReviewId(c.id);
                              setDialogOpen(true);
                            }}
                          >
                            <Delete fontSize="small" />
                          </GButton>
                        </TableRowData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </Paper>

      {/* <GToast title="Success" message=""></GToast> */}

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Review"
          text="Are you sure you want to delete this review?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteReview();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}
    </div>
  );
}
