import React from "react";
import styled from "styled-components";
import { Skeleton } from "@material-ui/lab";

const CardShimmer = styled.div`
  .MuiSkeleton-root {
    width: 100%;
    // background: #f6f7f8;

    &.MuiSkeleton-rect {
      margin: 0 8px 25px;
      display: inline-block;
      box-sizing: border-box;
      width: calc(50% - 16px);

      &.single {
        width: calc(100% - 16px);
      }
    }
    &.MuiSkeleton-text {
      margin-top: 10px;
      margin-bottom: 40px;
    }
  }
`;
const RoundShimmer = styled.div`
  display: flex;
  .MuiSkeleton-root {
    margin: 20px 10px;
  }
`;

export const GCardShimmer = (props) => {
  return (
    <CardShimmer>
      <div style={{ boxSizing: "border-box" }}>
        {props.type === "single" ? (
          <React.Fragment>
            <Skeleton
              style={{ background: props.color || "#f6f7f8" }}
              variant="rect single"
              height={props.height || 90}
              animation="wave"
            />
            <Skeleton
              style={{ background: props.color || "#f6f7f8" }}
              variant="rect single"
              height={props.height || 90}
              animation="wave"
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Skeleton
              variant="rect"
              height={props.height || 120}
              animation="wave"
              style={{
                width: "calc(50% - 16px)",
                background: props.color || "#f6f7f8",
              }}
            />
            <Skeleton
              variant="rect"
              height={props.height || 120}
              animation="wave"
              style={{
                width: "calc(50% - 16px)",
                background: props.color || "#f6f7f8",
              }}
            />
            <Skeleton
              variant="rect"
              height={props.height || 120}
              animation="wave"
              style={{
                width: "calc(50% - 16px)",
                background: props.color || "#f6f7f8",
              }}
            />
            <Skeleton
              variant="rect"
              height={props.height || 120}
              animation="wave"
              style={{
                width: "calc(50% - 16px)",
                background: props.color || "#f6f7f8",
              }}
            />
          </React.Fragment>
        )}
      </div>
    </CardShimmer>
  );
};

export const GRoundShimmer = (props) => {
  return (
    <RoundShimmer>
      <Skeleton variant="circular" width={60} height={60} />
      <Skeleton variant="circular" width={60} height={60} />
      <Skeleton variant="circular" width={60} height={60} />
    </RoundShimmer>
  );
};

export const GLineShimmer = (props) => {
  return (
    <>
      <Skeleton variant="text" height={50} style={{ margin: "0 8px 25px" }} />
    </>
  );
};
