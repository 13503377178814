import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import { Close, Remove } from "@material-ui/icons";
import FormExistFields from "./formSettingsExists";
const typesArray = [
  {
    title: "TextField",
    value: "textfield",
  },
  {
    title: "Numeric",
    value: "numeric",
  },
  {
    title: "Email",
    value: "email",
  },
  {
    title: "Phone",
    value: "phone",
  },
  {
    title: "Select Box",
    value: "selectbox",
  },
  {
    title: "Price Options",
    value: "priceoptions",
  },
  {
    title: "Image",
    value: "image",
  },
  {
    title: "Document",
    value: "document",
  },
];

const FormSettings = () => {
  const [openSnackbar] = useSnackbar();
  const [newformData, setNewFormData] = useState([]);

  const getFormSettings = () => {
    ApiService({ method: "GET", route: "form-fields" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          let d = response.data.data.map((n) => {
            if (!n.data.options) {
              n.data.options = [];
            }
            return n;
          });
          setNewFormData([...d]);
          console.log(d);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getFormSettings();
  }, []);

  const initialNewValues = {
    fields: [],
  };

  const onNewSubmit = (values, { resetForm }) => {
    console.log(values);
    values.data =
      values.type === "selectbox" || values.type === "priceoptions"
        ? values.data
        : {};
    //return;
    ApiService({ method: "POST", route: "form-fields", body: values.fields[0] })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          // let d = response.data.map((n) => {
          //   if (!n.data.options) {
          //     n.data.options = [];
          //   }
          //   return n;
          // });
          setNewFormData([...newformData, response.data.data]);
          resetForm(initialNewValues);
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validationSchema = Yup.object({
    fields: Yup.array()
      .of(
        Yup.object({
          title: Yup.string().required("Required"), // these constraints take precedence
          type: Yup.string().required("Required"), // these constraints take precedence,
          //   options: Yup.array().of(
          //     Yup.object({
          //       title:
          //         type === "selectbox" && type === "priceoptions"
          //           ? Yup.string().required("Required")
          //           : "",
          //     })
          //   ),
        })
      )
      .required("required"),
  });

  return (
    <div className="rightInnerView">
      <h2>Form Settings</h2>
      <div className="listPaper">
        <FormExistFields
          data={newformData}
          typesArray={typesArray}
          onFieldRemove={(i) => {
            console.log(i);
            getFormSettings();
          }}
        />
        <Formik
          initialValues={initialNewValues}
          onSubmit={onNewSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, resetForm }) => (
            <Form>
              <FieldArray name="fields">
                {(fieldArrayProps) => {
                  console.log(fieldArrayProps);
                  const { push, remove, form } = fieldArrayProps;
                  const { values } = form;
                  const { fields } = values;

                  console.log(fields);

                  return (
                    <React.Fragment>
                      {fields.map((f, index) => {
                        return (
                          <div
                            style={{
                              boxSizing: "border-box",
                              padding: "10px",
                              background: "#f4f5f9",
                              margin: "10px 0",
                            }}
                          >
                            <div
                              key={index}
                              style={{
                                position: "relative",
                                display: "inline-flex",
                                width: "100%",
                              }}
                              className="arrayFields"
                            >
                              <div
                                style={{
                                  width: "40%",
                                  display: "inline-block",
                                }}
                              >
                                <FormikControl
                                  control="input"
                                  type="text"
                                  name={`fields[${index}].title`}
                                  divstyle={{ padding: "0" }}
                                  //   style={{ width: "40%", margin: "5px 0" }}
                                />
                              </div>

                              <FormikControl
                                control="select"
                                as="select"
                                name={`fields[${index}].type`}
                                placeholder="Select Type"
                                options={typesArray}
                                key_title="title"
                                key_value="value"
                                divstyle={{
                                  display: "inline-block",
                                  width: "25%",
                                  boxSizing: "border-box",
                                  verticalAlign: "top",
                                }}
                              />
                              <FormikControl
                                control="toggle"
                                isToggled={fields[index].required}
                                name={`fieldss[${index}].required`}
                                onToggle={(isToggle) => {
                                  let toggleVal = !isToggle;
                                  setFieldValue(
                                    `fields[${index}].required`,
                                    toggleVal === 1 || toggleVal === true
                                      ? 1
                                      : 0
                                  );
                                }}
                                style={{ width: "15%", textAlign: "center" }}
                              />
                              <div
                                style={{ textAlign: "center", width: "20%" }}
                              >
                                <GButton
                                  type="submit"
                                  variant="linkable"
                                  children={"Save"}
                                  style={{
                                    margin: "10px 5px",
                                    padding: "5px 2px",
                                    textDecoration: "underline",
                                  }}
                                />

                                <GButton
                                  variant="linkable"
                                  children="Remove"
                                  onClick={(e) => {
                                    remove(index);
                                  }}
                                  style={{
                                    margin: "10px 5px",
                                    padding: "5px 2px",
                                    color: "red",
                                    textDecoration: "underline",
                                  }}
                                />
                              </div>
                            </div>
                            {fields[index].type === "selectbox" ||
                            fields[index].type === "priceoptions" ? (
                              <div style={{ padding: "10px" }}>
                                <label style={{ color: "#a2a2a2" }}>
                                  Options
                                </label>

                                <FieldArray
                                  name={`fields[${index}].data.options`}
                                >
                                  {(fieldArrayProps) => {
                                    console.log(fieldArrayProps);
                                    const { push, remove, form } =
                                      fieldArrayProps;
                                    const { values } = form;
                                    const { fields } = values;
                                    //const { options } = fields;
                                    console.log(fields);
                                    console.log(fields[index].data.options);
                                    return (
                                      <React.Fragment>
                                        <div>
                                          {fields[index].type ===
                                            "priceoptions" && (
                                            <div
                                              style={{
                                                display: "flex",
                                                width: "100%",
                                                alignItems: "center",
                                                padding: "0 10px",
                                              }}
                                            >
                                              <label style={{ width: "30%" }}>
                                                Title
                                              </label>

                                              <label
                                                style={{
                                                  width: "30%",
                                                  padding: "0 10px",
                                                }}
                                              >
                                                Amount
                                              </label>
                                            </div>
                                          )}
                                          {fields[index].data.options.map(
                                            (o, ind) => {
                                              return (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <FormikControl
                                                    control="input"
                                                    type="text"
                                                    placeholder="Title"
                                                    name={`fields[${index}].data.options[${ind}.title]`}
                                                    divstyle={{
                                                      margin: "0",
                                                      width: "30%",
                                                      padding: "2px 5px",
                                                    }}
                                                    style={{
                                                      padding: "0.6rem 0.8rem",
                                                      borderRadius: "20px",
                                                    }}
                                                  />

                                                  {fields[index].type ===
                                                    "priceoptions" && (
                                                    <FormikControl
                                                      control="input"
                                                      type="text"
                                                      placeholder="Amount"
                                                      name={`fields[${index}].data.options[${ind}.amount]`}
                                                      divstyle={{
                                                        margin: "0",
                                                        width: "30%",
                                                        padding: "2px 5px",
                                                      }}
                                                      style={{
                                                        padding:
                                                          "0.6rem 0.8rem",
                                                        borderRadius: "50px",
                                                      }}
                                                      onChange={(event) => {
                                                        const re =
                                                          /^[0-9.\b]+$/;
                                                        if (
                                                          event.target.value &&
                                                          !re.test(
                                                            event.target.value
                                                          )
                                                        ) {
                                                          return;
                                                        } else {
                                                          setFieldValue(
                                                            `fields[${index}].data.options[${ind}.amount`,
                                                            event.target.value
                                                          );
                                                        }
                                                      }}
                                                    />
                                                  )}

                                                  <GButton
                                                    type="button"
                                                    variant="linkable"
                                                    onClick={(e) => {
                                                      remove(ind);
                                                    }}
                                                    style={{
                                                      width: "20px",
                                                      height: "20px",
                                                      padding: "2px 0 0",
                                                      margin: "5px 15px",
                                                      borderRadius: "50px",
                                                      border:
                                                        "1px solid #d2d2d2",
                                                    }}
                                                  >
                                                    <Remove
                                                      style={{
                                                        width: "1rem",
                                                        height: "1rem",
                                                        fill: "#d2d2d2",
                                                      }}
                                                    />
                                                  </GButton>
                                                </div>
                                              );
                                            }
                                          )}

                                          <a
                                            href="#"
                                            onClick={(e) => {
                                              push({ title: "", amount: "" });
                                            }}
                                            style={{
                                              display: "inline-block",
                                              color: "#0381FC",
                                              marginTop: "8px",
                                              padding: "5px",
                                              textDecoration: "none",
                                              cursor: "pointer",
                                              fontStyle: "italic",
                                            }}
                                          >
                                            + Add Option
                                          </a>
                                        </div>
                                      </React.Fragment>
                                    );
                                  }}
                                </FieldArray>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })}

                      <div>
                        <br />
                        <a
                          href="#"
                          onClick={(e) => {
                            push({
                              title: "",
                              type: "",
                              required: 0,
                              data: { options: [] },
                            });
                          }}
                          style={{
                            color: "#0381FC",
                            padding: "8px",
                            textDecoration: "none",
                            cursor: "pointer",
                          }}
                        >
                          + Add New Field
                        </a>
                      </div>
                    </React.Fragment>
                  );
                }}
              </FieldArray>
              {/* <GButton
                type="submit"
                variant="condensed"
                children="Update"
                style={{ marginTop: "10px" }}
              /> */}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default FormSettings;
