import React from "react";
import styled from "styled-components";
import { Info } from "@material-ui/icons";
import GText from "../gComponents/gText";
import TooltipComponent from "../gComponents/gTooltip";

const Table = styled.table`
  width: 100%;

  tr.line {
    td {
      padding: 0px;
      border-top: 1px solid #e2e2e2;
    }
  }

  td {
    padding: 6px 5px;
    p {
      margin: 0;
      font-size: 14px;
    }
  }
`;

export const GContent = (props) => {
  return (
    <div style={{ padding: "5px 0" }}>
      <GText
        semi
        text={props.title}
        style={{ display: "inline-block", width: "auto", margin: "5px 0" }}
      />

      {props.tooltip === true ? (
        <TooltipComponent
          title={props.tContent}
          position="bottom"
          id={props.title}
        >
          <Info fontSize="small" />
        </TooltipComponent>
      ) : (
        ""
      )}
      {props.type ? " : " : ""}
      <GText
        g5
        med
        text={props.value}
        style={{
          margin: "0",
          display: props.type ? "inline-block" : "block",
          width: props.type ? "auto" : "100%",
        }}
      />
    </div>
  );
};

export const GTableContent = (props) => {
  return (
    <Table>
      <tbody>
        <tr style={{ fontWeight: props.bold }} className={props.title}>
          <td style={{ textAlign: props.lAlign ? "left" : "left" }}>
            {props.title !== "line" && (
              <GText g5 style={{ color: props.color }} text={props.title} />
            )}
          </td>
          <td
            style={{
              textAlign: props.align ? props.align : "left",
              width: "50%",
            }}
          >
            {props.title !== "line" && (
              <GText
                g5
                style={{ color: props.color }}
                text={
                  props.innerValue
                    ? props.value + " (" + props.innerValue + ")"
                    : props.value
                }
              />
            )}
          </td>
        </tr>
      </tbody>
    </Table>
  );
};
