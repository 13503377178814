import React, { useState, useEffect } from "react";
import { Grid, makeStyles, TableContainer } from "@material-ui/core";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import { Dashboard } from "@material-ui/icons";
import "./dashboard.css";
import { ApiService } from "../services";
import { Card } from "../styledComponents/cardStyles";
import GDashboardCounts from "../gComponents/gDashboardCounts";
import GDahboardInfo from "../gComponents/gDahboardInfo";
import GCharts from "../gComponents/gCharts";
import GEmpty from "../gComponents/gEmpty";
import { Link } from "react-router-dom";
import useSettings from "../useSettings";
const columns = [
  { id: "id", label: "Id" },
  { id: "status", label: "Status", minWidth: 40 },
  { id: "customer", label: "Customer", minWidth: 80 },
  { id: "total", label: "Total", minWidth: 40 },
  { id: "created_at_formatted", label: "Created", minWidth: 110 },
];

const paperStyle = {
  height: "420px",
  overflow: "auto",
  border: "1px solid #e8e8e8",
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  blockHeader: {
    margin: "0px",
    padding: "12px 8px",
    fontWeight: "500",
    color: "#333",
  },
}));
function DashboardData() {
  const classes = useStyles();
  const [dashboardData, setDashboardData] = useState({
    recent_customers: [],
    top_customers: [],
    recent_orders: [],
    graph_orders: [],
    graph_transactions: [],
  });
  const settings = useSettings(true);

  useEffect(() => {
    const getDashboardData = () => {
      ApiService({ method: "GET", route: "dashboard" })
        .then((response) => {
          console.log(response);
          setDashboardData(response.data || {});
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getDashboardData();
  }, []);

  return (
    <div id="dashboard" className="mainInnerView">
      <h1>
        <Dashboard /> Dashboard
      </h1>
      <div className={classes.root}>
        <Grid container spacing={1}>
          {dashboardData.customers ? (
            <GDashboardCounts
              title="Total Users"
              subtitle="Total registered users"
              total_count={dashboardData.customers}
              type="user"
            />
          ) : (
            ""
          )}
          {dashboardData.orders ? (
            <GDashboardCounts
              title="Total Order"
              subtitle="Total active orders"
              total_count={dashboardData.orders}
              type="order"
            />
          ) : (
            ""
          )}
          {dashboardData.drivers ? (
            <GDashboardCounts
              title="Total Drivers"
              subtitle="Total drivers"
              total_count={dashboardData.drivers}
              type="user"
            />
          ) : (
            ""
          )}
          {dashboardData.coupons ? (
            <GDashboardCounts
              title="Total Offers"
              subtitle="Total active offers"
              total_count={dashboardData.coupons}
              type="offer"
            />
          ) : (
            ""
          )}
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <Card style={paperStyle}>
              <h2 className={classes.blockHeader}>Orders</h2>
              <p style={{ padding: "0 8px", color: "#a2a2a2", marginTop: "0" }}>
                Total Orders for this year
              </p>
              <GCharts
                labels={dashboardData.graph_orders.map((l) => {
                  return l.label;
                })}
                values={dashboardData.graph_orders.map((l) => {
                  return l.count;
                })}
                height="320"
                color="#46ca7c"
                type="areaspline"
                name="Order"
              />
            </Card>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Card style={paperStyle}>
              <h2 className={classes.blockHeader}>Revenue</h2>
              {/* <h1
                style={{
                  fontWeight: "500",
                  margin: "-7px 0 15px",
                  padding: "0 8px",
                  fontSize: "1.8rem",
                  color: "#777",
                }}
              >
                {transactionsData.total_amount}
              </h1> */}
              <p style={{ padding: "0 8px", color: "#a2a2a2", marginTop: "0" }}>
                Including all Active & Completed Transactions
              </p>
              <GCharts
                labels={dashboardData.graph_transactions.map((l) => {
                  return l.label;
                })}
                values={dashboardData.graph_transactions.map((l) => {
                  return l.count;
                })}
                height="290"
                color="#e56d87"
                type="line"
                name="Transaction"
              />
            </Card>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Card style={paperStyle}>
              <h2 className={classes.blockHeader}>Recent Orders</h2>
              {dashboardData?.recent_orders &&
              dashboardData?.recent_orders.length > 0 ? (
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableHeader
                            key={column.id}
                            style={{
                              minWidth: column.minWidth,
                              textAlign: column.align,
                            }}
                          >
                            {column.label}
                          </TableHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dashboardData?.recent_orders.map((o) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={o.id}
                            className="bordered"
                          >
                            <TableRowData
                              style={{
                                BorderBottom: "1px solid #f4f5f9",
                              }}
                            >
                              <Link
                                to={{
                                  pathname: "/order/" + o.id,
                                  state: "order",
                                }}
                              >
                                #{o.id}
                              </Link>
                            </TableRowData>
                            <TableRowData>
                              <span
                                className="orderStatus"
                                style={{
                                  color: o.status.color1
                                    ? "#" + o.status.color1
                                    : "#333",
                                  background: o.status.color2
                                    ? "#" + o.status.color2
                                    : "transparent",
                                  padding: "5px 11px 2px",
                                  fontSize: "0.9em",
                                  fontWeight: "550",
                                }}
                              >
                                {o.status.title}
                              </span>
                            </TableRowData>
                            <TableRowData>{o.customer.title}</TableRowData>
                            <TableRowData>
                              {settings.currency_symbol}
                              {o.amount.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                              })}
                            </TableRowData>
                            <TableRowData className="created">
                              {o.created}
                            </TableRowData>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <GEmpty />
              )}
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card style={paperStyle}>
              <h2 className={classes.blockHeader}>New Users</h2>
              {dashboardData?.recent_customers &&
              dashboardData?.recent_customers.length > 0 ? (
                dashboardData?.recent_customers.map((user, i) => (
                  <div
                    key={i}
                    style={{
                      borderBottom: "1px solid rgb(226, 226, 226)",
                    }}
                  >
                    <GDahboardInfo
                      id={user.id}
                      title={user.title}
                      subtitle={user.email}
                      photo={user.thumb_photo}
                      imgType="user"
                      rightTopContent={user.created}
                      rightBottomContent=""
                      className={i % 2 ? "even" : "odd"}
                      rightTopStyle={{ color: "#b8b8b8", fontWeight: "400" }}
                    />
                  </div>
                ))
              ) : (
                <GEmpty />
              )}
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card style={paperStyle}>
              <h2 className={classes.blockHeader}>Top Users</h2>
              {dashboardData?.top_customers &&
              dashboardData?.top_customers.length > 0 ? (
                dashboardData?.top_customers.map((user, i) => (
                  <div
                    key={user.id}
                    style={{
                      borderBottom: "1px solid rgb(226, 226, 226)",
                    }}
                  >
                    <GDahboardInfo
                      id={user.id}
                      title={user.title}
                      subtitle={user.email}
                      photo={user.thumb_photo}
                      imgType="user"
                      rightTopContent={user.count + "  orders"}
                      rightBottomContent={user.created}
                      rightTopStyle={{ color: "#333" }}
                      className={i % 2 ? "even" : "odd"}
                    />
                  </div>
                ))
              ) : (
                <GEmpty />
              )}
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default DashboardData;
