import React, { useState, useEffect } from "react";
import GText from "../gComponents/gText";
import GView from "../gComponents/GView";
import styled from "styled-components";
import Modal from "react-modal";
import LocationAddress from "./orderStopsLocations";
import ConfirmLocationAddress from "./orderLocationForm";
import GButton from "../gComponents/gButton";
import Cross from "../assets/images/cross.png";
import GActions from "../gComponents/gActionsStyle";
import { useSnackbar } from "react-simple-snackbar";

const StopsDiv = styled.div`
  border: 1px solid #e9e9e9;
  background: #fff;
  padding: 12px;
  margin-bottom: 12px;
  // cursor: pointer;

  .type {
    border: 1px solid #777;
    border-radius: 20px;
    text-align: center;
    width: 21px;
    height: 21x;margin:0;

    &.exist {
      background: #0381fe;
      border: 1px solid #0381fe;
      color: #fff !important;
    }
  }
`;

const OrderStops = (props) => {
  const [openSnackbar] = useSnackbar();
  const [isLocAddressVisible, seIsLocAddressVisible] = useState(false);
  const [orderLocVisible, setOrderLocVisible] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [selectedLocationIndex, setSelectedLocationIndex] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [stops, setStops] = useState( props.stops.length > 0
    ? props.stops
    : [
    {
      type: "pickup",
      complete_after: "",
      complete_before: "",
    },
    {
      type: "delivery",
      complete_after: "",
      complete_before: "",
    },
  ]);

  useEffect(() => {
    console.log(stops);
    let stopdata = stops
      .filter((f) => f.lat)
      .map((s) => {
        return s;
      });

    console.log(stopdata);
    if (stopdata.length > 0) {
      console.log([...stopdata]);
      props.setPlaces([...stopdata]);
      //props.onChange(stopdata);
    }
  }, [stops]);
  return (
    <GView>
      {stops.map((s, i) => {
        return (
          <StopsDiv key={i}>
            {s.type === "pickup" ? (
              <GView
                style={{
                  display: "flex",
                  alignItems: "start",
                  cursor: !s.lat ? "pointer" : "",
                }}
              >
                <GText text={"P"} className={s.lat ? "type exist" : "type"} />
                &nbsp;&nbsp;
                {s.lat ? (
                  <GView
                    // onClick={() => {
                    //   setIsEdit(true);
                    //   setSelectedLocationIndex(i);
                    //   seIsLocAddressVisible(true);
                    // }}
                    style={{ width: "calc(100% - 50px)" }}
                  >
                    <GText
                      semi
                      text={`${s.location_name} (${s.title})`}
                      style={{
                        textTransform: "uppercase",
                        paddingBottom: "3px",margin:0
                      }}
                    />
                    <GText text={s.formatted_display} style={{ color: "#333",margin:0 }} />
                    <hr
                      style={{
                        borderTop: "0",
                        borderBottom: "1px solid #e2e2e2",
                      }}
                    />
                    <GText
                      text={`${s.rec_name}  ◆  ${s.rec_phone}${s.rec_email ? `  ◆  ${s.rec_email}` : ''}`}
                      style={{margin:0}}
                    />
                  </GView>
                ) : (
                  <GText
                    text={"Choose pickup address"}
                    onClick={() => {
                      setIsEdit(false);
                      setSelectedLocationIndex(i);
                      seIsLocAddressVisible(true);
                    }}
                    style={{margin:0}}
                  />
                )}
                {s.lat && (
                  <GActions
                    onEdit={() => {
                      setSelectedLocation(s);
                      setOrderLocVisible(true);
                      setSelectedLocationIndex(i);
                      //props.onEdit(addressData)
                    }}
                    onSelect={() => {
                      setIsEdit(true);
                      setSelectedLocationIndex(i);
                      seIsLocAddressVisible(true);
                    }}
                    menuEditTitle={"Update Details"}
                    menuTitle={"Change Location"}
                    mode={"dark"}
                    style={{ position: "relative", marginLeft: "auto" }}
                    menuStyle={{
                      fontSize: "13.5px",
                      padding: "4px 15px",
                    }}
                    paperStyle={{
                      minWidth: "140px",
                      background: "#3a3a3a",
                      color: "#f5f5f5",
                    }}
                  />
                )}
              </GView>
            ) : (
              <GView
                style={{
                  display: "flex",
                  alignItems: "start",
                  cursor: !s.lat ? "pointer" : "",
                }}
              >
                <GText text={"D"} className={s.lat ? "type exist" : "type"} />
                &nbsp;&nbsp;
                {s.lat ? (
                  <GView style={{ width: "calc(100% - 50px)" }}>
                    <GText
                      semi
                      text={`${s.location_name} (${s.title})`}
                      style={{
                        textTransform: "uppercase",
                        paddingBottom: "3px",margin:0
                      }}
                    />
                    <GText text={s.formatted_display} style={{ color: "#333",margin:0 }} />
                    <hr
                      style={{
                        borderTop: "0",
                        borderBottom: "1px solid #e2e2e2",
                      }}
                    />
                    <GText
                      text={`${s.rec_name}  ◆  ${s.rec_phone}${s.rec_email ? `  ◆  ${s.rec_email}` : ''}`}
                      style={{margin:0}}
                    />
                  </GView>
                ) : (
                  <GText
                    text={"Choose delivery address"}
                    onClick={() => {
                      setIsEdit(false);
                      setSelectedLocationIndex(i);
                      seIsLocAddressVisible(true);
                    }}
                    style={{margin:0}}
                  />
                )}
                {s.lat || i > 1 ? (
                  <>
                    <GActions
                      onEdit={() => {
                        setSelectedLocation(s);
                        setOrderLocVisible(true);
                        setSelectedLocationIndex(i);
                        //props.onEdit(addressData)
                      }}
                      onRemove={(e) => {
                        e.stopPropagation();
                        stops.splice(i, 1);
                        setStops([...stops]);
                      }}
                      onSelect={() => {
                        setIsEdit(true);
                        setSelectedLocationIndex(i);
                        seIsLocAddressVisible(true);
                      }}
                      menuEditTitle={"Update Details"}
                      menuTitle={"Change Location"}
                      mode={"dark"}
                      style={{ position: "relative", marginLeft: "auto" }}
                      menuStyle={{
                        fontSize: "13.5px",
                        padding: "4px 15px",
                      }}
                      paperStyle={{
                        minWidth: "140px",
                        background: "#3a3a3a",
                        color: "#f5f5f5",
                      }}
                    />
                    {/* <GButton
                    variant="linkable"
                    onClick={(e) => {
                      e.stopPropagation();
                      stops.splice(i, 1);
                      setStops([...stops]);
                    }}
                    style={{ marginLeft: "auto", padding: "3px 5px" }}
                  >
                    <img src={Cross} style={{ width: "13px" }} />
                  </GButton> */}
                  </>
                ) : (
                  ""
                )}
              </GView>
            )}
          </StopsDiv>
        );
      })}

      {stops.length == 1 && (
        <StopsDiv>
          <GView
            style={{
              display: "flex",
              alignItems: "start",
              cursor: "pointer",
            }}
          >
            <GText text={"D"} className={"type"} />
            &nbsp;&nbsp;
            <GText
              text={"Choose delivery address"}
              onClick={() => {
                stops.push({
                  type: "delivery",
                  complete_after: "",
                  complete_before: "",
                });
                setStops([...stops]);
                setIsEdit(false);
                setSelectedLocationIndex(stops.length - 1);
                seIsLocAddressVisible(true);
              }}
            />
          </GView>
        </StopsDiv>
      )}

        {/* {stops.length >= 2 && stops[0].lat && stops[1]?.lat ? (
            <GButton
              variant="linkable"
              style={{ fontWeight: "500" }}
              onClick={() => {
                if (!stops[stops.length - 1]?.lat) {
                  return;
                }
                stops.push({
                  type: "delivery",
                  complete_after: "",
                  complete_before: "",
                });
                setStops([...stops]);
                setIsEdit(false);
                setSelectedLocationIndex(stops.length - 1);
                seIsLocAddressVisible(true);
              }}
            >
              + Add Stops
            </GButton>
          ) : (
            ""
          )} */}
       

      <Modal
        isOpen={isLocAddressVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => {
          seIsLocAddressVisible(false);
        }}
        style={{
          content: {
            width: "450px",
            padding: "0 0 20px 0px",
            overflow: "hidden",
          },
        }}
        contentLabel="Order Locations Modal"
      >
        <LocationAddress
          isEdit={isEdit}
          addressData={props.address}
          isLocAddressVisible={isLocAddressVisible}
          seIsLocAddressVisible={seIsLocAddressVisible}
          onSelectAddress={(data) => {
            setSelectedLocation(data);
            setOrderLocVisible(true);
            seIsLocAddressVisible(false);

            // console.log(selectedLocationIndex);
            // console.log(stops[selectedLocationIndex]);
            // data.type = stops[selectedLocationIndex].type;
            // console.log(data);
            // stops[selectedLocationIndex] = {
            //   ...stops[selectedLocationIndex],
            //   ...data,
            // };
            // console.log(stops);
            // setStops([...stops]);
          }}
        />
      </Modal>
      <Modal
        isOpen={orderLocVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => {
          setOrderLocVisible(false);
        }}
        style={{
          content: {
            width: "450px",
            padding: "0 0 20px 0px",
            overflow: "hidden",
          },
        }}
        contentLabel="Order Location confirm Modal"
      >
        <ConfirmLocationAddress
          addressData={props.address}
          selectedLocation={selectedLocation}
          orderLocVisible={orderLocVisible}
          setOrderLocVisible={setOrderLocVisible}
          currentIndType={stops[selectedLocationIndex]?.type}
          onSubmit={(data) => {
            console.log(stops);
            console.log(selectedLocationIndex);
            console.log(stops[selectedLocationIndex]);
            data.type = stops[selectedLocationIndex].type;
            console.log(data);
            stops[selectedLocationIndex] = {
              ...stops[selectedLocationIndex],
              ...data,
            };
            console.log(stops);
            setStops([...stops]);
            setOrderLocVisible(false);
            seIsLocAddressVisible(false);
          }}
        />
      </Modal>
    </GView>
  );
};

export default OrderStops;
