import React, { useState } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";

export default function CancelReasons(props) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [openSnackbar] = useSnackbar();

  const initialValues = {
    user_type: props.type ? props.type : "",
    title: !props.isEmpty ? props.data.title : "",
  };

  const onSubmit = (values) => {
    setIsDisabled(true);
    console.log(values);

    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty
      ? "cancel-reasons/" + props.data.id
      : "cancel-reasons";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setCrvisible(false);
          props.onSubmit();
        }
        setIsDisabled(false);
        openSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
  });

  if (props.crvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? "Edit" : "Create"} Cancel Reason
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <FormikControl
              control="input"
              as="textarea"
              type="text"
              label="Title"
              name="title"
              rows="4"
            />
            <br />
            <GButton
              children={!props.isEmpty ? "Update" : "Create"}
              variant="condensed"
              type="submit"
              disabled={isDisabled}
              style={{ width: "calc(100% - 30px", margin: "0 15px" }}
            />
          </Form>
        </Formik>
      </div>
    </>
  );
}
