import React, { useState, useEffect } from "react";
import { makeStyles, Paper, TableContainer } from "@material-ui/core";
import { ApiService } from "../services";
import { Add } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GEmpty from "../gComponents/gEmpty";
import GTableShimmer from "../gComponents/gTableShimmer";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GPagination from "../gComponents/gPagination";
import earnPoints from "../assets/images/point-earn.png";
import withdrawPoints from "../assets/images/point-not-earn.png";
import GButton from "../gComponents/gButton";
import WalletForm from "../forms/walletPointForm";
import Modal from "react-modal";

const columns = [
  { id: "earn", label: "" },
  { id: "points", label: "Points", minWidth: 60, align: "" },
  { id: "text", label: "Text", minWidth: 130, align: "center" },
  { id: "date", label: "Date", minWidth: 80, align: "center" },
];

const useStyles = makeStyles({
  root: {
    padding: "18px",
    width: "100%",
    boxSizing: "border-box",
    background: "#fff",
    // background: '#222529',
    border: "1px solid #e8e8e8",
    boxShadow: "none",
    borderRadius: "0px",
  },
});

export default function WalletPoints(props) {
  const classes = useStyles();
  const [walletData, setWalletData] = useState([]);
  const [walletPaginationData, setWalletPaginationData] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState("");
  const [selectedWalletId, setSelectedWalletId] = useState("");
  const [walletvisible, setWalletvisible] = useState(false);

  function getWalletPoints() {
    let obj = {};
    obj[props.title] = props.value;
    obj["page"] = currentPage;
    ApiService({ method: "GET", route: props.type + "/wallet", body: obj })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setWalletData(response.data.data.data || []);
          setWalletPaginationData(response.data.data || {});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    console.log(props);
    if (props.type) {
      props.onChange("wallet");
    }

    setSelectedWalletId(props.value);
    getWalletPoints();
  }, [currentPage]);

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setCurrentPage(NewPageNo);
  };

  return (
    <div className={props.type ? "" : "mainInnerView"}>
      {props.type ? "" : <h1>Wallet Points</h1>}
      {props.type === "customer" && (
        <GButton
          variant="linkable"
          children="Add"
          onClick={() => {
            setWalletvisible(true);
          }}
          style={{
            margin: "-40px 0px 10px",
            float: "right",
          }}
        >
          <Add fontSize="large" />
        </GButton>
      )}

      <Paper className={classes.root}>
        {walletData.length !== 0 ? (
          <GPagination
            count={walletPaginationData.total}
            from={walletPaginationData.from}
            to={walletPaginationData.to}
            onChangeNextPage={() =>
              handleChangePage(walletPaginationData.next_page_url)
            }
            onChangePrevPage={() =>
              handleChangePage(walletPaginationData.prev_page_url)
            }
            rowsPerPage={walletPaginationData.per_page}
            prevDisabled={walletPaginationData.prev_page_url ? false : true}
            nextDisabled={walletPaginationData.next_page_url ? false : true}
          ></GPagination>
        ) : (
          ""
        )}
        {!walletPaginationData ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <TableContainer className={classes.container}>
            {walletData.length === 0 ? (
              <GEmpty></GEmpty>
            ) : (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableHeader
                        key={column.id}
                        style={{
                          minWidth:
                            walletData.length > 0 && !column.label
                              ? "30px"
                              : column.minWidth,
                          textAlign: column.align,
                        }}
                      >
                        {column.label}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {walletData.map((c) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={c.id}>
                        <TableRowData>
                          {c.amount < 0 ? (
                            <img
                              src={withdrawPoints}
                              alt=""
                              style={{ width: "15px" }}
                            />
                          ) : (
                            <img
                              src={earnPoints}
                              alt=""
                              style={{ width: "15px" }}
                            />
                          )}
                        </TableRowData>
                        <TableRowData style={{ textAlign: "" }}>
                          {c.amount}
                        </TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>
                          {c.title}
                        </TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>
                          {c.created}
                        </TableRowData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </Paper>

      {/* <GToast title="Success" message=""></GToast> */}

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Customer"
          text="Are you sure you want to delete this customer?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}

      <Modal
        isOpen={walletvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setWalletvisible(false)}
        style={{
          content: {
            width: "400px",
          },
        }}
        contentLabel="Wallet Point Form Modal"
      >
        <WalletForm
          walletvisible={walletvisible}
          setWalletvisible={setWalletvisible}
          onSubmit={() => {
            getWalletPoints();
          }}
          type={props.type}
          id={selectedWalletId}
        />
      </Modal>
    </div>
  );
}

//export default Customers;
