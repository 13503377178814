import React, { useState, useEffect } from "react";
import { Avatar } from "@material-ui/core";
import { ApiService, ImgUrl } from "../services";
import GEmpty from "../gComponents/gEmpty";
import GButton from "../gComponents/gButton";
import { Edit, Delete } from "@material-ui/icons";
import { useSnackbar } from "react-simple-snackbar";
import styled from "styled-components";
import useSettings from "../useSettings";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import GPricingRuleCard from "../gComponents/gPricingRuleOptions";
import GShimmer from "../gComponents/gTableShimmer";
import VehicleTypeForm from "../forms/vehicleTypeForm";
import Modal from "react-modal";

const VehicleCard = styled.div`
  padding: 0.65em 0.7em;
  background: #f7f7f7;
  border: 1px solid #e2e2e2;
  margin: 5px;
  font-weight: 500;
  border-radius: 0px;
  box-sizing: border-box;
  width: calc(33.3% - 10px);
  // min-width: 250px;
  box-shadow: none;
  display: inline-block;
  outline: 0;
  vertical-align: top;
  min-height: 120px;

  @media (max-width: 1200px) and (min-width: 992px) {
    & {
      width: calc(50% - 10px);
    }
  }
  h4 {
    margin: 0 0 5px;
    line-height: 22px;
    font-weight: 500;
  }
  p {
    font-weight: 450;
  }
  & .actionDiv {
    visibility: hidden;
  }
  &:hover .actionDiv {
    visibility: visible;
  }
`;

export default function VehicleTypes() {
  const [openSnackbar] = useSnackbar();
  const [vehicleTypeData, setVehicleTypeData] = useState([]);
  const [vehvisible, setVehvisible] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedVehicleType, setSelectedVehicleType] = useState({});
  const [selectedVehicleTypeId, setSelectedVehicleTypeId] = useState("");
  const [isEmpty, setIsEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const settings = useSettings(true);
  useEffect(() => {
    getVehicleTypes();
  }, []);

  const getVehicleTypes = () => {
    ApiService({ method: "GET", route: "vehicle/types" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setVehicleTypeData(response.data.data);
          setIsLoading(false);
          getPricingRules(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPricingRules = (vData) => {
    ApiService({ method: "GET", route: "task/rules" })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          console.log(vData);
          vData.map((v) => {
            response.data.data.map((p) => {
              if (parseInt(v.pricing_rule_id) == p.id) {
                v.rule = p;
              }
            });
          });
          console.log(vData);
          setVehicleTypeData([...vData]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteVehicleType = () => {
    ApiService({
      method: "DELETE",
      route: "vehicle/types/" + selectedVehicleTypeId,
    })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          getVehicleTypes();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.

  return (
    <div className="rightInnerView">
      <GButton
        variant="condensed"
        children="Add"
        onClick={() => {
          setSelectedVehicleType({});
          setVehvisible(true);
          setIsEmpty(true);
        }}
        style={{
          margin: "10px 5px",
          float: "right",
        }}
      ></GButton>
      <h2>Vehicle Types</h2>
      <div className="listPaper">
        {isLoading === true ? (
          <GShimmer></GShimmer>
        ) : vehicleTypeData.length === 0 ? (
          <GEmpty></GEmpty>
        ) : (
          vehicleTypeData.map((v, i) => {
            return (
              <VehicleCard key={i}>
                <Avatar
                  alt={v.title}
                  src={ImgUrl("vehicle_type") + "/" + v.thumb_photo}
                  style={{ float: "right", background: "#000", color: "#fff" }}
                />
                <h4>{v.title}</h4>
                <p>{v?.rule?.title}</p>
                {/* <GPricingRuleCard ruleData={v.rule} /> */}
                <div className="actionDiv" style={{ float: "right" }}>
                  <GButton variant="linkable">
                    <Edit
                      fontSize="small"
                      onClick={() => {
                        setSelectedVehicleType(v);
                        setIsEmpty(false);
                        setVehvisible(true);
                      }}
                    />
                  </GButton>
                  <GButton variant="linkable">
                    <Delete
                      fontSize="small"
                      onClick={() => {
                        setSelectedVehicleTypeId(v.id);
                        setDialogOpen(true);
                      }}
                    />
                  </GButton>
                </div>
              </VehicleCard>
            );
          })
        )}
      </div>

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Vehicle Type"
          text="Are you sure you want to delete this vehicle type?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            deleteVehicleType();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}

      <Modal
        isOpen={vehvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setVehvisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Vehicle Type Form Modal"
      >
        <VehicleTypeForm
          vehvisible={vehvisible}
          setVehvisible={setVehvisible}
          data={selectedVehicleType}
          isEmpty={isEmpty}
          onSubmit={getVehicleTypes}
          settings={settings}
        />
      </Modal>
    </div>
  );
}
