import React, { useState, useEffect, useRef } from "react";
import { ApiService } from "../services";
import { MoreVert } from "@material-ui/icons";
import { useDetectOutsideClick } from "../useDetectOutsideClick";
import GDropdown from "../gComponents/gDropdown";
import GButton from "../gComponents/gButton";
import { useSnackbar } from "react-simple-snackbar";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import styled from "styled-components";

const Div = styled.div`
  button:hover {
    background: #fff;
  }
`;

const assignList = [
  {
    title: "Update Payment Status",
    value: "payment_status",
  },
];

const OrderActions = (props) => {
  const AssignDropdownRef = useRef(null);
  const [openSnackbar] = useSnackbar();
  const [isDropdownActive, setIsDropdownActive] = useDetectOutsideClick(
    AssignDropdownRef,
    false
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState(props.data || []);
  const [selectedAssignType, setSelectedAssignType] = useState();
  useEffect(() => {
    setSelectedOrders(props.data);
  }, [props]);

  function updatePaymentStatus() {
    ApiService({
      method: "POST",
      route: "order/payment-bulk",
      body: { orders: selectedOrders },
    })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          props.onUpdatePaymentStatus();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <Div style={{ position: "relative" }}>
      <GButton
        variant="linkable"
        style={{
          //   background: "#fff",
          width: "35px",
          height: "35px",
          borderRadius: "20px",
        }}
        onClick={() => setIsDropdownActive(true)}
      >
        <MoreVert />
      </GButton>

      {isDropdownActive === true ? (
        <div ref={AssignDropdownRef} style={{ display: "inline-block" }}>
          <GDropdown
            id="assign"
            className={`menu ${isDropdownActive ? "active" : "inactive"}`}
            listData={assignList}
            onSelectValue={(value) => {
              setIsDropdownActive(false);

              setDialogOpen(true);
            }}
            style={{ top: "25px", right: "0", zIndex: "99", minWidth: "200px" }}
          />
        </div>
      ) : (
        ""
      )}

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Update Payment Status"
          text="Are you sure you want to update selected orders payment status?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            updatePaymentStatus();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}
    </Div>
  );
};

export default OrderActions;
