import React, { useEffect, useState, useReducer, useRef } from "react";
import GTabs from "../gComponents/gTabs";
import { ApiService } from "../services";
import GButton from "../gComponents/gButton";
import GInput from "../gComponents/gInput";
import GConfirmDialog from "../gComponents/gConfirmDialog";
import { useSnackbar } from "react-simple-snackbar";

const emailTypes = ["system", "mailgun"];

export default function EmailSettings({ match }) {
  const testEmail = useRef(null);
  const [typeValue, setTypeValue] = useState();
  const [emailSettingsData, setEmailSettingsData] = useState({});
  const [openSnackbar] = useSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [testResponse, setTestResponse] = useState("");
  const [systemData, setSystemData] = useState({ default: "system" });
  const [mailgunData, setMailgunData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      default: "mailgun",
      mailgun: {
        service_url: "",
        api_secret: "",
        from: "",
      },
    }
  );

  const fieldStyle = {
    display: "block",
    width: "300px",
    padding: "10px 15px 10px 0",
  };
  const lableStyle = {
    fontSize: "0.9rem",
    lineHeight: "1.5rem",
    padding: "3px",
    textTransform: "capitalize",
  };

  function getEmailSettings() {
    ApiService({ method: "GET", route: "email-settings" })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          setEmailSettingsData(response.data.data);
          setTypeValue(response.data.data.default);

          // if (response.data.default === "mailgun")
          setMailgunData({
            mailgun: {
              service_url: response.data.data.mailgun.service_url,
              api_secret: response.data.data.mailgun.api_secret,
              from: response.data.data.mailgun.from,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    console.log(typeValue);
    getEmailSettings();
  }, []);

  const handleChangeTab = (newVal) => {
    console.log(newVal);
    setTypeValue(newVal);
    if (testEmail.current) {
      testEmail.current.value = "";
    }
  };

  function updateMailgunSettings() {
    var hitUrl = "email-settings";

    console.log(mailgunData);

    ApiService({
      method: "PUT",
      route: hitUrl,
      body: typeValue === "system" ? systemData : mailgunData,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          getEmailSettings();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function testEmailSettings() {
    var testData = {
      email: testEmail.current.value,
      driver: typeValue,
    };
    console.log(testData);
    // return;
    ApiService({ method: "POST", route: "email-settings", body: testData })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          testEmail.current.value = "";
          setTestResponse(
            response.data.data
              ? JSON.stringify(response.data.data)
              : response.data.data
          );
          setTimeout(function () {
            setDialogOpen(true);
          }, 800);
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <div style={{ float: "right", marginTop: "25px" }}>
        <h4
          style={{
            margin: "0",
            fontSize: "17px",
            color: "#777",
            fontStyle: "italic",
            fontWeight: "400",
            textTransform: "capitalize",
          }}
        >
          Default Gateway -{" "}
          <span style={{ color: "#0381FC" }}>{emailSettingsData.default}</span>
        </h4>
        <br />
      </div>
      <h2>Email Notification Settings</h2>

      <GTabs
        routes={emailTypes}
        active={typeValue}
        type="single"
        onClick={handleChangeTab}
      ></GTabs>

      <div className="listPaper" style={{ padding: "30px" }}>
        {typeValue === "system" ? (
          <div>
            <p>In this, email settings are set by system configuration</p>
            <GButton
              variant="condensed"
              children="Set as Default"
              onClick={updateMailgunSettings}
              disabled={emailSettingsData.default === "system" ? true : false}
            ></GButton>
          </div>
        ) : typeValue === "mailgun" ? (
          <div>
            <div style={fieldStyle}>
              <label style={lableStyle}>Service Url</label>
              <GInput
                type="text"
                placeholder=""
                name="service_url"
                id="service_url"
                value={mailgunData.mailgun.service_url}
                display="block"
                onChange={(e) => {
                  mailgunData.mailgun.service_url = e.target.value;
                }}
                onBlur={(e) => {
                  mailgunData.mailgun.service_url = e;
                }}
              />
            </div>
            <div style={fieldStyle}>
              <label style={lableStyle}>Secret Api</label>
              <GInput
                type="text"
                placeholder=""
                name="api_secret"
                id="api_secret"
                value={mailgunData.mailgun.api_secret}
                display="block"
                onChange={(e) => {
                  mailgunData.mailgun.api_secret = e.target.value;
                }}
                onBlur={(e) => {
                  mailgunData.mailgun.api_secret = e;
                }}
              />
            </div>
            <div style={fieldStyle}>
              <label style={lableStyle}>email</label>
              <GInput
                type="text"
                placeholder=""
                name="from"
                id="from"
                value={mailgunData.mailgun.from}
                display="block"
                onChange={(e) => {
                  mailgunData.mailgun.from = e.target.value;
                }}
                onBlur={(e) => {
                  mailgunData.mailgun.from = e;
                }}
              />
            </div>
            <br />
            <GButton
              variant="condensed"
              children="Update"
              onClick={updateMailgunSettings}
            ></GButton>
          </div>
        ) : (
          ""
        )}

        {typeValue === "mailgun" && emailSettingsData.default === "mailgun" ? (
          <React.Fragment>
            <hr style={{ margin: "20px 0" }} />

            <label style={lableStyle}>Check {typeValue} Integration</label>
            <br />
            <div
              style={{
                display: "inline-flex",
                verticalAlign: "middle",
              }}
            >
              <input
                type="text"
                ref={testEmail}
                placeholder="test@example.com"
                name="testNo"
                id="testNo"
                display="block"
                style={{
                  padding: "10px",
                  margin: "4px",
                  outline: "0",
                  border: "1px solid #ccc",
                }}
              />
              <GButton
                variant="condensed"
                children="Send Test Email"
                onClick={testEmailSettings}
                style={{
                  height: "38px",
                  marginTop: "4px",
                }}
              ></GButton>
            </div>
          </React.Fragment>
        ) : (
          ""
        )}
      </div>

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title={typeValue + " Gateway Response"}
          text={testResponse}
          handleClose={() => setDialogOpen(false)}
          handleCloseText="Okay"
        ></GConfirmDialog>
      ) : (
        ""
      )}
    </div>
  );
}
