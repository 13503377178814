import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";

export default function CreateFaq(props) {
  const [openSnackbar] = useSnackbar();
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [faqTypesList, setFaqTypesList] = useState([]);
  const initialValues = {
    question: !props.isEmpty ? props.data.question : "",
    answer: !props.isEmpty ? props.data.answer : "",
    user_type: props.userType | "",
    faq_type: !props.isEmpty ? props.data.faq_type : "",
  };

  useEffect(() => {
    console.log(props.faqTypes);
    setFaqTypesList(props.faqTypes);
  }, [props]);

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    console.log(values);
    //return;
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty ? "faqs/" + props.data.id : "faqs";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.onSubmit();
          props.setCfvisible(false);
        }
        setIsBtnDisabled(false);
        openSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    question: Yup.string().required("Required"),
    answer: Yup.string().required("Required"),
    faq_type: faqTypesList.length > 0 ? Yup.string().required("Required") : "",
  });

  if (props.cfvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">{!props.isEmpty ? "Edit" : "Add"} FAQ</div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <FormikControl
              control="input"
              type="text"
              label="Question"
              name="question"
            />
            <FormikControl
              control="input"
              as="textarea"
              label="Answer"
              name="answer"
              rows="4"
            />
            {faqTypesList.length > 0 ? (
              <FormikControl
                control="select"
                as="select"
                label="Faq Type"
                name="faq_type"
                options={faqTypesList}
                key_title="title"
                key_value="identifier"
              />
            ) : (
              ""
            )}
            <br />
            <GButton
              type="submit"
              disabled={isBtnDisabled}
              variant="condensed"
              children={!props.isEmpty ? "Update" : "Create"}
              style={{ width: "calc(100% - 30px)", margin: "0 15px" }}
            />
          </Form>
        </Formik>
      </div>
    </>
  );
}
