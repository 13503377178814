import React from "react";
import { Switch, Route } from "react-router-dom";
import SettingRoutes from "./settingRoutes";
import BasicSettings from "./basicSettings";
import SocialLinks from "./socialLinks";
import InvoiceSettings from "./invoiceSettings";
import AppSettings from "./appSettings";
// import ApiKeySettings from "./apiKeys";
import DocumentTypes from "./documentTypes";
import OrderCancelReasons from "./orderCancelReasonsTypes";
import FaqTypes from "./faqTypes";
import VehicleTypes from "./vehicleTypes";
import TimeslotSettings from "./timeslotSettings";
import Timeslots from "./timeslots";
import SurgeTimeslots from "./surgeTimeslots";
import NotificationTypes from "./notificationsType";
import PricingRules from "./pricingRules";
import TaxSettings from "./tax";
import WalletSettings from "./walletSettings";
import WebsiteSettings from "./websiteSettings";
import WebPlugins from "./websitePlugins";
import PrivacyPolicy from "./privacyPolicy";
import TermsConditions from "./termsConditions";
import EmailSettings from "./emailSettings";
import SmsSettings from "./smsSettings";
import ZipCodes from "./zipcodes";

import FormSettings from "./formSettings";
import Events from "./events";

const leftInnerStyle = {
  width: "23%",
  height: "87vh",
  overflow: "overlay",
  background: "#fff",
};
const rightInnerStyle = {
  width: "77%",
  height: "87vh",
  overflow: "overlay",
};
export default function Settings({ match }) {
  return (
    <div style={{ display: "flex" }}>
      <div style={leftInnerStyle}>
        <SettingRoutes url={match.url} />
      </div>
      {/* match.path should be equal to '/home' */}
      <div style={rightInnerStyle}>
        <Switch>
          <Route exact path="/settings/basic" component={BasicSettings} />
          <Route exact path="/settings/social" component={SocialLinks} />
          <Route exact path="/settings/invoice" component={InvoiceSettings} />
          {/* <Route exact path="/settings/app" component={AppSettings} /> */}
          <Route
            exact
            path="/settings/form-settings"
            component={FormSettings}
          />
          <Route
            exact
            path="/settings/document-types/:type"
            component={DocumentTypes}
          />
          <Route
            exact
            path="/settings/order-cancel-reasons/:type"
            component={OrderCancelReasons}
          />
          <Route exact path="/settings/faqs/:type" component={FaqTypes} />

          <Route
            exact
            path="/settings/vehicle-types"
            component={VehicleTypes}
          />
          <Route exact path="/settings/zipcodes" component={ZipCodes} />
          <Route exact path="/settings/events" component={Events} />
          <Route exact path="/settings/pricing-rule" component={PricingRules} />

          <Route
            exact
            path="/settings/timeslot-settings"
            component={TimeslotSettings}
          />
          <Route
            exact
            path="/settings/timeslots/:weekday"
            component={Timeslots}
          />
          <Route exact path="/settings/timeslots" component={Timeslots} />
          <Route exact path="/settings/timeslots/" component={Timeslots} />

          <Route
            exact
            path="/settings/surge-timeslots"
            component={SurgeTimeslots}
          />
          <Route
            exact
            path="/settings/surge-timeslots/"
            component={SurgeTimeslots}
          />
          <Route
            exact
            path="/settings/surge-timeslots/:weekday"
            component={SurgeTimeslots}
          />
          <Route
            exact
            path="/settings/:type-notifications/:userType"
            component={NotificationTypes}
          />
          <Route
            exact
            path="/settings/:type-notifications/:userType/:notifyType"
            component={NotificationTypes}
          />

          <Route exact path="/settings/tax" component={TaxSettings} />
          <Route exact path="/settings/wallet" component={WalletSettings} />
          <Route
            exact
            path="/settings/website-settings"
            component={WebsiteSettings}
          />
          <Route exact path="/settings/web-plugin" component={WebPlugins} />
          <Route
            exact
            path="/settings/privacy-policy"
            component={PrivacyPolicy}
          />
          <Route
            exact
            path="/settings/terms-conditions"
            component={TermsConditions}
          />
          <Route
            exact
            path="/settings/email-notifications"
            component={EmailSettings}
          />
          <Route
            exact
            path="/settings/sms-notifications"
            component={SmsSettings}
          />
        </Switch>
      </div>
    </div>
  );
}
