import React, { useState, useEffect, useRef } from "react";
import { ApiService } from "../services";
import styled from "styled-components";
import { Search, Close, ArrowDropDown } from "@material-ui/icons";
import { useDetectOutsideClick } from "../useDetectOutsideClick";
import GText from "../gComponents/gText";

const LI = styled.li`
  padding: 6px 8px;
  color: #333;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background: #0381FC;
    color: #fff;
  }
`;

const Input = styled.input`
  width: calc(100% - 34px);
  /* min-width: 350px; */
  max-width: 450px;
  margin: 5px 0 0;
  height: 30px;
  background: transparent;
  border: 0px;
  outline: 0;
  box-shadow: none;
  color: #777;
  padding-left: 10px;
`;
export const DriverFilter = ({
  value,
  handleDriverChange,
  hideSelectedVal,
  handleBlur,
  handleFocus,
  placeholder,
  style,
}) => {
  const [searchWord, setSearchWord] = useState("");
  const wrapperSRef = useRef(null);
  const [showFilterList, setShowFilterList] = useDetectOutsideClick(
    wrapperSRef,
    false
  );
  const [selectedTitle, setSelectedTitle] = useState("");
  const [driversFilterList, setDrivers] = useState([]);

  useEffect(() => {
    ApiService({
      method: "GET",
      route: "drivers",
      body: { per_page: 30, search: searchWord },
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setDrivers(response.data.data.data || []);
          response.data.data.data.map((values) => {
            if (values.id === value) {
              setSelectedTitle(values.title);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [searchWord]);

  return (
    <div
      ref={wrapperSRef}
      style={Object.assign(
        {},
        {
          width: "212px",
          display: "inline-block",
          position: "relative",
          // verticalAlign: "middle",
        },
        style
      )}
    >
      <div
        style={{
          background: "#fff",
          border: "1px solid rgb(226, 226, 226)",
          height: hideSelectedVal ? "36px" : "40px",
          fontSize: hideSelectedVal ? "0.9rem" : "0.97rem",
          color: hideSelectedVal ? "#333" : "#555",
          boxSizing: "border-box",
          padding: hideSelectedVal ? "8px 10px" : "10px",
          fontFamily: "sans-serif",
        }}
        onClick={() => {
          setShowFilterList(!showFilterList);
          setSearchWord("");
        }}
      >
        {selectedTitle && !hideSelectedVal ? (
          <span
            style={{
              width: "calc(100% - 40px)",
              display: "inline-block",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              height: "20px",
              verticalAlign: "top",
            }}
          >
            {selectedTitle}
          </span>
        ) : (
          <span style={{ color: hideSelectedVal ? "#757575" : "#555" }}>
            {placeholder || "All Drivers"}
          </span>
        )}
        <ArrowDropDown
          style={{
            float: "right",
            fontSize: "19px",
            color: "#555",
          }}
        />
        {selectedTitle && !hideSelectedVal ? (
          <Close
            fontSize="small"
            style={{
              float: "right",
              fontSize: "18px",
              color: "#777",
              cursor: "pointer",
            }}
            onClick={() => {
              handleDriverChange();
              setSelectedTitle();
            }}
          />
        ) : (
          ""
        )}
      </div>
      {showFilterList === true ? (
        <div
          style={{
            background: "#fff",
            position: "absolute",
            zIndex: "9",
            width: "100%",
            boxSizing: "border-box",
            padding: "0 5px 5px",
            border: "1px solid rgb(226, 226, 226)",
          }}
        >
          <React.Fragment>
            <Input
              value={searchWord}
              onChange={(e) => setSearchWord(e.target.value)}
              onBlur={handleBlur}
              onFocus={handleFocus}
              placeholder="Search.."
            />
            <Search
              fontSize="small"
              style={{
                verticalAlign: "middle",
                fontSize: "22px",
                color: "#ccc",
              }}
            />
          </React.Fragment>
          <ul
            style={{
              listStyleType: "none",
              padding: "5px 0",
              margin: "0",
              borderTop: "1px solid #e2e2e2",
              maxHeight: "220px",
              overflow: "auto",
            }}
          >
            {driversFilterList && driversFilterList.length > 0 ? (
              driversFilterList.map((c, i) => {
                return (
                  <LI
                    key={i}
                    onClick={() => {
                      setSelectedTitle(c.title);
                      setShowFilterList(false);
                      handleDriverChange(c.id, c);
                    }}
                  >
                    {c.title}
                  </LI>
                );
              })
            ) : (
              <GText med text="No Data Found" />
            )}
          </ul>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
