import React, { useEffect, useState, useRef } from "react";
import { ImgUrl, addDefaultSrc, ApiService, FileApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { NoteDiv } from "../styledComponents/notesStyles";
import GLightBox from "../gComponents/gLightBox";
import GText from "../gComponents/gText";
import GImageEditor from "../gComponents/gImageEditor";
import GButton from "../gComponents/gButton";
import { Add } from "@material-ui/icons";

const idStyle = {
  float: "right",
  fontSize: "18px",
  color: "#0381FC",
  margin: "5px",
  fontWEight: "400",
  fontStyle: "italic",
};
const imgStyle = {
  cursor: "pointer",
  width: "80px",
  height: "80px",
  objectFit: "cover",
  border: "1px solid #e2e2e2",
  padding: "5px",
};

export default function OrderDeliveryInfo(props) {
  const [openSnackbar] = useSnackbar();
  const [notes, setNotes] = useState([]);
  const [images, setImages] = useState([]);
  const [signatures, setSigns] = useState([]);
  const [selectedImgPreview, setSelectedImgPreview] = useState(false);
  const [isImgPreview, setImgPreview] = useState(false);
  const [selectedImgType, setSelectedImgType] = useState("");
  const fileInput = useRef(null);

  useEffect(() => {
    setNotes(props.notes || []);
    setImages(props.images || []);
    setSigns(props.signatures || []);
  }, [props]);

  const changeFileHandler = (e) => {
    var files = fileInput.current.files[0];

    FileApiService("POST", "dimage", files)
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.status_code === 1) {
          updateField(response.data.image);
          fileInput.current.value = "";
        }
        openSnackbar(response.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateField = (newValue) => {
    var send_data = {
      order_task_id: props.taskId,
      image_path: newValue,
    };
    ApiService({ method: "POST", route: "task/images", body: send_data })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.onSubmitImages(response.data.data);
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (props.odInfovisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        Delivery Info
        <GText
          g4
          text={`#${props.linkedId}`}
          bold
          theme
          style={{
            margin: "0",
            marginLeft: "auto",
            fontStyle: "italic",
            width: "auto",
            fontSize: "14px",
          }}
        />
      </div>
      <div className="modal-body">
        <div
          style={{
            padding: "0 15px 15px",
            marginBottom: "20px",
          }}
        >
          <h4 style={{ margin: "5px 0 15px" }}>Notes ({notes.length}) </h4>

          {notes.map((p) => {
            return (
              <div key={p.id}>
                <NoteDiv className={"send"}>
                  <h5>{p.notes}</h5>
                  <p>{p.created}</p>
                </NoteDiv>
              </div>
            );
          })}
          {notes.length === 0 ? (
            <p style={{ color: "#b2b2b2", textAlign: "center" }}>
              No notes found
            </p>
          ) : (
            ""
          )}
        </div>
        <hr />
        <div
          style={{
            padding: "5px 15px 10px",
            marginBottom: "20px",
          }}
        >
          <input
            id={"imgInput"}
            type="file"
            ref={fileInput}
            onChange={changeFileHandler}
            style={{ display: "none" }}
            accept="image/x-png,image/gif,image/jpeg"
          />

          <h4 style={{ margin: "10px 0 15px" }}>
            Images ({images.length})
            <GButton
              variant="linkable"
              style={{ float: "right" }}
              onClick={() => {
                document.getElementById("imgInput").click();
              }}
            >
              <Add />
            </GButton>
          </h4>

          {images.map((p, i) => {
            return (
              <div
                key={p.id}
                style={{ display: "inline-block", margin: "5px" }}
              >
                <img
                  alt=""
                  src={ImgUrl("dimage") + "/" + p.image_path}
                  onError={addDefaultSrc}
                  className="thumbnail"
                  style={imgStyle}
                  onClick={() => {
                    setSelectedImgPreview(p.image_path);
                    setImgPreview(true);
                    setSelectedImgType("dimage");
                  }}
                />
              </div>
            );
          })}

          {images.length === 0 ? (
            <p style={{ color: "#b2b2b2", textAlign: "center" }}>
              No images found
            </p>
          ) : (
            ""
          )}
        </div>
        <hr />
        <div
          style={{
            padding: "5px 15px 10px",
            marginBottom: "20px",
          }}
        >
          <h4 style={{ margin: "10px 0 15px" }}>
            Signatures ({signatures.length}){" "}
          </h4>

          {signatures.map((p) => {
            return (
              <div
                key={p.id}
                style={{ display: "inline-block", margin: "5px 10px" }}
              >
                <img
                  alt=""
                  src={ImgUrl("dsignature") + "/" + p.signature_path}
                  onError={addDefaultSrc}
                  className="thumbnail"
                  style={imgStyle}
                  onClick={() => {
                    setSelectedImgPreview(p.signature_path);
                    setImgPreview(true);
                    setSelectedImgType("dsignature");
                  }}
                />
              </div>
            );
          })}

          {signatures.length === 0 ? (
            <p style={{ color: "#b2b2b2", textAlign: "center" }}>
              No signatures found
            </p>
          ) : (
            ""
          )}
        </div>
      </div>

      {isImgPreview === true ? (
        <GLightBox
          images={selectedImgPreview}
          isImgPreview={isImgPreview}
          setImgPreview={setImgPreview}
          imgType={selectedImgType}
        ></GLightBox>
      ) : (
        ""
      )}
    </>
  );
}
