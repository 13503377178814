import React, { useState, useEffect, useContext } from "react";
import GView from "../gComponents/GView";
import GText from "../gComponents/gText";
import VehicleSection from "./orderVehicles";
import OrderStops from "./orderStops";
import GTaxiMap from "./orderStopsMap";
import FormSection from "./orderForm";
import InstructionSection from "./orderInstructions";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import useSettings from "../useSettings";
import GButton from "../gComponents/gButton";
import CheckoutForm from "./orderCheckout";
import Modal from "react-modal";
import {
  GCardShimmer,
  GRoundShimmer,
  GLineShimmer,
} from "../gComponents/gShimmer";
import OrderSteps from "../gComponents/gStepRoutes";
import OrderTimeslots from "./orderTimeSlots";
import OrderReview from "./orderReview";
import OrderItems from "./orderItems";
import { ReactComponent as ArrowNext } from "../assets/images/arrowNext.svg";
import { ReactComponent as Close } from "../assets/images/cancel.svg";
import { CustomerFilter } from "../components/customerFilterComponent";
import GEmpty from "../gComponents/gEmpty";

const steps = [
  {
    index: 1,
    title: "Location",
  },
  {
    index: 2,
    title: "Items",
  },
  {
    index: 3,
    title: "Time",
  },
  {
    index: 4,
    title: "Instructions",
  },
  {
    index: 5,
    title: "Review",
  },
];

const OrderCreate = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [activeIndex, setSelectedActiveInd] = useState(steps[0].index);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const { addresses, fields, vehicle_types, special_instructions, items = []} = formData;
  const [addressData,setAddressData] = useState([])
  const [openSnackbar] = useSnackbar();
  const settings = useSettings(true);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [selectedVehicleType, setSelectedVehicleType] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [formFields, setFormFields] = useState([]);
  const [places, setPlaces] = useState([]);
  const [isFormValidate, setIsFormValidate] = useState(false);
  const [orderData, setOrderData] = useState({
    customer_id: selectedCustomer?.id || "",
    stops: [],
    fields: [],
    points: false,
    tip: 0,
    vehicle_type_id: selectedVehicleType,
    scheduled_from: "",
    scheduled_to: "",
    special_instructions: "",
  });
  const [checkoutVisible, setCheckoutVisible] = useState(false);

  useEffect(() => {
    setSelectedActiveInd(steps[0].index);
    ApiService({ method: "OPTIONS", route: "orders" })
      .then((response) => {
        console.log(response);
        if (response.status === 26) {
          openSnackbar(
            "NETWORK FAILED. Please check your internet connection."
          );
          return;
        } else {
          if (response?.data?.status_code === 1) {
            setFormData(response.data.data);
            orderData.fields = response.data?.data?.fields || [];
            setOrderData({ ...orderData });
          } else {
            openSnackbar(response?.data?.message);
          }
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });

      if(selectedCustomer?.id){
        getAddress()
      }

  }, [selectedCustomer]);


 function getAddress(){
  ApiService({ method: "GET", route: "addresses", body: {customer_id:selectedCustomer?.id,per_page:50} })
        .then((response) => {
          console.log(response);
          if (response.data.status_code === 1) {
            setAddressData(response.data.data.data || []);
          }
        })
        .catch((error) => {
          console.log(error);
        });
 }

  useEffect(()=>{
    if(selectedCustomer?.id){
    window.scrollTo(0, 0);
    var myDiv = document.getElementById('innerStepsView');
myDiv.scrollTop = 0;
    }
  },[activeIndex])

  useEffect(() => {
    console.log(selectedVehicleType);
    orderData.vehicle_type_id = selectedVehicleType;
    orderData.customer_id = selectedCustomer?.id;
    orderData.fields = formFields.length > 0 ? formFields : fields;
    orderData.items = selectedItems.length > 0 ? selectedItems : [];
    setOrderData({ ...orderData });
  }, [selectedVehicleType, formFields, selectedCustomer,selectedItems]);

  useEffect(() => {
    orderData.stops = places;
    setOrderData({ ...orderData });
  }, [places]);

  function showValidations() {
    if (activeIndex >= 1) {
      if (!orderData.vehicle_type_id) {
        openSnackbar("Choose Vehicle is required");
        return false;
      }
      if (orderData.stops.length == 0) {
        openSnackbar("Choose Pickup address");
        return false;
      }
      if (orderData.stops.length > 0 && orderData.stops.length < 2) {
        openSnackbar("Choose Delivery address");
        return false;
      }
    }

    if (activeIndex >= 2) {
      if (orderData.items.length > 0) {
        let iValidate;
        orderData.items.map((item, i) => {
          iValidate = true;
          if (!item.category) {
            openSnackbar(`Choose category for item ${i + 1}`);
            iValidate = false;
            return;
          }
          if (!item.quantity) {
            openSnackbar(`Add quantity for item ${i + 1}`);
            iValidate = false;
            return;
          }
          if (!item.packaging) {
            openSnackbar(`Choose package for item ${i + 1}`);
            iValidate = false;
            return;
          }
          if (!item.fit) {
            openSnackbar(`Choose Fit in for item ${i + 1}`);
            iValidate = false;
            return;
          }
          if (!item.weight) {
            openSnackbar(`Choose weight for item ${i + 1}`);
            iValidate = false;
            return;
          }
        });

        if (iValidate != true) {
          return false;
        }
      }
    }
    if (activeIndex >= 3) {
      if (!orderData.scheduled_from || !orderData.scheduled_to) {
        openSnackbar("Scheduled time is required");
        return false;
      }
    }
    if (activeIndex >= 4) {
      if (orderData.fields.length >= 0) {
        let validate = true;
        let m = "";
        orderData.fields.map((f) => {
          console.log(f);
          if (f.required === 1) {
            if (!f.value) {
              validate = false;
              m = f;
            }
          }
        });
        if (validate == false) {
          openSnackbar(m.title + " is required");
          return false;
        }
      }
    }
    return true;
  }

  function orderCheckout() {
    setIsDisabled(true);
    let COrderData = { ...orderData };
    ApiService({
      method: "POST",
      route: "order/checkout",
      body: COrderData,
    })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setSelectedActiveInd(activeIndex + 1);
        } else {
          openSnackbar(response.data.message);
        }

        setIsDisabled(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className={"mainInnerView"} style={{padding:"12px 15px"}}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginBottom: "10px",
          gap: "10px",alignItems:"center"
        }}
      >
        <h1 style={{ marginBottom: "10px" }}>Create Order</h1>
        <CustomerFilter
          value={selectedCustomer?.id || ""}
          style={{
            marginLeft: "auto",
            marginRight: "10px",
          }}
          handleCustomerChange={(id, value) => {
            setSelectedCustomer(value);
          }}
        />
      </div>
      {selectedCustomer?.id ? (
        <GView
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <GView
            style={{
              width: "35%",
              minWidth: "450px",
              background: "#f5f6f9",
            }}
          >
              <OrderSteps
          activeIndex={activeIndex}
          routes={steps}
        />

            <GView
        id={"innerStepsView"}
              style={{
                height: "calc(100vh - 280px)",
                overflow: "overlay",
                padding: "0px 15px 0 7px",
            margin: "10px 0",
              }}
            >
                {activeIndex == 1 && (
            <>
              {isLoading == true ? (
                <>
                  <GLineShimmer />
                  <GRoundShimmer></GRoundShimmer>
                  <GLineShimmer />
                  <GCardShimmer
                    type={"single"}
                    color={"#DBDCDD"}
                  ></GCardShimmer>
                </>
              ) : (
                <>
                  {vehicle_types && vehicle_types.length > 0 && (
                    <VehicleSection
                      data={vehicle_types}
                      selectedVehicleType={selectedVehicleType}
                      onSelect={(id) => setSelectedVehicleType(id)}
                    />
                  )}

                  <GView style={{ margin: "20px 0" }}>
                    <GText
                      g4
                      semi
                      text={"Select Stops"}
                      style={{ margin: "10px 0" }}
                    />
                    {addresses && (
                      <OrderStops setPlaces={setPlaces} address={addressData || addresses}
                      stops={orderData.stops} />
                    )}
                  </GView>
                </>
              )}
               </>
          )}

{activeIndex == 2 && (
            <>
              <OrderItems
                orderData={orderData}
                setSelectedVehicleType={setSelectedVehicleType}
                data={items}
                onChange={(iData) => {
                  setSelectedItems(iData);
                }}
              />
            </>
          )}
          {activeIndex == 3 && (
            <>
              <OrderTimeslots
                orderData={orderData}
                settings={settings}
                setOrderData={setOrderData}
              />
            </>
          )}
          {activeIndex == 4 && (
            <>
              <GView>
                <GText
                  g4
                  semi
                  text={"Delivery Instructions & Information"}
                  style={{ margin: "10px 0 20px" }}
                />
                {special_instructions && special_instructions.length > 0 && (
                  <InstructionSection
                    data={special_instructions}
                    orderData={orderData}
                    settings={settings}
                    setOrderData={setOrderData}
                  />
                )}
                {fields && fields.length > 0 && (
                  <FormSection
                    data={fields}
                    settings={settings}
                    onChange={(fields) => {
                      setFormFields(fields);
                    }}
                  />
                )}
              </GView>
            </>
          )}

          {activeIndex == 5 && (
            <>
              <OrderReview
                data={orderData}
                settings={settings}
                vehicleTypes={vehicle_types}
                special_instructions={special_instructions}
                items={items}
              />
            </>
          )}
            </GView>


            <GView>
          {activeIndex > 1 && (
            <GButton
              variant="outlined"
              style={{
                width: "auto",
                margin: "10px 20px 0",
                fontSize: "15px",
                padding: "0.8em 1.5em",
                fontWeight: "550",
                borderRadius: "2px",
                letterSpacing: "0.5px",
                display: "inline-flex",
              }}
              onClick={() => {
                if (activeIndex > 1) {
                  setSelectedActiveInd(activeIndex - 1);
                }
              }}
            >
              <ArrowNext
                style={{
                  transform: "rotate(180deg)",
                  fill: "#0381FC",
                  width: "17px",
                  height: "17px",
                  marginRight: "5px",
                }}
              />{" "}
              Back{" "}
            </GButton>
          )}
          {activeIndex == 5 ? (
            <>
              {" "}
              {isLoading != true && (
                <GButton
                  variant="condensed"
                  // disabled={!isFormValidate}
                  children="Proceed to Payment"
                  style={{
                    // width: "calc(100% - 50px)",
                    margin: "10px 20px 0",
                    textTransfoem: "uppercase",
                    fontSize: "14px",
                    padding: "0.9em 1.5em",
                    fontWeight: "550",
                    borderRadius: "2px",
                    float: "right",
                    letterSpacing: "0.5px",
                    display: "inline-flex",
                  }}
                  onClick={() => {
                    let v = showValidations();
                    if (v == true) {
                      setCheckoutVisible(true);
                    }
                  }}
                />
              )}
            </>
          ) : (
            <GButton
              variant="condensed"
              disabled={isDisabled}
              style={{
                width: "auto",
                margin: "10px 20px 0",
                fontSize: "15px",
                padding: "0.8em 1.5em",
                fontWeight: "550",
                borderRadius: "2px",
                float: "right",
                letterSpacing: "0.5px",
                display: "inline-flex",
              }}
              onClick={() => {
                let v = showValidations();
                if (v == true) {
                  if (activeIndex == 1) {
                    orderCheckout();
                  } else {
                    setSelectedActiveInd(activeIndex + 1);
                  }
                }
              }}
            >
              Continue{" "}
              <ArrowNext
                style={{
                  fill: "#fff",
                  width: "17px",
                  height: "17px",
                  marginLeft: "5px",
                }}
              />
            </GButton>
          )}
        </GView>
          </GView>

          <GView
            style={{
              width: "65%",
              position: "relative",
            }}
          >
            <GTaxiMap
              center={{ lat: 30.70586, lng: 76.708282 }}
              zoom={15}
              places={places}
              isAutoComplete={false}
              googleMapURL={
                "https://maps.googleapis.com/maps/api/js?key=" +
                process.env.REACT_APP_GOOGLE_KEY +
                "&libraries=geometry,drawing,places"
              }
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={
                <div
                  style={{
                    height: "calc(100vh - 250px)",
                  }}
                />
              }
              mapElement={<div style={{ height: `100%` }} />}
            />
          </GView>
        </GView>
      ) : (
        <div className="listPaper">
          <GEmpty subtitle={"Choose customer to create an order"} />
        </div>
      )}
      <Modal
        isOpen={checkoutVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCheckoutVisible(false)}
        style={{
          content: {
            top: "0",
            left: "auto",
            right: "0",
            bottom: "0",
            width: "450px",
          },
        }}
        contentLabel="Checkout Modal"
      >
        <CheckoutForm
          checkoutVisible={checkoutVisible}
          setCheckoutVisible={setCheckoutVisible}
          data={{ ...orderData }}
        />
      </Modal>
    </div>
  );
};

export default OrderCreate;
