import React, { useEffect, useState } from "react";
import { makeStyles, Paper, Backdrop } from "@material-ui/core";
import { ApiService, ImgUrl } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GImageEditor from "../gComponents/gImageEditor";
import GButton from "../gComponents/gButton";
import useDataFctory from "../useDataFactory";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: "15px",
    right: "15px",
  },
  option: {
    marginTop: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#888",
    width: "40px",
    height: "40px",
    fontSize: "18px",
    borderRadius: "5px",
    cursor: "pointer",
    //transition: 'background 0.3s',
    transitionProperty: "background-color, color",
    transitionDuration: "300ms, 300ms",
    "&:hover": {
      backgroundColor: "#333",
      color: "#bbb",
      fontWeight: "500",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function DocumentForm(props) {
  const classes = useStyles();
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [openSnackbar] = useSnackbar();
  const { data: docTypesList } = useDataFctory("document/types", false, {
    user_type: props.userType,
  });
  const initialValues = {
    document_type_id: !props.isEmpty ? props.data.document_type_id : "",
    photo: !props.isEmpty ? props.data.photo : "",
    thumb_photo: !props.isEmpty ? props.data.thumb_photo : "",
    user_id: props.linkedId || "",
    user_type: props.userType || "",
  };

  const onSubmit = (values) => {
    setIsBtnDisabled(true);
    console.log(values);
    //return;
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty ? "documents/" + props.data.id : "documents";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setDocvisible(false);
          props.onSubmit();
        }
        openSnackbar(response.data.message);
        setIsBtnDisabled(false);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    document_type_id: Yup.string().required("Required"),
  });

  //console.log(handleChange);
  const handleClose = (e) => {
    if (e.target.id !== (2731).toString()) {
      return;
    }
    props.setDocvisible(false);
  };

  // const handleChange = (e) => {
  //   let value = e.target.value;
  //   this.setState((prevState) => ({
  //     newUser: {
  //       ...prevState.newUser,
  //       name: value,
  //     },
  //   }));
  // };

  if (props.docvisible === false) {
    return null;
  }
  return (
    <div
      style={{
        position: "absolute",
        margin: "auto",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    >
      <Backdrop
        id="2731"
        className={classes.backdrop}
        open={props.docvisible}
        onClick={handleClose}
      >
        {/* <Popover style={{padding:"100px"}}> */}
        <Paper
          className={`modalRight
              ${props.docvisible === true ? "fade" : ""}`}
          style={{ padding: "20px" }}
        >
          <h3>{!props.isEmpty ? "Edit" : "Add"} Document</h3>

          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values }) => (
              //  function updateField(newValue){
              //      values.icon = newValue
              // }

              <Form>
                <GImageEditor
                  onSubmit={(newValue, id, thumb_val) => {
                    values.photo = newValue;
                    values.thumb_photo = thumb_val;
                  }}
                  id="thumb_photo"
                  image={
                    values.thumb_photo
                      ? ImgUrl("document") + "/" + values.thumb_photo
                      : ""
                  }
                  type="document"
                  style={{ height: "160px", paddingTop: "60px" }}
                  divstyle={{ margin: "0 auto", display: "block" }}
                />
                <br />
                {docTypesList.length > 0 ? (
                  <FormikControl
                    control="select"
                    as="select"
                    label="Document Types"
                    name="document_type_id"
                    options={docTypesList}
                    key_title="title"
                    key_value="id"
                  />
                ) : (
                  ""
                )}

                <br />
                <GButton
                  type="submit"
                  disabled={isBtnDisabled}
                  variant="condensed"
                  children={!props.isEmpty ? "Update" : "Create"}
                  style={{ margin: "0 15px", width: "calc(100% - 30px)" }}
                />
              </Form>
            )}
          </Formik>
        </Paper>
        {/* </Popover> */}
      </Backdrop>
    </div>
  );
}
