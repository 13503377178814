import React, { useState, useEffect } from "react";
// import { Button} from '@material-ui/core';
import styled from "styled-components";
import GButton from "./gButton";
import { Edit, Delete, Settings } from "@material-ui/icons";
import GConfirmDialog from "./gConfirmDialog";
import useSettings from "../useSettings";

const ListCard = styled.div`
  padding: 0.8em;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  border-radius: 4px;
  background: #f4f5f8;
  vertical-align: top;
  width: 215px;
  display: inline-block;
  margin: 10px;
  height: 70px;
  overflow: overlay;
  /*  
  &:hover{
    color:rgba(0, 0, 0, 0.26);
  }  */
  &.active {
    background: rgba(0, 204, 255, 0.6);
    // color: #fff;

    h5 {
      color: #333;
    }
    p {
      color: #333;
    }
  }

  h4 {
    margin: 0px;
    font-weight: 400;
    line-height: 20px;
    display: inline-block;
    width: 90%;
  }
  h5 {
    margin: 0px;
    font-weight: 400;
    color: #777;
    font-size: 0.9rem;
    padding: 5px 0;
  }
  p {
    margin: 0px;
    font-weight: 400;
    display: inline-block;
    width: 50%;
    font-size: 0.95rem;
    color: #818183;
  }
`;

function GPricingListCard(props) {
  const [pricingRulesData, setPricingRulesData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRuleId, setSelectedRuleId] = useState(false);
  const [defaultDialogOpen, setDefaultDialogOpen] = useState(false);
  const settings = useSettings(true);
  useEffect(() => {
    setPricingRulesData(props.rulesData);
  }, [props]);

  const handleDelete = (e) => {
    //setValue(e.target.value);
    console.log(selectedRuleId);
    props.onDelete(selectedRuleId);
  };
  const handleDefault = () => {
    props.onSelectDefault(selectedRuleId);
  };

  return (
    <div>
      {pricingRulesData.map((v) => {
        return (
          <ListCard
            key={v.id}
            className={
              props.selectedRule
                ? props.selectedRule.id === v.id
                  ? "active"
                  : ""
                : ""
            }
            onClick={() => {
              if (props.getSelected === true) {
                props.onSelected(v);
              }
            }}
            style={{ cursor: props.getSelected === true ? "pointer" : "" }}
          >
            {props.default ? (
              <div
                className="radio"
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  width: "30px",
                  verticalAlign: "top",
                }}
              >
                <label>
                  <input
                    type="radio"
                    value="cash"
                    checked={v.id === parseInt(props.default.key_value)}
                    onClick={() => {
                      setSelectedRuleId(v.id);
                      setDefaultDialogOpen(true);
                    }}
                    style={{ cursor: "pointer", height: "17px", width: "17px" }}
                  />
                </label>
              </div>
            ) : (
              ""
            )}
            <div
              style={{
                display: props.default ? "inline-block" : "block",
                width: props.default ? "calc(100% - 30px)" : "100%",
              }}
            >
              <h4>{v.title}</h4>

              {props.actions === true ? (
                <div style={{ float: "right" }}>
                  <GButton
                    variant="linkable"
                    onClick={() => {
                      props.onEdit(v);
                    }}
                  >
                    <Edit fontSize="small" />
                  </GButton>
                  <GButton
                    variant="linkable"
                    onClick={() => {
                      setSelectedRuleId(v.id);
                      setDialogOpen(true);
                    }}
                  >
                    <Delete fontSize="small" />
                  </GButton>
                </div>
              ) : (
                ""
              )}
              {/* 
              <div
                style={{
                  height: "97px",
                  overflowY: "overlay",
                  width: "100%",
                }}
              >
                <h5>Distance Slots Based</h5>

                {v?.options &&
                  v?.options.map((slots, i) => {
                    return (
                      <p key={i}>
                        {slots.distance} {settings.distance_unit}:{" "}
                        {slots.asap_cost} - {slots.flex_cost}
                      </p>
                    );
                  })}
              </div> */}
            </div>
          </ListCard>
        );
      })}

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Pricing Rule"
          text="Are you sure you want to delete Pricing Rule?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
            handleDelete();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}

      {defaultDialogOpen === true ? (
        <GConfirmDialog
          open={defaultDialogOpen}
          title="Set Default Rule"
          text="Are you sure you want to set this as a default rule?"
          handleClose={() => setDefaultDialogOpen(false)}
          onConfirmClose={() => {
            setDefaultDialogOpen(false);
            handleDefault();
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}
    </div>
  );
}

export default GPricingListCard;
