import React, { useState, useEffect } from "react";
import "../css/invoice.css";
import { ApiService, ImgUrl, addDefaultLogo } from "../services";
import { matchPath } from "react-router-dom";
import { GContent } from "../gComponents/gContent";
import GRouteAddress from "../gComponents/GRouteAddress";
import useSettings from "../useSettings";

function OrderInvoice(props) {
  const setting = useSettings();
  const [orderId, setOrderId] = useState(props.match.params.orderId);
  const [invoiceData, setInvoiceData] = useState({
    tasks: [],
  });
  const [orderPaySummary, setOrderPaySummary] = useState({
    payment: {},
    data: [],
  });
  const [linksData, setLinksData] = useState({
    invoice_logo: {},
    invoice_header_text: {},
    invoice_footer_text: {},
  });
  const match = matchPath(props.match.params.orderId, {
    path: "/order-invoice/:id",
    exact: true,
    strict: false,
  });

  const getInvoiceSettings = () => {
    ApiService({ method: "GET", route: "setting/invoice" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setLinksData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    console.log(props.match.params.orderId + "==" + orderId);
    setOrderId(props.match.params.orderId);
    const getOrderDetail = () => {
      var hitUrl = "orders/" + orderId;

      ApiService({ method: "GET", route: hitUrl })
        .then((response) => {
          console.log(response.data);
          if (response.data.status_code === 1) {
            setInvoiceData(response.data.data);
            setOrderPaySummary({
              payment: response.data.data.payment_summary,
              data: response.data.data.payment_summary.data,
            });
            getInvoiceSettings();
            console.log(invoiceData);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getOrderDetail();
  }, []);

  return (
    <div style={{ height: "100vh", overflow: "overlay" }}>
      <div className="invoiceDiv">
        <div
          className="flex"
          style={{
            width: "40%",
          }}
        >
          <img
            src={ImgUrl("logo") + "/" + linksData.invoice_logo.key_value}
            alt="logo"
            onError={addDefaultLogo}
          />
        </div>
        <div
          className="flex"
          style={{
            width: "60%",
          }}
        >
          <div>
            <p>{linksData.invoice_header_text.key_value}</p>
            <div
              className="flex"
              style={{
                width: "50%",
              }}
            >
              <label>Order Date</label>
              <p>{invoiceData.created || ""}</p>

              <br />

              <label>Order Number</label>
              <p>{"#" + (invoiceData?.slug || orderId)}</p>
              <br />
            </div>
            <div
              className="flex"
              style={{
                width: "50%",
              }}
            >
              <label>Invoice</label>
              <p>{invoiceData.customer ? invoiceData.customer.title : ""}</p>
              <p>{invoiceData.customer ? invoiceData.customer.phone : ""}</p>
              <p>
                {invoiceData.delivery
                  ? invoiceData.delivery.delivery_address
                  : ""}
              </p>
            </div>
          </div>
        </div>

        <hr />

        <div style={{ padding: "15px 0" }}>
          <GRouteAddress task={invoiceData.tasks} />
        </div>

        <hr />

        <div>
          <div
            className="flex"
            style={{
              width: "50%",
            }}
          >
            <br />
            <GContent
              key={invoiceData.vehicle_type_id}
              title={"Vehicle Type"}
              value={invoiceData.vehicle_type_title || "NA"}
              type="inline"
            />
            {invoiceData.schedule_time && (
              <GContent
                key={"schedule_time"}
                title={"Schedule Time"}
                value={invoiceData.schedule_time || "-"}
                type="inline"
              />
            )}
            {invoiceData?.special_instruction_display && (
              <GContent
                key={"special_instruction"}
                title={"Special Instructions"}
                value={invoiceData.special_instruction_display || "-"}
                type="inline"
              />
            )}
            {invoiceData.distance || invoiceData.distance >= 0 ? (
              <GContent
                key={invoiceData.distance}
                title={"Distance"}
                value={invoiceData.distance + " " + setting?.distance_unit}
                type="inline"
              />
            ) : (
              ""
            )}

            {invoiceData.duration || invoiceData.duration >= 0 ? (
              <GContent
                key={invoiceData.duration}
                title={"Duration"}
                value={invoiceData.duration + " mins"}
                type="inline"
              />
            ) : (
              ""
            )}
          </div>
          <div
            className="flex"
            style={{
              width: "50%",
            }}
          >
            {/* <table>
              <tbody>
                {orderPaySummary?.data.map((payment, ind) => {
                  return (
                    <tr className={payment.title + " payment"} key={ind}>
                      <td>{payment.title}</td>
                      <td>{payment.value}</td>
                    </tr>
                  );
                })}

                <tr className="payment">
                  <td>Total </td>
                  <td>{orderPaySummary?.payment?.total_display}</td>
                </tr>
              </tbody>
            </table> */}
          </div>
        </div>
        <br />

        <label>
          Thank you for using the services of {setting?.project_name}.
        </label>
        <p>Powered by delivAI</p>
        <br />
        <p>{linksData.invoice_footer_text.key_value}</p>
      </div>
    </div>
  );
}

export default OrderInvoice;
