import React, { useState, useEffect } from "react";
import { TableContainer } from "@material-ui/core";
import { ApiService } from "../services";
import { Assignment } from "@material-ui/icons";
import { Link, useHistory } from "react-router-dom";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "../styledComponents/tableStyle";
import GPagination from "../gComponents/gPagination";
import GPaginationSelect from "../gComponents/gPaginationSelectbox";
import GTableShimmer from "../gComponents/gTableShimmer";
import GEmpty from "../gComponents/gEmpty";
import QueryWith from "../Query";
import OrderStatuses from "../gComponents/gOrderStatus";
import { DriverFilter } from "../components/driverFilterComponent";
import { CustomerFilter } from "../components/customerFilterComponent";
import GSelectbox from "../gComponents/gSelectbox";
import GSorting from "../gComponents/gSorting";
import useSettings from "../useSettings";
import GButton from "../gComponents/gButton";
import { createSocketConnection } from "../useLaravelPusher";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import MultipleOrderActionView from "./orderActions";
import Modal from "react-modal";
import OrderBulkUploadForm from "../forms/orderBulkUpload";

const DateFilterStyles = styled.div`
  text-align: right;
  display: flex;
  flex-direction: row;
  .from,
  .to {
    border: 1px solid #e2e2e2;
    background: #fff;
    padding: 7px 5px 9px;
    margin: 0;
    display: flex;
    align-items: center;
    text-align: left;
    minwidth: 200px;
    label {
      display: block;
      padding: 0 2px;
      font-size: 14px;
      color: #777;
      width: 95px;
      font-style: italic;
    }
    .react-datepicker__input-container {
      margin-right: 30px;
    }
    .from .react-datepicker__tab-loop .react-datepicker-popper {
      left: -30px !important;
    }
    .from
      .react-datepicker-popper[data-placement^="bottom"]
      .react-datepicker__triangle {
      left: 0 !important;
    }

    input {
      border: 0;
      outline: 0;
      box-shadow: none;
      font-weight: 450;
    }
    .react-datepicker-popper {
      // z-index: 2;
    }
  }
`;
const columns = [
  { id: "checkbox", label: "", minWidth: 30 },
  { id: "id", label: "Order ID", minWidth: 30, type: "order" },
  {
    id: "customer",
    label: "Account",
    minWidth: 100,
    type: "customer_id",
    align: "center",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 120,
    type: "order",
    align: "center",
  },
  // {
  //   id: "risk",
  //   label: "Risk",
  //   minWidth: 60,
  //   type: "order",
  //   align: "center",
  // },
  {
    id: "state",
    label: "State",
    minWidth: 120,
    type: "order",
    align: "center",
  },
  {
    id: "deliver_to",
    label: "Deliver To",
    minWidth: 120,
    type: "order",
    align: "center",
  },
  {
    id: "scheduled_for",
    label: "Scheduled For",
    minWidth: 120,
    type: "order",
    align: "center",
  },
  // {
  //   id: "driver",
  //   label: "Driver",
  //   minWidth: 150,
  //   type: "driver_id",
  //   align: "center",
  // },
  {
    id: "payment",
    label: "Payment",
    minWidth: 60,
    type: "order",
    align: "center",
  },
  {
    id: "price",
    label: "Price",
    minWidth: 80,
    // format: (value) => value.toLocaleString('en-US'),
    //format: (value) => value.toFixed(2),
    type: "order",
    align: "center",
  },
  // {
  //   id: "pickup_time",
  //   label: "Pickup Time",
  //   minWidth: 110,
  //   width: 160,
  //   type: "order",
  //   align: "center",
  // },
  // {
  //   id: "delivery_time",
  //   label: "Delivery Time",
  //   minWidth: 110,
  //   width: 160,
  //   type: "order",
  //   align: "center",
  // },

  // {
  //   id: "pending_amount",
  //   label: "Pending Amount",
  //   minWidth: 120,
  //   type: "order",
  //   align: "center",
  // },
  {
    id: "created_at_formatted",
    label: "Created",
    minWidth: 130,
    type: "order",
    align: "center",
  },
];

const orderSorting = [
  {
    title: "Created At",
    value: "created_at",
  },
  {
    title: "Pickup Time",
    value: "pickup_from",
  },
  {
    title: "Delivery Time",
    value: "delivery_to",
  },
];

export default function Orders(props) {
  const [checkedIds, setCheckedIds] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [oUploadVisible, setOUploadVisible] = useState(false);
  const [ordersData, setOrderData] = useState([]);
  const [orderPaginationData, setOrderPaginationData] = useState("");
  const [orderStatusData, setOrderStatusData] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  let history = useHistory();
  let params = new URLSearchParams(history.location.search);
  const [filters, setFilters] = useState({
    page: params.get("page") ? params.get("page") : "1",
    order_status: params.get("order_status")
      ? params.get("order_status")
      : "active",
    // agent_id: params.get("agent_id") ? params.get("agent_id") : "",
    customer_id: params.get("customer_id") ? params.get("customer_id") : "",
    // delivery_type: params.get("delivery_type")
    //   ? params.get("delivery_type")
    //   : "",
    // order_type: params.get("order_type") ? params.get("order_type") : "",
    // orderby: params.get("orderby") ? params.get("orderby") : "created_at",
    order: params.get("order") ? params.get("order") : "DESC",
    date_from: params.get("date_from") ? params.get("date_from") : "",
    date_to: params.get("date_to") ? params.get("date_to") : "",
  });

  const settings = useSettings(true);

  function getOrders() {
    let obj = {};
    if (props.type) {
      props.onChange("orders");
      obj[props.title] = props.value;
      obj["page"] = filters.page;
    } else {
      var params = QueryWith(history.location, filters);
      if (params.length > 0) {
        history.push({
          pathname: "/orders",
          state: "order",
          search: params[0],
        });
      }
    }
    var query = props.type ? obj : filters;

    ApiService({ method: "GET", route: "orders", body: query })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          response.data.data.data.map((d) => {
            return (d.checked = false);
          });
          setCheckedIds([]);
          setCheckedAll(false);
          setOrderData([...response.data.data.data]);
          setOrderPaginationData(response.data.data);
          setOrderStatusData(response.data.statuses);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    if (filters?.date_from && filters?.date_to)
      setDateRange([new Date(filters?.date_from), new Date(filters?.date_to)]);
  }, [filters]);

  useEffect(() => {
    console.log(props);
    getOrders();
  }, [history, filters, props.value]);

  useEffect(() => {
    createSocketConnection();
    window.Echo.channel("admin-updates").listen("OrderUpdated", function (e) {
      console.log(e);
      let findInd = ordersData.findIndex((o) => o.id == e.order?.id);
      if (findInd > -1) {
        e.order.checked = false;
        ordersData[findInd] = e.order;
        setOrderData([...ordersData] || []);
      }
    });
    window.Echo.channel("admin-updates").listen("OrderPlaced", function (e) {
      console.log(e);
      let findInd = ordersData.findIndex((o) => o.id == e.order?.id);
      if (findInd > -1) {
        e.order.checked = false;
        ordersData[findInd] = e.order;
        setOrderData([...ordersData] || []);
      }
      else {
        e.order.checked = false;
        setOrderData([...[e.order], ...ordersData]);
      }
    });
    return () => {
      window.Echo.leaveChannel(`admin-updates`);
    };
  }, [ordersData, orderStatusData]);

  const handleChangePage = (pageUrl) => {
    console.log(pageUrl);
    var NewPageNo = pageUrl.substr(pageUrl.lastIndexOf("=") + 1);
    setFilters({ ...filters, page: NewPageNo });
  };
  const handleChangeStatus = (oStatus) => {
    console.log(oStatus);
    setFilters({ ...filters, page: "1", order_status: oStatus });
  };

  const handleChange = (id) => {
    let checkedArray = [];

    let currentChecked = [...ordersData];
    currentChecked.map((checkbox, index) => {
      if (checkbox.id === id) {
        checkbox.checked = !checkbox.checked;
      }
      return checkbox;
    });
    setOrderData(currentChecked);

    const uncheckedItems = ordersData.filter((item) => item.checked === false);
    console.log(uncheckedItems);

    setCheckedAll(uncheckedItems.length === 0 ? true : false);

    const checkedItems = ordersData.filter((c) => c.checked === true);
    checkedItems.map((c) => {
      checkedArray.push(c.id);
    });
    console.log(checkedArray);
    setCheckedIds(checkedArray);
  };

  return (
    <div className={props.type ? "" : "mainInnerView"}>
      {!props.type ? (
        <React.Fragment>
          <h1>
            <Assignment /> Orders
          </h1>
          <Link to={{ pathname: "/orderCreate", state: 'order' }}>
            <GButton
              variant="condensed"
              style={{
                marginTop: "-55px",
                float: "right",
              }}
              children="Add new order"
            />
          </Link>
        </React.Fragment>
      ) : (
        ""
      )}

      {!props.type ? (
        <React.Fragment>
          <OrderStatuses
            statusList={orderStatusData}
            onClickStatus={handleChangeStatus}
            defaultStatus={filters.order_status}
            currencySymbol={settings.currency_symbol}
          />

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              marginBottom: "15px",
              gap: "10px",
            }}
          >
            {/* <DriverFilter
              value={filters.agent_id}
              handleDriverChange={(value) => {
                setFilters({ ...filters, agent_id: value || "", page: 1 });
              }}
            /> */}
            <CustomerFilter
              value={filters.customer_id}
              handleCustomerChange={(value) => {
                setFilters({ ...filters, customer_id: value || "", page: 1 });
              }}
            />

            <DateFilterStyles>
              <div className="from">
                <DatePicker
                  selectsRange={true}
                  startDate={startDate ? moment(startDate).toDate() : startDate}
                  endDate={endDate ? moment(endDate).toDate() : endDate}
                  // endDate={endDate}
                  onChange={(update) => {
                    console.log(update);
                    setDateRange(update);
                    if (update[0] && update[1]) {
                      setFilters({
                        ...filters,
                        date_from: moment(update[0]).format("YYYY-MM-DD"),
                        date_to: moment(update[1]).format("YYYY-MM-DD"),
                        page: 1,
                      });
                    }

                    if (!update[0] && !update[1]) {
                      setFilters({
                        ...filters,
                        date_from: "",
                        date_to: "",
                      });
                    }
                  }}
                  isClearable={true}
                  placeholderText={"Select Scheduled Date"}
                  dateFormat="dd MMM, yyyy"
                  utcOffset={0}
                />
              </div>
            </DateFilterStyles>

            {/* <GSorting
              defaultvalue={filters.orderby}
              defaultOrdervalue={filters.order}
              data={orderSorting}
              id="sortBy"
              option_key="title"
              option_value="value"
              onChange={(newVal) => {
                setFilters({
                  orderby: newVal,
                  order_status: filters.order_status,
                });
              }}
              style={{
                height: "40px",
                marginRight: "10px",
                minWidth: "150px",
              }}
              onOrderChange={(newVal) => {
                setFilters({
                  order: newVal,
                  order_status: filters.order_status,
                });
              }}
            /> */}

            {ordersData.length > 0 ? (
              <>
                <GPagination
                  style={{ display: "inline-block", marginLeft: "auto" }}
                  count={orderPaginationData.total}
                  from={orderPaginationData.from}
                  to={orderPaginationData.to}
                  onChangeNextPage={() =>
                    handleChangePage(orderPaginationData.next_page_url)
                  }
                  onChangePrevPage={() =>
                    handleChangePage(orderPaginationData.prev_page_url)
                  }
                  rowsPerPage={orderPaginationData.per_page}
                  prevDisabled={
                    orderPaginationData.prev_page_url ? false : true
                  }
                  nextDisabled={
                    orderPaginationData.next_page_url ? false : true
                  }
                ></GPagination>
                {orderPaginationData ? (
                  <GPaginationSelect
                    defaultvalue={filters.page}
                    totalPage={orderPaginationData.last_page}
                    onChangePage={(NewPageNo) =>
                      setFilters({ ...filters, page: NewPageNo })
                    }
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            <GButton
              variant="outlined"
              children="Bulk Upload"
              onClick={() => {
                setOUploadVisible(true);
              }}
              style={{
                marginLeft: "8px",
                padding: "0.5rem 0.8rem ",
              }}
            />

            {checkedIds.length > 0 && (
              <MultipleOrderActionView
                data={checkedIds}
                onUpdatePaymentStatus={() => {
                  getOrders();
                }}
              />
            )}
          </div>
        </React.Fragment>
      ) : (
        ""
      )}

      <div
        className="listPaper"
        style={{ marginTop: props.type ? "" : "10px" }}
      >
        {ordersData.length !== 0 && props.type ? (
          <GPagination
            count={orderPaginationData.total}
            from={orderPaginationData.from}
            to={orderPaginationData.to}
            onChangeNextPage={() =>
              handleChangePage(orderPaginationData.next_page_url)
            }
            onChangePrevPage={() =>
              handleChangePage(orderPaginationData.prev_page_url)
            }
            rowsPerPage={orderPaginationData.per_page}
            prevDisabled={orderPaginationData.prev_page_url ? false : true}
            nextDisabled={orderPaginationData.next_page_url ? false : true}
          ></GPagination>
        ) : (
          ""
        )}

        {!orderPaginationData ? (
          <GTableShimmer></GTableShimmer>
        ) : (
          <TableContainer>
            {ordersData.length === 0 ? (
              <GEmpty></GEmpty>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    {!props.type && (
                      <TableHeader className="light">
                        <input
                          type="checkbox"
                          checked={checkedAll}
                          onChange={() => {
                            setCheckedAll(!checkedAll);
                            let checkedIdArray = [];
                            let a = ordersData.map((d) => {
                              if (checkedAll === false) {
                                d.checked = true;
                                checkedIdArray.push(d.id);
                              }
                              if (checkedAll === true) {
                                d.checked = false;
                                checkedIdArray.splice(d.id, 1);
                              }
                              return d;
                            });
                            console.log(checkedIdArray);
                            setCheckedIds(checkedIdArray);
                            setOrderData([...a]);
                          }}
                        />
                      </TableHeader>
                    )}
                    {columns
                      .filter((f) => {
                        if (props.type) {
                          return f.id != "checkbox";
                        } else {
                          return f;
                        }
                      })
                      .filter((column) => column.type !== props.title)
                      .map((c) => {
                        return (
                          <TableHeader
                            key={c.id}
                            style={{
                              minWidth: c.minWidth,
                              maxWidth: c.maxWidth,
                              width: c.width,
                              textAlign: c.align,
                              zIndex: "8",
                            }}
                          >
                            {c.label}
                          </TableHeader>
                        );
                      })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ordersData.map((s, i) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={s.id}>
                        {!props.type && (
                          <TableRowData>
                            <input
                              type="checkbox"
                              id="orderCheck"
                              name={"check" + i}
                              checked={s.checked}
                              onChange={() => {
                                handleChange(s.id);
                              }}
                            />{" "}
                          </TableRowData>
                        )}
                        <TableRowData>
                          <Link
                            to={{ pathname: "/order/" + s.id, state: "order" }}
                          >
                            #{s?.slug || s.id}
                          </Link>
                        </TableRowData>
                        {props.title === "customer_id" ? (
                          ""
                        ) : (
                          <TableRowData style={{ textAlign: "center" }}>
                            {s.customer ? (
                              <Link
                                to={{
                                  pathname:
                                    "/customer/" + s.customer.id + "/orders",
                                  state: "customer",
                                }}
                              >
                                {s.customer.title}
                              </Link>
                            ) : (
                              "-"
                            )}
                          </TableRowData>
                        )}
                        <TableRowData style={{ textAlign: "center" }}>
                          <span
                            className="orderStatus"
                            style={{
                              opacity: "1",
                              color: "#" + s.status.color1,
                              background: "#" + s.status.color2,
                            }}
                          >
                            {s.status.title}
                          </span>
                        </TableRowData>
                        {/* {props.title === "driver_id" ? (
                          ""
                        ) : (
                          <TableRowData
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {s.agent ? s?.agent?.title : "-"}
                          </TableRowData>
                        )} */}
                        {/* <TableRowData style={{ textAlign: "center" }}>-</TableRowData> */}
                        <TableRowData style={{ textAlign: "center" }}>
                          <span
                            className="orderStatus"
                            style={{
                              opacity: "1",
                              color: "#" + s?.current_task?.status_display?.color1,
                              background: "#" + s?.current_task?.status_display?.color2,
                            }}
                          >
                            {s?.current_task?.status_display?.title}
                          </span>
                        </TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>{s?.tasks?.find(t => t.type == 'delivery')?.location_name}</TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>{s?.schedule_time}</TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>{s?.pending_payment ? 'PENDING' : '-'}</TableRowData>
                        <TableRowData style={{ textAlign: "center" }}>
                          {settings.currency_symbol}
                          {s.amount_display}
                        </TableRowData>
                        {/* <TableRowData style={{ textAlign: "center" }}>
                              {s.pickup_time ? s.pickup_time : "-"}
                            </TableRowData>
                            <TableRowData style={{ textAlign: "center" }}>
                              {s.delivery_time ? s.delivery_time : "-"}
                            </TableRowData> */}

                        {/* <TableRowData style={{ textAlign: "center" }}>
                          {s?.pending_payment
                            ? settings.currency_symbol + s?.pending_payment
                            : "-"}
                        </TableRowData> */}
                        <TableRowData style={{ textAlign: "center" }}>
                          {s.created}
                        </TableRowData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        )}
      </div>

      <Modal
        isOpen={oUploadVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOUploadVisible(false)}
        style={{
          content: {
            width: "420px",
          },
        }}
        contentLabel="Bulk Order Upload Form Modal"
      >
        <OrderBulkUploadForm
          oUploadVisible={oUploadVisible}
          setOUploadVisible={setOUploadVisible}
          onSubmit={getOrders}
        />
      </Modal>
    </div>
  );
}
