import React, { useState } from "react";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
import GTaxiMap from "./orderStopsMap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";
import GText from "../gComponents/gText";
import GView from "../gComponents/GView";

function getIOSVersion() {
  var match = window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
  console.log(match);
  return match ? match[1] : undefined;
}

const addressTitle = [
  {
    key: "RESIDENTIAL",
    value: "RESIDENTIAL",
  },
  {
    key: "BUSINESS",
    value: "BUSINESS",
  },
];

const handleChange = (d) => {
  // var date = new Date(d).getDate(); //Current Date
  var date = (new Date(d).getDate() < 10 ? "0" : "") + new Date(d).getDate();
  // var month = new Date(d).getMonth() + 1; //Current Month
  var month =
    (new Date(d).getMonth() + 1 < 10 ? "0" : "") + (new Date(d).getMonth() + 1);
  var year = new Date(d).getFullYear(); //Current Year
  var hour = ("0" + new Date(d).getHours()).slice(-2); //Current Year
  var minute = ("0" + new Date(d).getMinutes()).slice(-2); //Current Year
  var second = ("0" + new Date(d).getSeconds()).slice(-2); //Current Year
  console.log(
    year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second
  );
  return (
    year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second
  );
};

export default function CreateAddress(props) {
  // console.log(props.selectedLocation)
  const [addressLocation] = useState(
    !props.isEmpty
      ? {
          lat: parseFloat(props.selectedLocation.lat),
          lng: parseFloat(props.selectedLocation.lng),
        }
      : { lat: 30.7046, lng: 76.7179 }
  );
  const initialValues = {
    title: props.selectedLocation.title || "RESIDENTIAL",
    location_name: props.selectedLocation.location_name || "",
    line1: props.selectedLocation.line1 || "",
    line2: props.selectedLocation.line2 || "",
    formatted_display: props.selectedLocation?.formatted_display || "",
    rec_name: props.selectedLocation.rec_name || "",
    rec_phone: props.selectedLocation.rec_phone || "",
    rec_email: props.selectedLocation.rec_email || "",
    email_notify: props.selectedLocation.email_notify || 0,
    phone_notify: props.selectedLocation.phone_notify || 0,
    lat: props.selectedLocation.lat || "",
    lng: props.selectedLocation.lng || "",
    notes:
      props.selectedLocation?.notes ||
      (props.currentIndType == "pickup"
        ? props.selectedLocation.pickup_notes||
          ""
        : props.currentIndType == "delivery"
        ? props.selectedLocation.delivery_notes ||
          ""
        : ""),
    city: props.selectedLocation.city || "",
    state: props.selectedLocation.state || "",
    country: props.selectedLocation.country || "",
    zipcode: props.selectedLocation.zipcode || "",
    complete_before: props.selectedLocation?.complete_before
      ? new Date(props.selectedLocation?.complete_before)
      : null,
    save: props.selectedLocation?.save || 0,
  };

  const onSubmit = (values) => {
    let sendData = { ...values };
    sendData.complete_before = sendData.complete_before
      ? handleChange(sendData.complete_before)
      : "";
    console.log(sendData);
    props.onSubmit(sendData);
    props.setOrderLocVisible(false);
  };

  const validationSchema = Yup.object({
    line1: Yup.string().required("Required"),
    location_name: Yup.string().required("Required"),
    rec_name: Yup.string().required("Required"),
    rec_email: Yup.string().required("Required"),
    rec_phone: Yup.string()
      .min("10", "Phone should be atleast 10 didgits")
      .required("Required"),
  });

  if (props.orderLocVisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setOrderLocVisible(false)}
        />
        Confirm Location
      </div>
      <div
        className="modal-body"
        style={{
          padding: "0 20px",
          maxHeight: "calc(100vh - 80px)",
          overflowX: "hidden",overflowY:"auto"
        }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <br />
              <GView style={{ padding: "0 15px" }}>
                <GTaxiMap
                  center={addressLocation}
                  zoom={16}
                  places={[]}
                  isAutoComplete={true}
                  style={{ height: "300px", position: "relative" }}
                  onChange={(address, loc) => {
                    values.lat = address.lat;
                    values.lng = address.lng;
                    values.line1 = address.line1;
                    values.formatted_display = address.formatted_display;
                    values.city = address.city;
                    values.state = address.state;
                    values.country = address.country;
                    values.zipcode = address.zipcode;
                    setFieldValue("line1", address.line1);
                  }}
                />
              </GView>
              <FormikControl
                control="radio"
                name="title"
                options={addressTitle}
                key_title="key"
                selected={values.title}
                key_value="value"
                icons={true}
                style={{ display: "none" }}
                divstyle={{
                  display: "inline-block",
                  width: "50%",
                  border: "1px solid #d2d2d2",
                  textAlign: "center",
                  padding: "5px",
                  boxSizing: "border-box",
                  cursor: "pointer",
                }}
                labelStyle={{
                  boxSizing: "border-box",
                  fontSize: "14px",
                  margin: "3px 0",
                }}
              />
              <FormikControl
                control="input"
                type="text"
                placeholder="House / Flat / block No. *"
                name="formatted_display"
                className="noBorder"
                disabled={true}
              />
               <FormikControl
                control="input"
                type="text"
                placeholder="House / Flat / block No. *"
                name="line1"
                className="noBorder"
                divstyle={{display:"none"}}
              />
              <FormikControl
                control="input"
                type="text"
                placeholder="Landmark / Extra Details"
                name="line2"
                className="noBorder"
              />
              <GText
                g6
                med
                semi
                text="Other Details"
                style={{
                  padding: "15px 15px 10px",
                  textTransform: "uppercase",
                  fontSize: "14px",
                }}
              />
              <FormikControl
                control="input"
                type="text"
                label="Location Name*"
                name="location_name"
                style={{ height: "40px" }}
              />
              <FormikControl
                control="input"
                type="text"
                label="Contact Name*"
                name="rec_name"
                onChange={(event) => {
                  const re = /^[A-Za-z \b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("rec_name", event.target.value);
                  }
                }}
                divstyle={{
                  width: "100%",
                  display: "inline-block",
                  boxSizing: "border-box",
                  verticalAlign: "top",
                }}
              />
              <FormikControl
                control="input"
                type="text"
                label="Contact Phone*"
                name="rec_phone"
                maxLength={13}
                onChange={(event) => {
                  const re = /^[0-9+\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("rec_phone", event.target.value);
                  }
                }}
                // divstyle={{
                //   width: "50%",
                //   display: "inline-block",
                //   boxSizing: "border-box",
                //   verticalAlign: "top",
                // }}
              />
              <span
                style={{
                  position: "relative",
                  display: "flex",
                  padding: "0 15px 10px",
                }}
              >
                <input
                  type="checkbox"
                  id="phone_notify"
                  name="phone_notify"
                  value={values.phone_notify == 1 ? true : false}
                  checked={values.phone_notify == 1 ? true : false}
                  onChange={() => {
                    let checked = values.phone_notify == 0 ? 1 : 0;
                    setFieldValue("phone_notify", checked);
                  }}
                  style={{ margin: "1px 8px 0 0" }}
                ></input>
                <span
                  style={{ marginTop: "-3px", fontSize: "13px", color: "#555" }}
                >
                  Check to enable mobile notifications
                </span>
              </span>
              <FormikControl
                control="input"
                type="text"
                label="Contact Email*"
                name="rec_email"
                // divstyle={{
                //   width: "50%",
                //   display: "inline-block",
                //   boxSizing: "border-box",
                //   verticalAlign: "top",
                // }}
              />
              <span
                style={{
                  position: "relative",
                  display: "flex",
                  padding: "0 15px 10px",
                }}
              >
                <input
                  type="checkbox"
                  id="email_notify"
                  name="email_notify"
                  value={values.email_notify == 1 ? true : false}
                  checked={values.email_notify == 1 ? true : false}
                  onChange={() => {
                    let echecked = values.email_notify == 0 ? 1 : 0;
                    setFieldValue("email_notify", echecked);
                  }}
                  style={{ margin: "1px 8px 0 0" }}
                ></input>
                <span
                  style={{ marginTop: "-3px", fontSize: "13px", color: "#555" }}
                >
                  Check to enable email notifications
                </span>
              </span>
              <FormikControl
                control="toggle"
                label="Save to Address Book"
                isToggled={values.save}
                name="save"
                onToggle={() => {
                  setFieldValue("save", values.save == 1 ? 0 : 1);
                }}
              />
              <FormikControl
                control="input"
                as="textarea"
                label="Notes"
                name="notes"
                maxLength={140}
              />
              <br />
              <GButton
                variant="condensed"
                children={"Confirm Location"}
                type="submit"
                style={{
                  width: "calc(100% - 30px)",
                  margin: "0 15px 15px",
                  borderRadius: "2px",
                  fontWeight: "550",
                }}
              />{" "}
              {getIOSVersion() >= 14 ? (
                <div style={{ height: "80px" }}></div>
              ) : (
                ""
              )}
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
}
