import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
} from "../styledComponents/tableStyle";
import GEditable from "../gComponents/gEditable";
import GText from "../gComponents/gText";

export default function TimeslotSettings() {
  const [timeslotSettingsData, setTimeslotSettingsData] = useState({
    later_discount: {},
    later_duration: {},
    today_discount: {},
    today_duration: {},
  });
  const [openSnackbar] = useSnackbar();

  const getTimeslotSettings = () => {
    ApiService({ method: "GET", route: "setting/timeslot" })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          setTimeslotSettingsData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTimeslotSettings();
  }, []);

  function updateField(newValue, selectedFieldId, keyTitle) {
    console.log(newValue);
    console.log(selectedFieldId);
    updateSetting(newValue, selectedFieldId, keyTitle);
  }
  function updateSetting(newValue, selectedFieldId, keyTitle) {
    ApiService({
      method: "PUT",
      route: "setting/" + selectedFieldId,
      body: {
        key_value: newValue,
      },
    })
      .then((response) => {
        console.log(JSON.stringify(response));
        if (response.data.status_code === 1) {
          if (keyTitle) {
            timeslotSettingsData[keyTitle] = response.data.data;
            setTimeslotSettingsData({ ...timeslotSettingsData });
          }
        } else {
          getTimeslotSettings();
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="rightInnerView">
      <GText
        g2
        bold
        text={"Timeslot Settings"}
        style={{ marginBottom: "20px" }}
      />
      <div className="listPaper">
        <Table>
          <TableBody>
            {timeslotSettingsData.later_discount?.id && (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableRowData>
                  {timeslotSettingsData.later_discount.key_display_title}
                </TableRowData>
                <TableRowData style={{ width: "200px", padding: "0" }}>
                  <GEditable
                    type="int"
                    title={false}
                    editable={false}
                    defaultValue={timeslotSettingsData.later_discount.key_value}
                    id={timeslotSettingsData.later_discount.id}
                    onSubmit={(newVal, id) => {
                      timeslotSettingsData.later_discount.key_value = newVal;
                      setTimeslotSettingsData(timeslotSettingsData);
                      updateField(newVal, id, "later_discount");
                    }}
                    style={{ margin: "0px 0 10px", width: "80%" }}
                    inputStyle={{ minWidth: "80px", width: "60%" }}
                  />
                </TableRowData>
              </TableRow>
            )}

            {timeslotSettingsData.later_duration?.id && (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableRowData>
                  {timeslotSettingsData.later_duration.key_display_title}
                </TableRowData>
                <TableRowData style={{ width: "200px", padding: "0" }}>
                  <GEditable
                    type="int"
                    title={false}
                    editable={false}
                    defaultValue={timeslotSettingsData.later_duration.key_value}
                    id={timeslotSettingsData.later_duration.id}
                    onSubmit={(newVal, id) => {
                      timeslotSettingsData.later_duration.key_value = newVal;
                      setTimeslotSettingsData(timeslotSettingsData);
                      updateField(newVal, id, "later_duration");
                    }}
                    style={{ margin: "0px 0 10px", width: "80%" }}
                    inputStyle={{ minWidth: "80px", width: "60%" }}
                  />
                </TableRowData>
              </TableRow>
            )}

            {timeslotSettingsData.today_discount?.id && (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableRowData>
                  {timeslotSettingsData.today_discount.key_display_title}
                </TableRowData>
                <TableRowData style={{ width: "200px", padding: "0" }}>
                  <GEditable
                    type="int"
                    title={false}
                    editable={false}
                    defaultValue={timeslotSettingsData.today_discount.key_value}
                    id={timeslotSettingsData.today_discount.id}
                    onSubmit={(newVal, id) => {
                      timeslotSettingsData.today_discount.key_value = newVal;
                      setTimeslotSettingsData(timeslotSettingsData);
                      updateField(newVal, id, "today_discount");
                    }}
                    style={{ margin: "0px 0 10px", width: "80%" }}
                    inputStyle={{ minWidth: "80px", width: "60%" }}
                  />
                </TableRowData>
              </TableRow>
            )}

            {timeslotSettingsData.today_duration?.id && (
              <TableRow hover role="checkbox" tabIndex={-1}>
                <TableRowData>
                  {timeslotSettingsData.today_duration.key_display_title}
                </TableRowData>
                <TableRowData style={{ width: "200px", padding: "0" }}>
                  <GEditable
                    type="int"
                    title={false}
                    editable={false}
                    defaultValue={timeslotSettingsData.today_duration.key_value}
                    id={timeslotSettingsData.today_duration.id}
                    onSubmit={(newVal, id) => {
                      timeslotSettingsData.today_duration.key_value = newVal;
                      setTimeslotSettingsData(timeslotSettingsData);
                      updateField(newVal, id, "today_duration");
                    }}
                    style={{ margin: "0px 0 10px", width: "80%" }}
                    inputStyle={{ minWidth: "80px", width: "60%" }}
                  />
                </TableRowData>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
