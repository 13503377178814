import React, { useState, useEffect } from "react";
import { ImgUrl, ApiService } from "../services";
import GEditable from "../gComponents/gEditable";
import GImageEditor from "../gComponents/gImageEditor";
import { useSnackbar } from "react-simple-snackbar";

export default function InvoiceSettings(props) {
  const [openSnackbar] = useSnackbar();
  const [linksData, setLinksData] = useState({
    invoice_logo: {},
    invoice_header_text: {},
    invoice_footer_text: {},
  });

  useEffect(() => {
    getSocialSettings();
  }, []);

  const getSocialSettings = () => {
    ApiService({ method: "GET", route: "setting/invoice" })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setLinksData(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function updateField(newValue, selectedFieldId, field) {
    console.log(newValue);
    console.log(selectedFieldId);
    // return;
    var send_data = {
      key_value: newValue,
    };
    var hitUrl = "setting/" + selectedFieldId;
    ApiService({ method: "PUT", route: hitUrl, body: send_data })
      .then((response) => {
        console.log(response);
        if (response.data.status_code === 1) {
          //getSocialSettings();
          linksData[field] = response.data.data;
        }
        openSnackbar(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.

  return (
    <div className="rightInnerView">
      <h2>Invoice Settings</h2>

      <div className="listPaper">
        <div
          style={{
            display: "inline-block",
            width: "225px",
            verticalAlign: "top",
            boxSizing: "border-box",
            padding: "20px",
          }}
        >
          {linksData.invoice_logo ? (
            <div>
              <label style={{ display: "block", marginBottom: "10px" }}>
                Invoice Logo
              </label>
              <div
                style={{
                  background: "#f4f5f9",
                  padding: "10px",
                  display: "inline-block",
                }}
              >
                <GImageEditor
                  image={
                    ImgUrl("logo") + "/" + linksData.invoice_logo.key_value
                  }
                  //onSubmit={updateField}
                  onSubmit={(newValue, selectedFieldId) =>
                    updateField(newValue, selectedFieldId, "invoice_logo")
                  }
                  id={linksData.invoice_logo.id}
                  type="logo"
                  isPreviewImg={true}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          style={{
            display: "inline-block",
            width: "calc(100% - 500px)",
            minWidth: "350px",
            maxWidth: "700px",
            padding: "20px",
            boxSizing: "border-box",
          }}
        >
          <GEditable
            key={linksData.invoice_header_text.id}
            type="text"
            title={true}
            editable={false}
            defaultValue={linksData.invoice_header_text.key_value}
            className={props.display}
            placeholder={linksData.invoice_header_text.key_display_title}
            id={linksData.invoice_header_text.id}
            onSubmit={(newValue, selectedFieldId) =>
              updateField(newValue, selectedFieldId, "invoice_header_text")
            }
            style={{ margin: "10px 0 20px" }}
            required={true}
          />

          <GEditable
            key={linksData.invoice_footer_text.id}
            type="text"
            title={true}
            editable={false}
            defaultValue={linksData.invoice_footer_text.key_value}
            className={props.display}
            placeholder={linksData.invoice_footer_text.key_display_title}
            id={linksData.invoice_footer_text.id}
            onSubmit={(newValue, selectedFieldId) =>
              updateField(newValue, selectedFieldId, "invoice_footer_text")
            }
            style={{ margin: "10px 0 20px" }}
            required={true}
          />
        </div>
      </div>
    </div>
  );
}
