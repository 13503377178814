import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ImgUrl, addDefaultSrc } from "../services";
import { Avatar } from "@material-ui/core";
import GImageEditor from "./gImageEditor";
import { Link } from "react-router-dom";

const ItemImage = styled.div`
  margin-right: 15px;
  .MuiAvatar-root {
    height: 50px;
    width: 50px;
  }
  &.headerInfo {
    .MuiAvatar-root {
      height: 50px;
      width: 50px;
      text-transform: uppercase;
      background: #0381FC;
      color: #fff;
    }
    .MuiAvatar-fallback {
      width: 85%;
      height: 85%;
    }
  }
`;

const ItemText = styled.div`
  width: 70%;

  h4 {
    color: #0381FC;
    font-size: 16px;
    margin: 5px 0;
    font-weight: 400;
  }
  p {
    color: #333;
    font-size: 15px;
    margin: 0px;
    line-height: 20px;
  }

  &.headerInfo {
    h4 {
      color: #000;
      font-size: 20px;
      font-weight: 500;
    }
  }
`;

function GInfo(props) {
  const [infoData, setInfoData] = useState({});
  const handleUpdate = (newValue, id, thumbValue) => {
    props.uploadImage(newValue, id, thumbValue);
  };
  useEffect(() => {
    setInfoData(props);
    //  props.onChange(value)
    // action on update of movies
  }, [props]);

  return (
    <div
      style={Object.assign(
        {},
        { display: "inline-flex", width: "100%" },
        props.style
      )}
    >
      <ItemImage className={infoData.className}>
        {infoData.imgEdit === true ? (
          <GImageEditor
            image={ImgUrl(infoData.imgType) + "/" + infoData.photo}
            alt={infoData.title}
            onSubmit={handleUpdate}
            id={infoData.id}
            type={infoData.imgType}
            isPreviewImg={true}
          />
        ) : infoData.photo ? (
          <img
            alt={infoData.title}
            src={
              infoData.imgType
                ? ImgUrl(infoData.imgType) + "/" + infoData.photo
                : infoData.photo
            }
            style={{
              height: "45px",
              width: "45px",
              borderRadius: "50%",
            }}
            onError={(e) => addDefaultSrc(e, infoData.imgType)}
          />
        ) : (
          <Avatar
            alt={infoData.title}
            src={ImgUrl(infoData.imgType) + "/" + infoData.photo}
          />
        )}
      </ItemImage>
      <ItemText className={infoData.className}>
        {infoData.link ? (
          <Link to={{ pathname: infoData.link, state: infoData.linkState }}>
            <h4>{infoData.title}</h4>
          </Link>
        ) : (
          <h4>{infoData.title}</h4>
        )}

        {infoData.subtitle && <p>{infoData.subtitle}</p>}
        {infoData.subtitle2 ? <p>{infoData.subtitle2}</p> : ""}
      </ItemText>
    </div>
  );
}

export default GInfo;
