import React from "react";
import styled from "styled-components";

const TaskDiv = styled.div`
  position: relative;
  svg {
    /* margin-top: 5px; */
  }
  & h4 {
    font-size: 16px;
    margin: 2px 0;
    font-weight: 400;
  }
  & p {
    font-size: 15px;
    margin: 0;
    font-weight: 400;
    color: #333;
    line-height: 22px;
  }

  h5 {
    font-weight: 440;
    font-style: italic;
    color: #777;
    margin: 10px 0;
  }
`;
const PickupDiv = styled.div`
  display: inline-flex;
  vertical-align: top;
  width: calc(100% - 175px);
  flex-direction: column;
  /* &:after {
    content: " ";
    position: absolute;
    display: block;
    top: 18px;
    left: 12px;
    width: 1px;
    transform: scale(1, 2);
    transform-origin: 50% -100%;
    background-color: rgba(0, 0, 0, 0.25);
    min-height: 15px;
    max-height: 20px;
  } */
  div {
    display: inline-block;
    /* padding-left: 0px; */
    width: 90%;
    margin-bottom: 10px;
    vertical-align: top;
    /* padding-bottom: 10px; */
    box-sizing: border-box;
  }
`;
const DeliveryDiv = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 95%;
  div {
    display: inline-block;
    padding-left: 10px;
    width: 90%;
    vertical-align: top;
    padding-bottom: 10px;
  }
`;

export default function gTaskView(props) {
  console.log(props);
  return (
    <TaskDiv>
      <h5>Address</h5>
      <PickupDiv>
        {/* <h4>{props.storeTitle}</h4> */}
        <p>
          {props.task?.title && (
            <span style={{ fontWeight: "550" }}>{`${props?.task?.location_name} (${props.task?.title})`} - </span>
          )}
          {props.task.address}
        </p>
        {props.task.notes && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "10px 0 5px",
            }}
          >
            <h5 style={{ margin: "0" }}>Notes : &nbsp;</h5>
            <p
              style={{
                color: "#333",
                fontSize: "14px",
              }}
            >
              {props.task.notes}
            </p>
          </div>
        )}
      </PickupDiv>

      <div
        style={{ display: "inline-block", float: "right", marginTop: "-35px" }}
      >
        <h5 style={{ margin: "5px 0" }}>Recipient Info</h5>
        <div style={{ background: "#f4f5f9", padding: "5px 10px" }}>
          <p>
            <small>Name : {props.task.rec_name}</small>
          </p>
          <p>
            <small>Phone : {props.task.rec_phone || "-"}</small>
          </p>
          <p>
            <small>Email : {props.task.rec_email || "-"}</small>
          </p>
        </div>
      </div>
      {/* <DeliveryDiv>

        <div>
          {/* <h4>Home</h4> *
      <p>{props.task.address}</p>
        </div>
      </DeliveryDiv > */}
    </TaskDiv>
  );
}
