import React, { useState } from "react";

export function ReadMore({ children }) {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p>
      {isReadMore ? text.slice(0, 50) : text}
      <span
        onClick={(e) => {
          e.stopPropagation();
          toggleReadMore();
        }}
        style={{ color: "#6a6a6a" }}
      >
        {text.length > 50 ? (isReadMore ? "...more" : "") : ""}
      </span>
    </p>
  );
}
