import React, { useEffect, useState } from "react";
import { ApiService } from "../services";
import { Remove } from "@material-ui/icons";
import GButton from "../gComponents/gButton";
import { Formik, Form, Field, FieldArray } from "formik";
import FormikControl from "../formikComponents/formikControl";
import * as Yup from "yup";
import { useSnackbar } from "react-simple-snackbar";
import GConfirmDialog from "../gComponents/gConfirmDialog";

const fieldStyle = {
  border: "0px",
  borderBottom: "1px solid #d2d2d2",
  background: "transparent",
};
function ExistForm(props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  console.log(props);
  const [openSnackbar] = useSnackbar();
  const [fieldsData, setFieldData] = useState([]);

  useEffect(() => {
    document.getElementById("resetButton").click();
    setFieldData(props.data);
    initialValues.fields = props.data;
  }, [props.data]);

  const initialValues = {
    fields: props.data,
  };

  // let validationField = Yup.array().of(
  //   Yup.object({
  //     title: Yup.string().required("Required"), // these constraints take precedence
  //     type: Yup.string().required("Required"), // these constraints take precedence,
  //   })
  // );

  // if (
  //   validationField.type === "selectbox" ||
  //   validationField.type === "priceoptions"
  // ) {
  //   validationField.data.options = Yup.array().of(
  //     Yup.object({
  //       title: Yup.string().required("Required"),
  //     })
  //   );
  // }

  // const validationSchema = Yup.object({ fields: validationField });

  const validationSchema = Yup.object({
    fields: Yup.array()
      .of(
        Yup.object({
          title: Yup.string().required("Required"), // these constraints take precedence
          type: Yup.string().required("Required"), // these constraints take precedence,
          //   options: Yup.array().of(
          //     Yup.object({
          //       title:
          //         type === "selectbox" && type === "priceoptions"
          //           ? Yup.string().required("Required")
          //           : "",
          //     })
          //   ),
        })
      )
      .required("required"),
  });

  const handleUpdateVFields = (values, ind) => {
    console.log(ind);
    console.log(values.fields[ind]);
    console.log(fieldsData[ind]);

    let sendData = { ...values.fields[ind] };

    sendData.data =
      sendData.type === "selectbox" || sendData.type === "priceoptions"
        ? sendData.data
        : {};
    //return;
    ApiService({
      method: "PUT",
      route: "form-fields/" + sendData.id,
      body: sendData,
    }).then((response) => {
      console.log(response.data);
      if (response.data.status_code === 1) {
        // let r = response.data[0].options || [];
        // if (
        //   fieldsData[ind].type == "selectbox" ||
        //   fieldsData[ind].type === "priceoptions"
        // ) {
        // } else {
        //   response.data.data = { fields: [] };
        // }
        fieldsData[ind] = { ...response.data.data };

        setFieldData(fieldsData);
      }
      openSnackbar(response.data.message);
    });
  };

  return (
    <div style={props.style}>
      <Formik
        initialValues={initialValues}
        //onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, resetForm }) => (
          <Form>
            <FieldArray name="fields">
              {(fieldArrayProps) => {
                console.log(fieldArrayProps);
                const { remove, form } = fieldArrayProps;
                const { values } = form;
                const { fields } = values;

                console.log(fields);

                const handleUpdateVField = (values, ind) => {
                  console.log(ind);
                  console.log(values.fields[ind]);
                  console.log(fields[ind]);

                  let sendData = { ...values.fields[ind] };

                  sendData.data =
                    sendData.type === "selectbox" ||
                    sendData.type === "priceoptions"
                      ? sendData.data
                      : {};
                  //return;
                  ApiService({
                    method: "PUT",
                    route: "form-fields/" + sendData.id,
                    body: sendData,
                  }).then((response) => {
                    console.log(response.data);
                    if (response.data.status_code === 1) {
                      // let r = response.data[0].options || [];
                      // if (
                      //   fieldsData[ind].type == "selectbox" ||
                      //   fieldsData[ind].type === "priceoptions"
                      // ) {
                      // } else {
                      //   response.data.data = { fields: [] };
                      // }
                      fieldsData[ind] = { ...response.data.data };

                      setFieldData(fieldsData);
                    }
                    openSnackbar(response.data.message);
                    return;
                  });
                };

                const handleDeleteField = (ind) => {
                  if (
                    window.confirm(
                      "Are you sure you want to delete this field?"
                    )
                  ) {
                    ApiService({
                      method: "DELETE",
                      route: "form-fields/" + values.fields[ind].id,
                    }).then((response) => {
                      console.log(response.data);
                      if (response.data.status_code === 1) {
                        remove(ind);
                        setFieldData(fieldsData.splice(ind, 1));
                        props.onFieldRemove(ind);
                      }
                      openSnackbar(response.data.message);
                    });
                  }
                };

                return (
                  <React.Fragment>
                    <div
                      style={{
                        display: "inline-flex",
                        width: "100%",
                        boxSizing: "border-box",
                        padding: "0 10px",
                      }}
                    >
                      <label style={{ width: "40%" }}>Title</label>
                      <label style={{ width: "25%" }}>Type</label>
                      <label style={{ width: "15%", textAlign: "center" }}>
                        Required
                      </label>
                      <label style={{ width: "20%", textAlign: "center" }}>
                        Actions
                      </label>
                    </div>

                    {fields.map((f, index) => {
                      return (
                        <div
                          style={{
                            boxSizing: "border-box",
                            padding: "10px",
                            background: "#f4f5f9",
                            margin: "10px 0",
                          }}
                        >
                          <div
                            key={index}
                            style={{
                              position: "relative",
                              display: "inline-flex",
                              width: "100%",
                            }}
                            className="arrayFields"
                          >
                            <div
                              style={{
                                width: "40%",
                                display: "inline-block",
                              }}
                            >
                              <FormikControl
                                control="input"
                                type="text"
                                name={`fields[${index}].title`}
                                divstyle={{ padding: "0" }}
                                //   style={{ width: "40%", margin: "5px 0" }}
                              />
                            </div>

                            <FormikControl
                              control="select"
                              as="select"
                              name={`fields[${index}].type`}
                              placeholder="Select Type"
                              options={props.typesArray}
                              key_title="title"
                              key_value="value"
                              divstyle={{
                                display: "inline-block",
                                width: "25%",
                                boxSizing: "border-box",
                                verticalAlign: "top",
                              }}
                            />
                            <FormikControl
                              control="toggle"
                              isToggled={fields[index].required}
                              name={`fields[${index}].required`}
                              onToggle={(isToggle) => {
                                let toggleVal = !isToggle;
                                setFieldValue(
                                  `fields[${index}].required`,
                                  toggleVal === 1 || toggleVal === true ? 1 : 0
                                );
                              }}
                              style={{ width: "15%", textAlign: "center" }}
                            />
                            <div style={{ textAlign: "center", width: "20%" }}>
                              <GButton
                                type="button"
                                variant="linkable"
                                children={fields[index].id ? "Update" : "Save"}
                                style={{
                                  margin: "10px 5px",
                                  padding: "5px 2px",
                                  textDecoration: "underline",
                                }}
                                onClick={(e) => {
                                  handleUpdateVField(values, index);
                                }}
                              />

                              <GButton
                                type="button"
                                variant="linkable"
                                children="Delete"
                                onClick={(e) => {
                                  handleDeleteField(index);
                                }}
                                style={{
                                  margin: "10px 5px",
                                  padding: "5px 2px",
                                  color: "red",
                                  textDecoration: "underline",
                                }}
                              />
                            </div>
                          </div>
                          {fields[index].type === "selectbox" ||
                          fields[index].type === "priceoptions" ? (
                            <div style={{ padding: "10px" }}>
                              <label style={{ color: "#a2a2a2" }}>
                                Options
                              </label>

                              <FieldArray
                                name={`fields[${index}].data.options`}
                              >
                                {(fieldArrayProps) => {
                                  console.log(fieldArrayProps);
                                  const { push, remove, form } =
                                    fieldArrayProps;
                                  const { values } = form;
                                  const { fields } = values;
                                  //const { options } = fields;
                                  console.log(fields);
                                  console.log(fields[index].data.options);
                                  return (
                                    <React.Fragment>
                                      <div>
                                        {fields[index].type ===
                                          "priceoptions" && (
                                          <div
                                            style={{
                                              display: "flex",
                                              width: "100%",
                                              alignItems: "center",
                                              padding: "0 10px",
                                            }}
                                          >
                                            <label style={{ width: "30%" }}>
                                              Title
                                            </label>

                                            <label
                                              style={{
                                                width: "30%",
                                                padding: "0 10px",
                                              }}
                                            >
                                              Amount
                                            </label>
                                          </div>
                                        )}
                                        {fields[index].data.options.map(
                                          (o, ind) => {
                                            return (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  width: "100%",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <FormikControl
                                                  control="input"
                                                  type="text"
                                                  placeholder="Title"
                                                  name={`fields[${index}].data.options[${ind}.title]`}
                                                  divstyle={{
                                                    margin: "0",
                                                    width: "30%",
                                                    padding: "2px 5px",
                                                  }}
                                                  style={{
                                                    padding: "0.6rem 0.8rem",
                                                    borderRadius: "20px",
                                                  }}
                                                />

                                                {fields[index].type ===
                                                  "priceoptions" && (
                                                  <FormikControl
                                                    control="input"
                                                    placeholder="Amount"
                                                    type="text"
                                                    name={`fields[${index}].data.options[${ind}.amount]`}
                                                    divstyle={{
                                                      margin: "0",
                                                      width: "30%",
                                                      padding: "2px 5px",
                                                    }}
                                                    style={{
                                                      padding: "0.6rem 0.8rem",
                                                      borderRadius: "50px",
                                                    }}
                                                    onChange={(event) => {
                                                      const re = /^[0-9.\b]+$/;
                                                      if (
                                                        event.target.value &&
                                                        !re.test(
                                                          event.target.value
                                                        )
                                                      ) {
                                                        return;
                                                      } else {
                                                        setFieldValue(
                                                          `fields[${index}].data.options[${ind}.amount`,
                                                          event.target.value
                                                        );
                                                      }
                                                    }}
                                                  />
                                                )}

                                                <GButton
                                                  type="button"
                                                  variant="linkable"
                                                  onClick={(e) => {
                                                    remove(ind);
                                                  }}
                                                  style={{
                                                    width: "20px",
                                                    height: "20px",
                                                    padding: "2px 0 0",
                                                    margin: "5px 15px",
                                                    borderRadius: "50px",
                                                    border: "1px solid #d2d2d2",
                                                  }}
                                                >
                                                  <Remove
                                                    style={{
                                                      width: "1rem",
                                                      height: "1rem",
                                                      fill: "#d2d2d2",
                                                    }}
                                                  />
                                                </GButton>
                                              </div>
                                            );
                                          }
                                        )}
                                        <a
                                          href="#"
                                          onClick={(e) => {
                                            push({ title: "", amount: "" });
                                          }}
                                          style={{
                                            display: "inline-block",
                                            marginTop: "8px",
                                            color: "#0381FC",
                                            padding: "5px",
                                            textDecoration: "none",
                                            cursor: "pointer",
                                            fontStyle: "italic",
                                          }}
                                        >
                                          + Add Option
                                        </a>
                                      </div>
                                    </React.Fragment>
                                  );
                                }}
                              </FieldArray>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                  </React.Fragment>
                );
              }}
            </FieldArray>

            <button
              id="resetButton"
              style={{ display: "none" }}
              onClick={() => resetForm()}
            ></button>
            {/* <GButton
                type="submit"
                variant="condensed"
                children="Update"
                style={{ marginTop: "10px" }}
              /> */}
          </Form>
        )}
      </Formik>

      {dialogOpen === true ? (
        <GConfirmDialog
          open={dialogOpen}
          title="Delete Field"
          text="Are you sure you want to delete this Field?"
          handleClose={() => setDialogOpen(false)}
          onConfirmClose={() => {
            setDialogOpen(false);
          }}
        ></GConfirmDialog>
      ) : (
        ""
      )}
    </div>
  );
}
export default ExistForm;
