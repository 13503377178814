import orderIcon from "../assets/images/sidebar/orders.svg";
import customerIcon from "../assets/images/sidebar/customers.svg";
import driverIcon from "../assets/images/sidebar/drivers.svg";
import couponIcon from "../assets/images/sidebar/promotions.svg";
import transactionIcon from "../assets/images/sidebar/transactions.svg";
import reportIcon from "../assets/images/sidebar/reports.svg";
import mapIcon from "../assets/images/sidebar/map.svg";

export const menus = [
  {
    icon: orderIcon,
    title: "Orders",
    path: "/orders",
    state: "order",
    isLink: true,
    children: [
      {
        icon: "📋",
        title: "Reviews",
        path: "/reviews",
        state: "reviews",
        isLink: true,
      },
    ],
  },
  {
    icon: customerIcon,
    title: "Users",
    path: "/customers",
    state: "customer",
    isLink: true,
    isAdd: true,
  },
  {
    icon: driverIcon,
    title: "Drivers",
    path: "/drivers",
    state: "driver",
    isLink: true,
    isAdd: false,
  },
  {
    icon: mapIcon,
    title: "Map View",
    path: "/map-view",
    state: "map",
    isLink: true,
    isAdd: false,
  },
  {
    icon: couponIcon,
    title: "Promotions",
    path: "/coupons",
    state: "coupon",
    isLink: true,
    isAdd: true,
  },
  {
    icon: transactionIcon,
    title: "Transactions",
    path: "/transactions",
    state: "transaction",
    isLink: true,
    children: [
      {
        icon: "📋",
        title: "Failed Payments",
        path: "/failed-payments/pending",
        state: "transaction",
        isLink: true,
      },
    ],
  },
  {
    icon: reportIcon,
    title: "Reports",
    path: "/reports",
    state: "report",
    isLink: true,
  },
];
