import React, { useState } from "react";
import "./sidebar.css";
import CustomerCreate from "../forms/customerForm";
import DriverCreate from "../forms/driverForm";
import CouponCreate from "../forms/couponForm";
import { Link, useLocation } from "react-router-dom";
import useSettings from "../useSettings";
import { addDefaultLogo, ImgUrl } from "../services";
import GNestedMenu from "../gComponents/gNestedMenu";
import settingIcon from "../assets/images/sidebar/settings.svg";
import styled from "styled-components";
import Modal from "react-modal";

const Ul = styled.ul`
  li {
    a {
      display: flex !important;
      align-items: center;

      img {
        width: 15px;
        height: 15px;
        opacity: 0.7;
      }
    }
  }
`;

export default function Sidebar(props) {
  const location = useLocation();
  const [ccvisible, setCcvisible] = useState(false);
  const [cdvisible, setCdvisible] = useState(false);
  const [ccpnvisible, setCcpnvisible] = useState(false);
  const settings = useSettings(true);
  console.log(location);
  return (
    <div className="Sidebar" style={props.style}>
      <div className="App-header">
        <Link to="/dashboard">
          <img
            src={ImgUrl("logo") + "/" + settings?.admin_logo}
            className="App-logo"
            alt="logo"
            onError={addDefaultLogo}
          />
        </Link>
        <span>
          <h3>{settings.project_name}</h3>
        </span>
      </div>

      <GNestedMenu
        handleClick={(title) => {
          // eslint-disable-next-line no-unused-expressions
          title === "Users"
            ? setCcvisible(true)
            : title === "Drivers"
            ? setCdvisible(true)
            : title === "Promotions"
            ? setCcpnvisible(true)
            : "";
        }}
      />

      <h4>Other Information</h4>
      <Ul>
        <li className={location.state === "setting" ? "active" : ""}>
          <Link to={{ pathname: "/settings/basic", state: "setting" }}>
            <span
              role="img"
              aria-label="Order"
              className="icon"
              style={{ lineHeight: "10px" }}
            >
              <img src={settingIcon} alt="settings" />
            </span>
            <span>Settings</span>
          </Link>
        </li>
      </Ul>
      <div className="Sidebar-footer">
        <hr />
        <p>
          Powered by{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.delivai.com/"
            style={{ color: "#0381FC" }}
          >
            delivAI
          </a>
        </p>
      </div>

      <Modal
        isOpen={cdvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCdvisible(false)}
        style={{
          content: {
            width: "450px",
          },
        }}
        contentLabel="Driver Form Modal"
      >
        <DriverCreate
          cdvisible={cdvisible}
          setCdvisible={setCdvisible}
          isEmpty={true}
        />
      </Modal>

      <Modal
        isOpen={ccvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCcvisible(false)}
        style={{
          content: {
            width: "420px",
          },
        }}
        contentLabel="Customer Form Modal"
      >
        <CustomerCreate
          isEmpty={true}
          ccvisible={ccvisible}
          setCcvisible={setCcvisible}
        />
      </Modal>

      <Modal
        isOpen={ccpnvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setCcpnvisible(false)}
        style={{
          content: {
            width: "500px",
          },
        }}
        contentLabel="Coupon Form Modal"
      >
        <CouponCreate
          isEmpty={true}
          ccpnvisible={ccpnvisible}
          setCcpnvisible={setCcpnvisible}
        />
      </Modal>
    </div>
  );
}
