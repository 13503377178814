import React, { useState, useEffect } from "react";
import GView from "../gComponents/GView";
import RecentAddress from "../assets/images/recent.png";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
import GText from "../gComponents/gText";
import GTaxiMap from "./orderStopsMap";

const OrderStopsLocations = (props) => {
  const [addressLocation] = useState({ lat: 30.7046, lng: 76.7179 });

  return (
    <>
      <div className="modal-header">Choose location</div>

      <div
        className="modal-body"
        // style={{ padding: "5px 0" }}
      >
        <GTaxiMap
          center={{ lat: 30.70586, lng: 76.708282 }}
          zoom={15}
          // googleMapURL={
          //   "https://maps.googleapis.com/maps/api/js?key=" +
          //   process.env.REACT_APP_GOOGLE_KEY +
          //   "&libraries=geometry,drawing,places"
          // }
          places={[]}
          isAutoComplete={true}
          showMap={false}
          style={{ height: "0px", position: "relative" }}
          onChange={(address) => {
            let newAddress = {
              lat: address.lat,
              lng: address.lng,
              line1: address.line1,
              formatted_display:address.formatted_display,
              city: address.city,
              state: address.state,
              country: address.country,
              zipcode: address.zipcode,
            };
            props.onSelectAddress(newAddress);
          }}
        />

        <GView>
          {props.addressData.map((address, i) => {
            return (
              <GView
                key={i}
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  padding: "20px 10px",
                  borderTop: i > 0 ? "1px solid #f2f2f2" : "",
                  cursor: "pointer",

                  // "&:hover": {
                  //   background: "#f5f5f5",
                  // },
                }}
                onClick={() => {
                  props.onSelectAddress(address);
                }}
              >
                <img
                  src={RecentAddress}
                  style={{ width: "20px", marginRight: "10px" }}
                />
                 <GView>
                  <GText
                    semi
                    text={`${address.location_name} (${address.title})`}
                    style={{
                      textTransform: "uppercase",
                      paddingBottom: "3px",
                      color: "#333",
                      fontSize: "15.5px",
                      fontWeight: "550",margin:0
                    }}
                  />
                  <GText
                    dark
                    text={address.formatted_display}
                    style={{ fontSize: "14px" ,margin:0}}
                  />
                </GView>

              </GView>
            );
          })}
        </GView>
      </div>
    </>
  );
};

export default OrderStopsLocations;
