import React, { useState } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import GButton from "../gComponents/gButton";

export default function ChangePassword(props) {
  const [openSnackbar] = useSnackbar();
  const [isBtnLoading, setBtnLoading] = useState(false);
  const initialValues = {
    old: "",
    new: "",
  };

  const onSubmit = (values) => {
    setBtnLoading(true);
    ApiService({
      method: "POST",
      route: "change-password",
      body: values,
    }).then((response) => {
      console.log(response.data.status_code);
      if (response.data.status_code === 1) {
        props.setCpvisible(false);
      }
      setBtnLoading(false);
      openSnackbar(response.data.message);
    });
  };

  const validationSchema = Yup.object({
    old: Yup.string().required("Old password is required"),
    new: Yup.string()
      .min(5, "New password is too short")
      .required("New password is required"),
  });

  if (props.cpvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">Change Password</div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <FormikControl
              control="input"
              type="text"
              label="Old Password"
              name="old"
            />
            <FormikControl
              control="input"
              type="text"
              label="New Password"
              name="new"
            />

            <GButton
              type="submit"
              variant="condensed"
              loading={isBtnLoading}
              children="Update"
              style={{ width: "calc(100% - 30px)", margin: "15px" }}
            />
          </Form>
        </Formik>
      </div>
    </>
  );
}
