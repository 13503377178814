import React, { useState } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../formikComponents/formikControl";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
import GButton from "../gComponents/gButton";
import { FlashOffRounded } from "@material-ui/icons";

export default function CreateTimeslotsForm(props) {
  const [openSnackbar] = useSnackbar();
  const [isDisabled, setIsDisabled] = useState(false);

  const initialValues = {
    weekday: props.weekday,
    from_time: props.data ? props.data.from_time : "",
    to_time: props.data ? props.data.to_time : "",
    percentage: props.data ? props.data?.percentage : "",
  };

  const showSecond = true;
  const str = showSecond ? "HH:mm:ss" : "HH:mm";

  const onSubmit = (values) => {
    setIsDisabled(true);
    console.log(values);
    // return;
    var hitMethod = !props.isEmpty ? "PUT" : "POST";
    var hitUrl = !props.isEmpty
      ? "surge-timeslots/" + props.data.id
      : "surge-timeslots";
    ApiService({ method: hitMethod, route: hitUrl, body: values }).then(
      (response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          props.setSTsvisible(false);
          props.onSubmit();
        }
        setIsDisabled(false);
        openSnackbar(response.data.message);
        return;
      }
    );
  };

  const validationSchema = Yup.object({
    from_time: Yup.string().required("Required"),
    to_time: Yup.string().required("Required"),
  });

  if (props.sTsvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header">
        {!props.isEmpty ? "Edit" : "Add"} Surge Timeslot
      </div>
      <div className="modal-body">
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue }) => (
            //  function updateField(newValue){
            //      values.icon = newValue
            // }

            <Form>
              <div
                style={{
                  padding: "20px 15px 0px",
                }}
              >
                <label>Start Time</label>
                <TimePicker
                  defaultValue={
                    values.from_time
                      ? moment(values.from_time, "HH:mm:ss")
                      : undefined
                  }
                  defaultOpenValue={
                    values.from_time
                      ? moment(values.from_time, "HH:mm:ss")
                      : moment()
                  }
                  onChange={(value) => {
                    console.log(value);
                    values.from_time = value && value.set('seconds', 0).format(str);
                    setFieldValue("from_time", value && value.set('seconds', 0).format(str));
                  }}
                  // showSecond={showSecond}
                  showSecond={false}
                  minuteStep={1}
                  // secondStep={60}
                  className="xxx"
                  style={{ width: "100%", height: "40px" }}
                />
              </div>
              <FormikControl
                control="input"
                type="text"
                name="from_time"
                style={{ display: "none" }}
              />

              <div
                style={{
                  margin: "10px 15px 0px",
                }}
              >
                <label>End Time</label>
                <TimePicker
                  defaultValue={
                    values.to_time
                      ? moment(values.to_time, "HH:mm:ss")
                      : undefined
                  }
                  defaultOpenValue={
                    values.to_time
                      ? moment(values.to_time, "HH:mm:ss")
                      : moment()
                  }
                  onChange={(value) => {
                    values.to_time = value && value.set('seconds', 0).format(str);
                    setFieldValue("to_time", value && value.set('seconds', 0).format(str));
                  }}
                  // showSecond={showSecond}
                  showSecond={false}
                  minuteStep={1}
                  className="xxx"
                  style={{ width: "100%", height: "40px" }}
                />
              </div>
              <FormikControl
                control="input"
                type="text"
                name="to_time"
                style={{ display: "none" }}
              />

              <FormikControl
                control="input"
                type="text"
                label="Percentage"
                name="percentage"
                onChange={(event) => {
                  const re = /^[0-9.\b]+$/;
                  if (event.target.value && !re.test(event.target.value)) {
                    return;
                  } else {
                    setFieldValue("percentage", event.target.value);
                  }
                }}
              />
              <br />
              <GButton
                type="submit"
                disabled={isDisabled}
                variant="condensed"
                children={!props.isEmpty ? "Update" : "Create"}
                style={{ margin: "0 15px", width: "calc(100% - 30px)" }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
