import React, { useState, useEffect } from "react";
import { ApiService } from "../services";
import { useSnackbar } from "react-simple-snackbar";
import GPricingListCard from "../gComponents/gPricingListCards";
import GEmpty from "../gComponents/gEmpty";
import GShimmer from "../gComponents/gTableShimmer";
import GButton from "../gComponents/gButton";

export default function PricingRulePopup(props) {
  const [rulesData, setRulesData] = useState([]);
  const [selectedRule, setSelectedRule] = useState(
    props.selectedRule ? props.selectedRule : ""
  );
  const [openSnackbar] = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const getPricingRules = () => {
      ApiService({ method: "GET", route: "task/rules" })
        .then((response) => {
          console.log(response.data);
          if (response.data.status_code === 1) {
            setRulesData(response.data.data);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getPricingRules();
  }, []);

  const hadleSelectRule = () => {
    console.log(selectedRule);
    if (selectedRule) {
      props.onSubmit(selectedRule);
      props.setPrsvisible(false);
    } else {
      openSnackbar("Select Pricing rule");
      return;
    }
  };

  if (props.prsvisible === false) {
    return null;
  }
  return (
    <>
      <div className="modal-header"> Pricing Rules </div>
      <div className="modal-body">
        <div
          style={{
            maxHeight: "65vh",
            overflow: "overlay",
          }}
        >
          {isLoading === true ? (
            <GShimmer></GShimmer>
          ) : rulesData.length === 0 ? (
            <GEmpty></GEmpty>
          ) : (
            <GPricingListCard
              actions={false}
              rulesData={rulesData}
              selectedRule={selectedRule}
              onSelected={(r) => {
                console.log(r);
                setSelectedRule(r);
              }}
              getSelected={true}
            />
          )}
        </div>

        <GButton
          variant="condensed"
          type="submit"
          style={{ margin: "15px 15px 0", width: "200px", float: "right" }}
          onClick={() => hadleSelectRule()}
        >
          Submit
        </GButton>
      </div>
    </>
  );
}
