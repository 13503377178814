import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import GSelectbox from "../gComponents/gSelectbox";
import { ApiService, ApiDownloadService } from "../services";
import { TableContainer } from "@material-ui/core";
import { Print, GetApp } from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableRow,
  TableRowData,
  TableHead,
  TableHeader,
} from "./../styledComponents/tableStyle";
import GEmpty from "../gComponents/gEmpty";
import QueryWith from "../Query";
import GdatePicker from "../gComponents/gDatePicker";
import GButton from "../gComponents/gButton";
import styled from "styled-components";
import GText from "../gComponents/gText";

const customDateFilters = [
  {
    title: "Last 7 Days",
    value: "7-days",
  },
  {
    title: "Last 1 Week",
    value: "1-week",
  },
  {
    title: "Last 30 Days",
    value: "30-days",
  },
  {
    title: "Last 1 Month",
    value: "1-month",
  },
  {
    title: "Last 90 Days",
    value: "90-days",
  },
  {
    title: "Last 3 Months",
    value: "3-months",
  },
];

const DateFilterStyles = styled.div`
  text-align: right;
  .from,
  .to {
    border: 1px solid #e2e2e2;
    background: #fff;
    padding: 5px;
    margin: 5px 0 5px 10px;
    display: inline-block;
    text-align: left;
    label {
      display: block;
      font-weight: 550;
      padding: 2px;
    }

    input {
      border: 0;
      outline: 0;
      box-shadow: none;
      font-weight: 450;
    }
  }
`;

const reportHeader = {
  textAlign: "center",
  fontSize: "25px",
  fontWeight: "500",
  margin: "10px 0",
};

function ReportDetails({ match }) {
  let { identifier } = useParams();
  let history = useHistory();

  const [selectedReportType, setSelectedReportType] = useState(identifier);
  const [reportDetailData, setDetailData] = useState({});
  const [reportDetailColumns, setDetailColumns] = useState([]);
  const [reportDetailRows, setDetailRows] = useState([]);
  const [selectedCustomDate, setSelectedCustomDate] = useState("7-days");
  let params = new URLSearchParams(history.location.search);

  const handleFormatChange = (d) => {
    var date = ("0" + new Date(d).getDate()).slice(-2); //Current Date
    var month = ("0" + (new Date(d).getMonth() + 1)).slice(-2); //Current Month
    var year = new Date(d).getFullYear(); //Current Year
    console.log(year + "-" + month + "-" + date);
    return year + "-" + month + "-" + date;
  };

  const [filters, setFilters] = useState({
    date_from: params.get("date_from")
      ? params.get("date_from")
      : handleFormatChange(
          new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() - 7
          )
        ),
    date_upto: params.get("date_upto")
      ? params.get("date_upto")
      : handleFormatChange(new Date()),
  });

  const handleFilterDates = (customDate) => {
    var curr = new Date();
    var first, last;
    if (customDate === "7-days") {
      first = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate() - 7);
      last = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate());
    }
    if (customDate === "1-week") {
      var day = curr.getDay();
      first = new Date(
        curr.getFullYear(),
        curr.getMonth(),
        curr.getDate() + (day === 0 ? -6 : 1) - day - 7
      );
      last = new Date(
        curr.getFullYear(),
        curr.getMonth(),
        curr.getDate() + (day === 0 ? -6 : 1) - day - 1
      );
    }

    if (customDate === "30-days") {
      first = new Date(
        curr.getFullYear(),
        curr.getMonth(),
        curr.getDate() - 29
      );
      last = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate());
    }

    if (customDate === "1-month") {
      first = new Date(curr.getFullYear(), curr.getMonth() - 1, 1);
      last = new Date(curr.getFullYear(), curr.getMonth(), 0);
    }

    if (customDate === "90-days") {
      first = new Date(
        curr.getFullYear(),
        curr.getMonth(),
        curr.getDate() - 89
      );
      last = new Date(curr.getFullYear(), curr.getMonth(), curr.getDate());
    }

    if (customDate === "3-months") {
      first = new Date(curr.getFullYear(), curr.getMonth() - 3, 1);
      last = new Date(curr.getFullYear(), curr.getMonth(), 0);
    }
    setFilters({
      date_from: handleFormatChange(first),
      date_upto: handleFormatChange(last),
    });
  };
  const getReportDetail = () => {
    var params = QueryWith(history.location, filters);
    console.log(params);
    if (params.length > 0) {
      history.push({
        pathname: "/report/details/" + selectedReportType,
        state: "report",
        search: params[0],
      });
    }
    var hitUrl = "report/" + selectedReportType;
    console.log(hitUrl);

    ApiService({ method: "GET", route: hitUrl, body: filters })
      .then((response) => {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setDetailData(response.data.data);
          setDetailColumns(response.data.data.columns);
          setDetailRows(response.data.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getReportDetail();
  }, [selectedReportType, filters]);

  const getDownloadReport = () => {
    var params = QueryWith(history.location, filters);
    console.log(params[0]);
    let obj =
      params[0].length > 1
        ? params[0] + "&download=true"
        : params[0] + "download=true";

    console.log(obj);
    var defaultFileName = selectedReportType + ".xls";

    var hitUrl = "report/" + selectedReportType;
    console.log(hitUrl);
    ApiDownloadService("GET", hitUrl, obj)
      .then((response) => {
        var blob = new Blob([response]);
        var objectUrl = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.style = "display:none";
        a.href = objectUrl;
        a.download = defaultFileName;
        a.click();
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const printDocument = () => {
    var printContents = document.getElementById("divToPrint");
    console.log(window);
    params = "width=" + window.screen.width;
    params += ", height=" + window.screen.height;
    params += ", top=0, left=0";
    params += ", fullscreen=yes";

    var popupWin = window.open("", "_blank", params);
    //return;
    popupWin.document.open();
    popupWin.document.write(
      '<html><style>table{table-layout:auto!important}th:first-child,td:first-child{text-align:left!important}th:last-child,td:last-child{text-align:right!important}@page {size: auto;margin-top: 5mm;margin-bottom:80px}table{width:100%;table-layout:fixed}table tr td,table,tr,th,h2,h3,p{font-family:"PT Serif",serif;}</style> <body onload = "window.print()" onafterprint = "window.close()" > ' +
        printContents.innerHTML +
        "</html>"
    );

    popupWin.document.close();
    // const input = document.getElementById('divToPrint');
    // html2canvas(input)
    //   .then((canvas) => {
    //     const imgData = canvas.toDataURL('image/png');
    //     const pdf = new jsPDF();
    //     pdf.addImage(imgData, 'JPEG', 0, 0);
    //     // pdf.output('dataurlnewwindow');
    //     pdf.save("download.pdf");
    //   })
    //   ;
  };

  return (
    <div className="mainInnerView">
      {/* <div style={{ textAlign: "right" }}>
        <GSelectbox
          placeholder=""
          defaultvalue={selectedReportType}
          data={reportsData}
          id="report_filter"
          option_key="title"
          option_value="identifier"
          optGroup={true}
          optTitle="type"
          onChange={(newVal) => {
            setSelectedReportType(newVal);
            history.push({
              pathname: "/report/details/" + newVal,
              search: "",
            });
          }}
          style={{
            border: "1px solid #e2e2e2",
            height: "40px",
            marginLeft: "10px",
          }}
        />
      </div> */}
      {filters.date_from || reportDetailData.date_from ? (
        <DateFilterStyles>
          <GSelectbox
            placeholder=""
            defaultvalue={selectedCustomDate}
            data={customDateFilters}
            id="report_date_filter"
            option_key="title"
            option_value="value"
            showSelectOption={false}
            onChange={(newVal) => {
              console.log(newVal);
              setSelectedCustomDate(newVal);
              handleFilterDates(newVal);
            }}
            style={{
              border: "0px solid #e2e2e2",
              height: "32px",
              padding: "0.45rem",
              background: "transparent",
              marginLeft: "10px",
            }}
          />
          <div>
            <div className="from">
              <GdatePicker
                label="From"
                id="date_from"
                name="date_from"
                value={
                  new Date(
                    filters.date_from
                      ? filters.date_from
                      : reportDetailData?.date_from
                  ) || null
                }
                format="yyyy-MM-dd"
                onChange={(val) => {
                  console.log(val);
                  setFilters({ date_from: handleFormatChange(val) });
                }}
              />
            </div>
            <div className="to">
              <GdatePicker
                label="To"
                id="date_upto"
                name="date_upto"
                value={
                  new Date(
                    filters.date_upto
                      ? filters.date_upto
                      : reportDetailData?.date_upto
                  ) || null
                }
                format="yyyy-MM-dd"
                minDate={new Date(filters.date_from)}
                onChange={(val) => {
                  console.log(val);
                  setFilters({ date_upto: handleFormatChange(val) });
                }}
              />
            </div>
          </div>
        </DateFilterStyles>
      ) : (
        ""
      )}

      <div className="listPaper" style={{ marginTop: "10px" }}>
        {reportDetailData && reportDetailData?.rows?.length > 0 ? (
          <React.Fragment>
            {" "}
            <GButton
              variant="linkable"
              style={{ float: "right" }}
              onClick={() => {
                getDownloadReport();
              }}
            >
              <GetApp />
            </GButton>
            <GButton
              variant="linkable"
              style={{ float: "right" }}
              onClick={() => printDocument()}
            >
              <Print />
            </GButton>
          </React.Fragment>
        ) : (
          ""
        )}

        <br />
        <br />
        <div id="divToPrint">
          <h2 style={reportHeader}>{reportDetailData.company}</h2>
          <GText
            g3
            bold
            text={reportDetailData.title}
            style={{ textTransform: "uppercase", textAlign: "center" }}
          />
          {reportDetailData.date_from ? (
            <GText
              text={
                reportDetailData.date_from + " - " + reportDetailData.date_upto
              }
              style={{ textAlign: "center", marginBottom: "20px" }}
            />
          ) : (
            <br />
          )}
          {reportDetailData ? (
            <TableContainer>
              <Table
                stickyHeader
                aria-label="sticky table"
                className="reportsTable"
              >
                <TableHead>
                  <TableRow>
                    {reportDetailColumns.map((column, value) => (
                      <TableHeader
                        key={value}
                        style={{
                          textAlign: "center",
                          borderTop: "2px solid #888888",
                          borderBottom: "2px solid #888888",
                          textTransform: "uppercase",
                          padding: "14px",
                        }}
                      >
                        {column.replace("_", " ")}
                      </TableHeader>
                    ))}
                  </TableRow>
                </TableHead>
                {reportDetailRows.map((data, i) => {
                  return (
                    <TableBody key={i} style={{ borderTop: "2px solid #ddd" }}>
                      <TableRow>
                        <TableRowData
                          style={{
                            padding: "8px",
                            fontStyle: "italic",
                            textTransform: "uppercase",
                            fontSize: "16px",
                            background: "#fff",
                            borderTop: data.header ? "1px solid #ccc" : "",
                          }}
                        >
                          {data.header}
                        </TableRowData>
                        {reportDetailColumns
                          .filter((f, i) => !(i === 0))
                          .map((col, v) => {
                            return (
                              <TableRowData
                                key={v}
                                style={{
                                  borderTop: data.header
                                    ? "1px solid #ccc"
                                    : "",
                                }}
                              ></TableRowData>
                            );
                          })}
                      </TableRow>
                      {data.rows.map((c, value) => {
                        return (
                          <TableRow hover tabIndex={-1} key={value}>
                            {Object.keys(c).map((keyName, keyIndex) => {
                              return (
                                <TableRowData
                                  key={keyIndex}
                                  style={{
                                    textAlign: "center",
                                    background: "#fff",
                                    padding: "10px 8px",
                                  }}
                                >
                                  {c[keyName]}
                                </TableRowData>
                              );
                            })}
                          </TableRow>
                        );
                      })}

                      {data.rows.length > 0 && (
                        <TableRow>
                          <TableRowData
                            style={{ background: "#fff" }}
                          ></TableRowData>
                        </TableRow>
                      )}
                      {data.rows.length > 0 ? (
                        <TableRow>
                          <TableRowData
                            style={{
                              padding: "8px",
                              fontStyle: "italic",
                              fontSize: "16px",
                              fontWeight: "700",
                              background: "#fff",
                              borderTop: "1px solid #e2e2e2",
                            }}
                          >
                            {data.footer}
                          </TableRowData>
                          {reportDetailData.columns
                            .filter((f, i) => !(i === 0 || i === 1))
                            .map((col, v) => {
                              return (
                                <TableRowData
                                  style={{
                                    background: "#fff",
                                    borderTop: "1px solid #e2e2e2",
                                  }}
                                ></TableRowData>
                              );
                            })}
                          <TableRowData
                            style={{
                              background: "#fff",
                              textAlign: "right",
                              fontWeight: "700",
                              padding: "14px 8px",
                              borderTop: "1px solid #e2e2e2",
                            }}
                          >
                            {" "}
                            {data.footer_value}
                          </TableRowData>
                        </TableRow>
                      ) : (
                        ""
                      )}
                    </TableBody>
                  );
                })}
              </Table>
              {reportDetailColumns.length === 0 ? (
                <GEmpty></GEmpty>
              ) : reportDetailRows[0]?.rows.length === 0 ? (
                <GEmpty></GEmpty>
              ) : (
                ""
              )}
            </TableContainer>
          ) : (
            ""
          )}

          {reportDetailData.footer_value ? (
            <div
              style={{
                width: "100%",
                boxSizing: "border-box",
                display: "flex",
                borderTop: "1px solid #ccc",
                padding: "0 8px",
              }}
            >
              <p
                style={{
                  width: "50%",
                  fontWeight: "550",
                }}
              >
                {reportDetailData.footer}
              </p>
              <p
                style={{
                  width: "50%",
                  textAlign: "right",
                  fontWeight: "550",
                }}
              >
                {reportDetailData.footer_value}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default ReportDetails;
