import React from "react";
// import styled from "styled-components";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import useSettings from "../useSettings";

const useStyles = makeStyles((theme) => ({
  SettingRoute: {
    color: "#000",

    "& a": {
      color: "#000",
      cursor: "pointer",
      display: "block",
      padding: "6px 20px",
      fontSize: "13.5px",
      fontWeight: "450",
      textDecoration: "none",

      "&:hover": {
        color: "#0381FC",
      },
    },
    "& a.active": {
      color: "#0381FC",
    },
  },
}));
var headerStyle = {
  fontSize: "15px",
  margin: "0px",
  fontWeight: "500",
  padding: "0 20px",
  marginBottom: "10px",
  fontWeight: "550",
};

var lineStyle = {
  width: "90%",
  marginTop: "10px",
  marginBottom: "15px",
  border: "0px",
  borderTop: "1px solid #eee",
};

export default function SettingRoutes(props) {
  const classes = useStyles();
  const settings = useSettings();

  console.log(JSON.stringify(props));
  // export default function SettingView = ({ match }) => {
  return (
    <div className={classes.SettingRoute}>
      <br />
      <h3 style={headerStyle}>General Settings</h3>
      <Link
        to={{ pathname: "/settings/basic", state: "setting" }}
        className={props.url === "/settings/basic" ? "active" : ""}
      >
        Basic Settings
      </Link>
      <Link
        to={{
          pathname: "/settings/social",
          state: "setting",
        }}
        className={props.url === "/settings/social" ? "active" : ""}
      >
        Social Links
      </Link>
      <Link
        to={{
          pathname: "/settings/invoice",
          state: "setting",
        }}
        className={props.url === "/settings/invoice" ? "active" : ""}
      >
        Invoice Settings
      </Link>
      {/* <Link
        to={{
          pathname: "/settings/app",
          state: "setting",
        }}
        className={props.url === "/settings/app" ? "active" : ""}
      >
        App Settings
      </Link> */}
      {settings?.theme != "3" && (
        <Link
          to={{ pathname: "/settings/form-settings", state: "setting" }}
          className={props.url === "/settings/form-settings" ? "active" : ""}
        >
          Form Settings
        </Link>
      )}
      <Link
        to={{
          pathname: "/settings/document-types/customer",
          state: "setting",
        }}
        className={props.url === "/settings/document-types" ? "active" : ""}
      >
        Document Types
      </Link>
      <hr style={lineStyle} />

      <h3 style={headerStyle}>Order Settings</h3>
      <Link
        to={{
          pathname: "/settings/zipcodes",
          state: "setting",
        }}
        className={props.url === "/settings/zipcodes" ? "active" : ""}
      >
        Zip Codes
      </Link>
      <Link
        to={{
          pathname: "/settings/order-notifications/customer",
          state: "setting",
        }}
        className={
          props.url === "/settings/order-notifications" ? "active" : ""
        }
      >
        Notifications
      </Link>

      <Link
        to={{
          pathname: "/settings/order-cancel-reasons/customer",
          state: "setting",
        }}
        className={
          props.url === "/settings/order-cancel-reasons" ? "active" : ""
        }
      >
        Cancel Reasons{" "}
      </Link>
      <Link
        to={{
          pathname: "/settings/surge-timeslots/mon",
          state: "setting",
        }}
        className={props.url === "/settings/surge-timeslots" ? "active" : ""}
      >
        Surge Timeslots
      </Link>
      <Link
        to={{
          pathname: "/settings/events",
          state: "setting",
        }}
        className={props.url === "/settings/events" ? "active" : ""}
      >
        Events
      </Link>

      <hr style={lineStyle} />

      <h3 style={headerStyle}>Delivery Settings</h3>
      <Link
        to={{
          pathname: "/settings/timeslot-settings",
          state: "setting",
        }}
        className={props.url === "/settings/timeslot-settings" ? "active" : ""}
      >
        Timeslot Settings
      </Link>
      <Link
        to={{
          pathname: "/settings/vehicle-types",
          state: "setting",
        }}
        className={props.url === "/settings/vehicle-types" ? "active" : ""}
      >
        Vehicle Types
      </Link>
      <Link
        to={{
          pathname: "/settings/pricing-rule",
          state: "setting",
        }}
        className={props.url === "/settings/pricing-rule" ? "active" : ""}
      >
        Pricing Rule
      </Link>

      <hr style={lineStyle} />

      <h3 style={headerStyle}>Payment Settings</h3>
      <Link
        to={{
          pathname: "/settings/tax",
          state: "setting",
        }}
        className={props.url === "/settings/tax" ? "active" : ""}
      >
        Tax{" "}
      </Link>
      <Link
        to={{
          pathname: "/settings/wallet",
          state: "setting",
        }}
        className={props.url === "/settings/wallet" ? "active" : ""}
      >
        Wallet{" "}
      </Link>
      <hr style={lineStyle} />

      <h3 style={headerStyle}>Website Settings</h3>
      <Link
        to={{ pathname: "/settings/website-settings", state: "setting" }}
        className={props.url === "/settings/website-settings" ? "active" : ""}
      >
        Basic Settings
      </Link>

      <Link
        to={{ pathname: "/settings/web-plugin", state: "setting" }}
        className={props.url === "/settings/web-plugin" ? "active" : ""}
      >
        Homepage Plugins
      </Link>
      <hr style={lineStyle} />

      <h3 style={headerStyle}>Content Settings</h3>

      <Link
        to={{
          pathname: "/settings/faqs/customer",
          state: "setting",
        }}
        className={props.url === "/settings/faqs" ? "active" : ""}
      >
        FAQs{" "}
      </Link>
      <Link
        to={{
          pathname: "/settings/privacy-policy",
          state: "setting",
        }}
        className={props.url === "/settings/privacy-policy" ? "active" : ""}
      >
        Privacy Policy
      </Link>
      <Link
        to={{
          pathname: "/settings/terms-conditions",
          state: "setting",
        }}
        className={props.url === "/settings/terms-conditions" ? "active" : ""}
      >
        Terms & Conditions
      </Link>

      <hr style={lineStyle} />

      <h3 style={headerStyle}>Notification Settings</h3>
      <Link
        to={{
          pathname: "/settings/email-notifications",
          state: "setting",
        }}
        className={
          props.url === "/settings/email-notifications" ? "active" : ""
        }
      >
        Email
      </Link>
      <Link
        to={{
          pathname: "/settings/sms-notifications",
          state: "setting",
        }}
        className={props.url === "/settings/sms-notifications" ? "active" : ""}
      >
        SMS{" "}
      </Link>
      <br />
    </div>
  );
}
