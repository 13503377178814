import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import GTabs from "../gComponents/gTabs";
import Child from "./orderCancelReasons";

const docRoutes = [
  {
    title: "customer",
    link: "/settings/order-cancel-reasons/customer",
    state: "setting",
  },
  // {
  //   title: "manager",
  //   link: "/settings/order-cancel-reasons/manager", state: 'setting'
  // },
  // {
  //   title: "driver",
  //   link: "/settings/order-cancel-reasons/driver",
  //   state: "setting",
  // },
];

export default function OrderCancelReasons({ match }) {
  const [typeValue, setTypeValue] = useState("customer");

  useEffect(() => {
    console.log(typeValue);
  });

  function handleChange(newValue) {
    console.log("n" + newValue);
    setTypeValue(newValue);
  }

  return (
    <Router>
      <div className="rightInnerView">
        <h2>Order Cancel Reasons</h2>

        {/* <GTabs routes={docRoutes} active={typeValue}></GTabs> */}

        <Switch>
          <div className="listPaper">
            <div>
              <Route path="/settings/order-cancel-reasons/:type">
                <Child onChange={handleChange} />
              </Route>
            </div>
          </div>
        </Switch>
      </div>
    </Router>
  );
}
